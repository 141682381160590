import {
	type OptionsObject,
	type SnackbarMessage,
	useSnackbar as useNotice,
	type VariantType,
} from "notistack"

import { CloseAction } from "./actions"

const Close = <CloseAction />
export const useSnackbar = () => {
	const { enqueueSnackbar, closeSnackbar } = useNotice()

	const show = (
		message: SnackbarMessage,
		props: OptionsObject | VariantType = "success",
	) => {
		const options = typeof props === "string" ? { variant: props } : props

		enqueueSnackbar(message, {
			anchorOrigin: { vertical: "top", horizontal: "right" },
			autoHideDuration: 5000,
			action: Close,
			...options,
		})
	}

	return { show, close: closeSnackbar }
}
