import { deriveEq, type Eq, G, type S, TC, TCP } from "@artists/sf-fp"

export interface Instances<I, O> {
	Eq: Eq.Eq<O>
	Guard: G.Guard<O>
	Transcoder: TC.Transcoder<I, O>
	TranscoderPar: TCP.TranscoderPar<I, O>
}

export const getInstances = <I, O>(
	schema: S.Schema<I, O>,
): Instances<I, O> => ({
	Eq: deriveEq(schema),
	Guard: G.deriveGuard(schema),
	Transcoder: TC.deriveTranscoder(schema),
	TranscoderPar: TCP.deriveTranscoderPar(schema),
})
