import { namespaces as footerNamespaces } from "@artists/footer"
import { namespaces as headerNamespaces } from "@artists/header"
import type { InitOptions } from "i18next"

import type { Namespaces } from "./types"

export const appNamespaces = [
	"common",
	"challenge",
	"home",
	"submission",
	"artist",
	"past",
	"vote",
	"review",
] satisfies Namespaces
export const namespaces: Namespaces = [
	...appNamespaces,
	...headerNamespaces,
	...footerNamespaces,
]
export const defaultLanguage: AppLocale = "en"
export const defaultNamespace = "common"

export const baseConfig = {
	load: "languageOnly",
	ns: [defaultNamespace],
	defaultNS: defaultNamespace,
	fallbackLng: defaultLanguage,
	react: { useSuspense: false },
	partialBundledLanguages: true,
	interpolation: { escapeValue: false },
	supportedLngs: ["en"] as AppLocale[],
} satisfies InitOptions
