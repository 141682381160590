import { theme } from "@artists/chakra-ui-theme"
import {
	HeaderUI,
	useAnnouncements,
	useCartCount,
	useUserAnalytics,
} from "@artists/header"
import { O, pipe, RA } from "@artists/sf-fp"
import { ChakraProvider } from "@chakra-ui/react"

import { services } from "../../../../../../../../config/services"
import { getENV } from "../../../../../../../@common"
import { useAuth, useUserPreferences } from "../../../../../../api"
import { useAnalytics } from "../../../../../../utils"

export const Header = () => {
	const { send } = useAnalytics()
	const { user } = useAuth()
	const userPreferences = useUserPreferences()
	const sfHost = getENV("SPOONFLOWER_HOST")
	const recentlyViewedUrl = getENV("RECENTLY_VIEWED_URL")

	useUserAnalytics(services.gateway, send, {
		username: user?.userName,
		userId: user?.userId,
		orderIdPending: userPreferences?.orderIdPending,
	})

	const { cartCount } = useCartCount(
		services.gateway,
		services.cart,
		userPreferences?.orderIdPending,
	)

	const { announcements } = useAnnouncements(services.gateway, "en")

	return (
		<ChakraProvider theme={theme}>
			<HeaderUI
				user={pipe(
					O.fromNullable(user),
					O.map(u => ({
						userId: u.userId,
						username: u.userName,
						messages: 0,
						isAdmin: u.role === "ADMIN",
					})),
				)}
				announcements={RA.toArray(announcements)}
				cartItems={cartCount}
				cartUrl={services.cart}
				spoonflowerRedirectUrl={sfHost}
				recentlyViewedUrl={recentlyViewedUrl}
			/>
		</ChakraProvider>
	)
}
