import type { FC, ReactNode } from "react"
import { createContext } from "react"

interface BrowserCtx {
	browser: Nullable<{ name: string; supported: boolean }>
}
export const BrowserContext = createContext<BrowserCtx>({ browser: null })

interface Props {
	browser?: Nullable<{ name: string; supported: boolean }>
	children: ReactNode
}
export const BrowserProvider: FC<Props> = ({ children, browser = null }) => (
	<BrowserContext.Provider value={{ browser }}>
		{children}
	</BrowserContext.Provider>
)
