import type { ButtonProps } from "@mui/material"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import { Button } from "./styles"

export const SkipLink: FC<ButtonProps> = props => {
	const [t] = useTranslation("common")

	return (
		<Button disableElevation variant='contained' disableRipple {...props}>
			{t`skipLink`}
		</Button>
	)
}
