import { matchSI } from "@simspace/matchers"
import * as S from "schemata-ts/index"
import { deriveTranscoder } from "schemata-ts/Transcoder"

export const currencies = ["USD", "CAD", "AUD", "GBP", "EUR"] as const
export type Currency = (typeof currencies)[number]
export const CurrencyS = S.Literal(...currencies)
export const currencyDecoder = deriveTranscoder(CurrencyS)

export const currencyToDisplayCurrency: (currency: Currency) => string =
	matchSI({
		AUD: () => "AU$",
		CAD: () => "CA$",
		EUR: () => "€",
		GBP: () => "£",
		USD: () => "US$",
	})
