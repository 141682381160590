import { FacebookIcon } from "../components/facebook-icon"
import { InstagramIcon } from "../components/instagram-icon"
import { PinterestIcon } from "../components/pinterest-icon"
import { TikTokIcon } from "../components/tiktok-icon"
import { YouTubeIcon } from "../components/youtube-icon"
import {
	facebookPath,
	instagramPath,
	pinterestPath,
	tiktokPath,
	youtubePath,
} from "./paths"

export const socialMediaIcons = [
	["instagram", InstagramIcon, instagramPath],
	["facebook", FacebookIcon, facebookPath],
	["pinterest", PinterestIcon, pinterestPath],
	["youtube", YouTubeIcon, youtubePath],
	["tiktok", TikTokIcon, tiktokPath],
] as const
