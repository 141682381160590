import { decoder } from "@artists/devkit"
import { boolean, type InferType, mixed, object, string } from "yup"

const envSchema = object({
	BACKEND_API: string()
		.url()
		.default("https://staging.spoonflower.com/titanarum-main/public"),
	GOOGLE_RECAPTCHA_KEY: string().default(
		"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
	),
	PUBLIC_PATH: string().default("/"),
	SPOONFLOWER_HOST: string().url().default("https://staging.spoonflower.com"),
	HOST: string()
		.url()
		.default("https://local-design-challenges.spoonflower.com:3000"),
	GIT_SHA: string().default("xxx"),
	NODE_ENV: mixed<"production" | "development" | "test">()
		.oneOf(["production", "development", "test"])
		.default("development"),
	API_MODE: mixed<"production" | "staging">()
		.oneOf(["production", "staging"])
		.default("staging"),
	SEND_ANALYTICS: boolean().default(false), // see apps/design-challenges/README.md
	POSTHOG_API_KEY: string().required(),
	RECENTLY_VIEWED_URL: string()
		.url()
		.default("https://recent.spoonflower.com"),
})

export type Env = InferType<typeof envSchema>

export const getENV = decoder(
	envSchema,
	IS_SERVER || IS_SPA ? process.env : window.env_vars,
)

export const setEnvVars = (nonce: string) => {
	const {
		BACKEND_API,
		GOOGLE_RECAPTCHA_KEY,
		PUBLIC_PATH,
		SPOONFLOWER_HOST,
		HOST,
		GIT_SHA,
		NODE_ENV,
		API_MODE,
		SEND_ANALYTICS,
		POSTHOG_API_KEY,
		RECENTLY_VIEWED_URL,
	} = getENV()

	return `<script nonce='${nonce}'>window.env_vars = Object.freeze(${JSON.stringify(
		{
			BACKEND_API,
			GOOGLE_RECAPTCHA_KEY,
			PUBLIC_PATH,
			SPOONFLOWER_HOST,
			HOST,
			GIT_SHA,
			NODE_ENV,
			API_MODE,
			SEND_ANALYTICS,
			POSTHOG_API_KEY,
			RECENTLY_VIEWED_URL,
		},
	)})</script>`
}
