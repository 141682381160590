import type { FC, ReactNode } from "react"

import { Footer } from "./footer"
import { Header } from "./header"

interface Props {
	children: ReactNode
}
export const SF: FC<Props> = ({ children }) => (
	<>
		<Header />
		{children}
		<Footer />
	</>
)
