import type { ApolloError } from "@apollo/client"
import type { NetworkError } from "@apollo/client/errors"
import type { ErrorResponse } from "@apollo/client/link/error"
import type { GraphQLError } from "graphql/error"

// https://github.com/apollographql/apollo-client/issues/9870
type NetworkErrorWithResult = NetworkError & {
	result: { errors: ReadonlyArray<GraphQLError> }
}
function hasGraphQlErrorsInNetwork(
	error?: NetworkError,
): error is NetworkErrorWithResult {
	return Boolean(
		error &&
			"result" in error &&
			typeof error.result === "object" &&
			Array.isArray(error.result["errors"]) &&
			error.result["errors"].length,
	)
}

export function hasNetworkErrorOnly({
	networkError,
	graphQLErrors,
}: ErrorResponse | ApolloError) {
	return Boolean(
		networkError &&
			!hasGraphQlErrorsInNetwork(networkError) &&
			!graphQLErrors?.length,
	)
}

export function getGraphQlErrors({
	graphQLErrors = [],
	networkError,
}: ErrorResponse | ApolloError): ReadonlyArray<GraphQLError> {
	const networkErrors = hasGraphQlErrorsInNetwork(networkError)
		? networkError.result.errors
		: []

	return [...graphQLErrors, ...networkErrors]
}
