import { Button as MuiButton } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Button = styled(MuiButton)(({ theme }) =>
	theme.unstable_sx({
		p: 1,
		top: 0,
		left: 0,
		opacity: 0,
		zIndex: 999,
		width: "100%",
		borderRadius: 0,
		position: "fixed",
		bgcolor: "#e7e7e7",
		textAlign: "center",
		color: "text.primary",
		transform: "translateY(-100%)",
		transition: theme.transitions.create(["transform", "opacity"]),
		"&:focus": {
			opacity: 1,
			bgcolor: "#e7e7e7",
			transform: "translateY(0)",
			outline: "none",
		},
		"&:hover": { bgcolor: "#e7e7e7" },
	}),
)
