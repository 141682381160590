/* eslint-disable */ 
// @ts-nocheck
import * as Types from '../schema.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateDesignByIdMutationVariables = Types.Exact<{
  dto: Types.UpdateDesignByIdInput;
}>;


export type UpdateDesignByIdMutation = { __typename?: 'Mutation', updateDesignById: { __typename?: 'Design', id: string } };


export const UpdateDesignByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateDesignById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dto"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateDesignByIdInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateDesignById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"updateDesignByIdData"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dto"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateDesignByIdMutation, UpdateDesignByIdMutationVariables>;