import type { Tree } from "@artists/sf-fp"
import { A, O, pipe, RA, Str } from "@artists/sf-fp"

import type { TreeItemMeta, TreeItemWithHref } from "./nav-items"

const itemPathEq = A.getEq(Str.Eq)

export const makeItemPath = (t: Tree.Tree<TreeItemMeta>) => [
	...t.value.parents,
	t.value.key,
]

export const makeTestId = (t: Tree.Tree<TreeItemMeta>) =>
	`${t.value.parents.join("-")}-${t.value.title}`

export const makeUniqName = (t: Tree.Tree<TreeItemMeta>) =>
	pipe(
		RA.last(t.value.parents),
		O.map(lastParent =>
			t.value.title === "all"
				? `${t.value.title} ${lastParent}`
				: t.value.title,
		),
		O.toUndefined,
	)

export const areAllChildrenLinks = (t: Tree.Tree<TreeItemMeta>) =>
	pipe(
		t.forest,
		RA.every(i => i.forest.length === 0),
	)

export const isSelectedItem = (
	itemPath: Array<string>,
	selectedItem: Array<string>,
	depth: number,
) => itemPathEq.equals(itemPath, pipe(selectedItem, A.takeLeft(depth + 1)))

export const treeItemWithHrefGuard = (
	item: Tree.Tree<TreeItemMeta>,
): item is TreeItemWithHref => item.value.href !== undefined
