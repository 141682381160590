import { ClientLogger } from "./client"
import type { ILogger } from "./types"

const getLogger = (): ILogger => {
	if (IS_SERVER) {
		const { ServerLogger } = require("./server")
		return new ServerLogger(IS_DEV)
	} else {
		return new ClientLogger("artists")
	}
}
export const logger = getLogger()
