import { O } from "@artists/sf-fp"

import type { FooterProps } from "./domain/footer"
import { FooterUI } from "./footer-ui"

interface FooterContainerProps extends Omit<FooterProps, "userId"> {
	userId?: string
}

export const FooterContainer = ({
	handleManageCookiesDrawer,
	handleOpenChat,
	userId,
	alpenroseServiceUrl,
}: FooterContainerProps) => (
	<FooterUI
		userId={O.fromNullable(userId)}
		handleManageCookiesDrawer={handleManageCookiesDrawer}
		handleOpenChat={handleOpenChat}
		alpenroseServiceUrl={alpenroseServiceUrl}
	/>
)
