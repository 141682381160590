import {
	fetchUserQuery,
	patchUserPreferences,
	type UserPreferencesPatch,
	userPreferencesPatchDecoder,
} from "@artists/api-client"
import { type Theme } from "@artists/chakra-ui-theme"
import {
	type Measurement,
	measurementDecoder,
} from "@artists/regional-preferences"
import { E, flow, pipe, TCE } from "@artists/sf-fp"
import {
	Box,
	Button,
	chakra,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormControl,
	FormLabel,
	type FormLabelProps,
	Heading,
	Text,
	useDisclosure,
	useRadio,
	useRadioGroup,
	type UseRadioProps,
	useTheme,
	useToast,
} from "@chakra-ui/react"
import { matchSI } from "@simspace/matchers"
import {
	createRef,
	type FormEvent,
	type ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react"
import { useTranslation } from "react-i18next"

const PreferencesLabel = (props: FormLabelProps & { children: ReactNode }) => (
	<FormLabel
		fontSize='x-small'
		textTransform='uppercase'
		{...props}
		color='gray.500'
	>
		{props.children}
	</FormLabel>
)

const MeasurementRadio = (props: UseRadioProps & { children: ReactNode }) => {
	const { getInputProps, getRadioProps } = useRadio(props)
	const theme: Theme = useTheme()

	return (
		<Flex
			as='label'
			flexGrow={1}
			justifyContent='center'
			border={`1px solid ${theme.colors.gray[200]}`}
			bgColor={props.isChecked ? "gray.200" : "white"}
			fontWeight={props.isChecked ? "700" : "normal"}
			py={1}
			_last={{ borderLeft: "none" }}
			fontSize='sm'
		>
			<chakra.input {...getInputProps()} />
			<Box {...getRadioProps()}>{props.children}</Box>
		</Flex>
	)
}

interface UserPreferencesButtonProps {
	basePath: string
}

export const UserPreferencesButton = ({
	basePath,
}: UserPreferencesButtonProps) => {
	const { t } = useTranslation("footer")
	const theme: Theme = useTheme()
	const [defaultMeasurement, setDefaultMeasurement] =
		useState<Measurement>("IMPERIAL")
	const [measurement, setMeasurement] = useState<Measurement>("IMPERIAL")
	const { onOpen, onClose, isOpen } = useDisclosure()
	const buttonRef = createRef<HTMLButtonElement>()
	const formRef = createRef<HTMLFormElement>()
	const { getRootProps, getRadioProps } = useRadioGroup({
		name: "measurement",
		defaultValue: defaultMeasurement,
		onChange: flow(
			measurementDecoder.decode,
			E.fold(console.error, setMeasurement),
		),
	})

	const toastFailure = useToast({
		status: "error",
		isClosable: true,
		position: "top-right",
		duration: null,
	})

	const toastSuccess = useToast({
		status: "success",
		isClosable: true,
		position: "top-right",
		duration: null,
	})

	const handleUpdatePreferences = useCallback(
		async (userPreferences: UserPreferencesPatch) => {
			const resultE = await pipe(
				basePath,
				patchUserPreferences(userPreferences),
			)()

			pipe(
				resultE,
				E.fold(
					() => {
						toastFailure({
							title: "Error updating preferences",
							description:
								"Failed to update preferences. Please try again.",
						})
						setMeasurement(defaultMeasurement)
					},
					result => {
						toastSuccess({
							title: "Preferences updated",
							description:
								"Preferences were successfully updated.",
						})
						setMeasurement(
							result.data.preferences.measurement_system,
						)
					},
				),
			)
		},
		[
			basePath,
			defaultMeasurement,
			setMeasurement,
			toastFailure,
			toastSuccess,
		],
	)

	useEffect(() => {
		async function fetchUser() {
			const userE = await pipe(basePath, fetchUserQuery)()
			pipe(
				userE,
				E.fold(
					() => {
						// Error fetching user data
					},
					user => {
						setDefaultMeasurement(
							user.data.preferences.measurement_system,
						)
					},
				),
			)
		}
		fetchUser()
	}, [basePath])

	const handleSubmit = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault()

			if (formRef.current) {
				const data = new FormData(formRef.current)
				const record = {
					preferences: {
						country: "US",
						currency: "USD",
						measurement_system: data.get("measurement"),
					},
				}
				const resultE = userPreferencesPatchDecoder.decode(record)
				pipe(
					resultE,
					E.mapLeft(
						TCE.getFieldErrors<{
							preferences: {
								measurement_system?: "IMPERIAL" | "METRIC"
							}
						}>(
							matchSI({
								preferences: () => "Error with preferences",
								measurement_system: () =>
									"Error with measurement",
							}),
						),
					),
					E.fold(console.error, handleUpdatePreferences),
				)
			}
		},
		[formRef, handleUpdatePreferences],
	)

	return (
		<>
			<Button
				data-testid='user-preferences-button'
				display='flex'
				alignItems='center'
				variant='outline'
				colorScheme='granite'
				onClick={onOpen}
				ref={buttonRef}
			>
				{measurement === "IMPERIAL"
					? t("navigation.YARD")
					: t("navigation.METER")}
			</Button>
			<Drawer
				isOpen={isOpen}
				placement='right'
				onClose={onClose}
				finalFocusRef={buttonRef}
				size={{ base: "full", md: "md" }}
				blockScrollOnMount={false}
			>
				<DrawerOverlay />
				<DrawerContent
					overflow='auto'
					bgColor='white'
					data-testid='preferences-drawer'
				>
					<Flex direction='column' minHeight='100dvh'>
						<DrawerCloseButton />
						<DrawerHeader>
							<Heading as='h1' size='lg'>
								{t("userPreferences")}
							</Heading>
						</DrawerHeader>
						<DrawerBody>
							<form
								id='preferences-form'
								ref={formRef}
								onSubmit={handleSubmit}
							>
								<Flex direction='column' gap={5}>
									<FormControl>
										<PreferencesLabel htmlFor='measurement'>
											Measurement
										</PreferencesLabel>
										<Flex {...getRootProps()}>
											<MeasurementRadio
												{...getRadioProps({
													value: "IMPERIAL",
												})}
											>
												{t("navigation.YARD")}
											</MeasurementRadio>
											<MeasurementRadio
												{...getRadioProps({
													value: "METRIC",
												})}
											>
												{t("navigation.METER")}
											</MeasurementRadio>
										</Flex>
									</FormControl>
								</Flex>
							</form>
							<Box bg='gray.200' p={4} mt={4}>
								<Heading as='h2' size='xs'>
									Where did country, language, and currency
									selections go?
								</Heading>
								<Text fontSize='xs'>
									Spoonflower is simplifying our site and
									moving to USD and English only. Shipping and
									Billing country can be selected at checkout.
								</Text>
							</Box>
						</DrawerBody>
						<DrawerFooter
							justifyContent='center'
							borderTop={`2px solid ${theme.colors.gray[200]}`}
						>
							<Flex gap={5}>
								<Button
									variant='outline'
									colorScheme='granite'
									type='button'
									onClick={onClose}
								>
									Cancel
								</Button>
								<Button type='submit' form='preferences-form'>
									Update
								</Button>
							</Flex>
						</DrawerFooter>
					</Flex>
				</DrawerContent>
			</Drawer>
		</>
	)
}
