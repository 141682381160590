import { defineStyleConfig } from "@chakra-ui/react"

export default defineStyleConfig({
	variants: {
		"thumbnail-select": {
			control: {
				position: "absolute",
				bottom: 5,
				left: 8,
			},
			container: {
				padding: 4,
			},
		},
	},
})
