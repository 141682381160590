import type { Styles } from "@chakra-ui/theme-tools"

export const styles: Styles = {
	global: {
		html: {
			height: "100%",
		},
		body: {
			height: "100%",
			padding: "0",
		},
		"#root": {
			height: "100%",
		},
		":host": {
			// these are auto-added to body by chakra; we need to copy them for web components
			fontFamily: "body",
			color: "chakra-body-text",
			background: "chakra-body-bg",
			transitionProperty: "background-color",
			transitionDuration: "normal",
			lineHeight: "base",
			position: "relative",
			fontFeatureSettings: "kern",
			fontSize: "16px",

			// these are duplicating our additions to body from above
			height: "100%",
			padding: "0",
		},
	},
} as const
