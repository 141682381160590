import { joinPath } from "@artists/devkit"

import { getENV } from "../index"

export const basename = new URL(getENV("HOST")).pathname
const PUBLIC_PATH = getENV("PUBLIC_PATH")

export const publicPath = (p: string) => joinPath(PUBLIC_PATH, p)

export const basePath = (path: string) => joinPath(basename, path)
