import { A, O, pipe, RA } from "@artists/sf-fp"
import { Box, Button, Flex, Link, List, ListItem } from "@chakra-ui/react"
import ChevronRight from "@mui/icons-material/ChevronRight"
import * as tr from "@simspace/trout/index"
import React from "react"
import { useTranslation } from "react-i18next"

import type { HeaderProps } from "../domain/header"
import type { navItemsMetaForest } from "../utils/nav-items"
import {
	joinRoute,
	loginRoute,
	logoutRoute,
	makeSecondaryMenuHref,
} from "../utils/nav-items"
import {
	areAllChildrenLinks,
	isSelectedItem,
	makeItemPath,
	makeTestId,
	makeUniqName,
	treeItemWithHrefGuard,
} from "../utils/tree-item"
import { Indicator } from "./indicator"
import { RecentlyViewedLink } from "./recently-viewed-link"
import { UploadYourDesignCTA } from "./upload-design-cta"

interface HandleProfileClickParams {
	e:
		| React.KeyboardEvent<HTMLButtonElement>
		| React.MouseEvent<HTMLButtonElement>
	isSelected: boolean
	selectedItem: Array<string>
	setSelectedItem: (value: React.SetStateAction<string[]>) => void
	itemPath: Array<string>
	navRef: React.RefObject<HTMLDivElement>
	depth: number
}

const handleProfileClick = ({
	e,
	isSelected,
	selectedItem,
	setSelectedItem,
	itemPath,
	navRef,
	depth,
}: HandleProfileClickParams) => {
	e.stopPropagation()
	setSelectedItem(
		isSelected ? pipe(selectedItem, A.takeLeft(depth)) : itemPath,
	)

	if (navRef?.current) {
		navRef.current.scrollTop = 0
	}
}

interface RecursiveNavListProps {
	navList: typeof navItemsMetaForest
	selectedItem: Array<string>
	setSelectedItem: React.Dispatch<React.SetStateAction<Array<string>>>
	depth: number
	user: HeaderProps["user"]
	isDesktop: boolean | undefined
	navRef: React.RefObject<HTMLDivElement>
	spoonflowerRedirectUrl: string
	recentlyViewedUrl: string
}

export const RecursiveNavList = ({
	navList,
	selectedItem,
	setSelectedItem,
	depth,
	user,
	isDesktop,
	navRef,
	spoonflowerRedirectUrl,
	recentlyViewedUrl,
}: RecursiveNavListProps) => {
	const { t, i18n } = useTranslation("navigation")
	const isAuthenticated = O.isSome(user)
	const isSpanish = i18n.resolvedLanguage === "es"
	const isFrench = i18n.resolvedLanguage === "fr"

	return (
		<React.Fragment key={`fragment-${depth}`}>
			<div className='header-wrapper'>
				{isAuthenticated ? (
					<Link
						variant='header'
						href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
							logoutRoute,
						)({})}`}
					>
						{t("logOut.text")}
					</Link>
				) : (
					<Box>
						<Link
							variant='header'
							href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
								loginRoute,
							)({})}`}
						>
							{t("logIn.text")}
						</Link>{" "}
						<Box as='span'>{t("loginORjoin")}</Box>{" "}
						<Link
							variant='header'
							href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
								joinRoute,
							)({})}`}
						>
							{t("join.text")}
						</Link>
					</Box>
				)}
			</div>

			{pipe(
				navList,
				A.mapWithIndex((index, item) => {
					const uniqName = makeUniqName(item)
					const testId = makeTestId(item)
					const itemPath = makeItemPath(item)
					const isSelected = isSelectedItem(
						itemPath,
						selectedItem,
						depth,
					)
					const isMessages = item.value.title === "messages"
					const isSelectedDepth = selectedItem.length === depth
					const handleProfileClickParams = {
						isSelected,
						selectedItem,
						setSelectedItem,
						itemPath,
						navRef,
						depth,
					} as const

					/**
					 * Do not render parent elements on mobile so that the child
					 * elements only take up as much height as they need and not
					 * take up the additional space of the parent is taking up.
					 *
					 * AR - 2023-02-02
					 */
					const shouldHideParentMenusOnMobile =
						!isDesktop && !isSelectedDepth && !isSelected

					if (
						(item.value.title === "category" ||
							item.value.title === "color") &&
						(isSpanish || isFrench)
					) {
						/**
						 * On spoonflower.com, by category and by color, do not
						 * render for Spanish or French so I am replicating this
						 * behavior here as well.
						 *
						 * AR - 2022-12-30
						 */
						return undefined
					}

					if (!isAuthenticated && item.value.title === "account") {
						return undefined
					}

					const keyValue: { text: string; href?: string } = t(
						`${item.value.key}`,
						{
							returnObjects: true,
						},
					)

					return (
						<React.Fragment key={`${item.value.key}-fragment`}>
							<ListItem
								/** ARIA */
								role='presentation'
								/** Element */
								key={`${item.value.key}-list-item`}
								data-depth={depth}
								/** CSS */
								className='nav-list-item'
								data-hide-on-mobile={
									shouldHideParentMenusOnMobile
								}
								data-max-items-reached={index >= 9}
								data-is-selected={isSelected}
							>
								{RA.isNonEmpty(item.forest) ? (
									<React.Fragment
										key={`${item.value.key}-fragment`}
									>
										<Flex
											/** ARIA */
											role='heading'
											aria-level={depth}
											className='nav-button-container'
											key={`${item.value.key}-forest`}
										>
											<Button
												/** ARIA */
												id={uniqName}
												aria-expanded={isSelected}
												data-testid={testId}
												className='nav-button'
												data-depth={depth}
												/** PROPS */
												variant='unstyled'
												rightIcon={
													<ChevronRight
														fontSize='small'
														className='expand-submenu'
														data-depth={depth}
													/>
												}
												iconSpacing='auto'
												onClick={e => {
													handleProfileClick({
														e,
														...handleProfileClickParams,
													})
												}}
												{...(isDesktop && {
													onMouseEnter: () => {
														if (
															selectedItem.length >
															1
														) {
															setSelectedItem([
																"root",
															])
														}
														;(
															document.activeElement as HTMLElement
														).blur()
													},

													onMouseLeave: () => {
														if (
															selectedItem.length >
															1
														) {
															setSelectedItem([
																"root",
															])
														}
														;(
															document.activeElement as HTMLElement
														).blur()
													},
												})}
											>
												{t(`${item.value.key}.text`)}
											</Button>
										</Flex>
										<Flex
											role='region'
											aria-labelledby={uniqName}
											key={`${uniqName}-region`}
										>
											<List
												/** Element */
												key={`${uniqName}-options`}
												data-testid={`${uniqName}-options`}
												className='submenu-container'
												data-depth={depth}
												data-is-selected={isSelected}
												data-is-about-menu={
													item.value.title ===
													"about-menu"
												}
												data-all-children-are-links={areAllChildrenLinks(
													item,
												)}
												data-has-over-50-children={
													item.forest.length > 50
												}
												data-has-over-10-children={
													item.forest.length > 10
												}
												{...(isDesktop && {
													onMouseEnter: () => {
														if (
															selectedItem.length >
															1
														) {
															setSelectedItem([
																"root",
															])
														}
														;(
															document.activeElement as HTMLElement
														).blur()
													},
													onMouseLeave: () => {
														if (
															selectedItem.length >
															1
														) {
															setSelectedItem([
																"root",
															])
														}
														;(
															document.activeElement as HTMLElement
														).blur()
													},
												})}
											>
												<RecursiveNavList
													key={`recursive-list-${depth}`}
													navList={item.forest}
													depth={depth + 1}
													selectedItem={selectedItem}
													setSelectedItem={
														setSelectedItem
													}
													user={user}
													isDesktop={isDesktop}
													navRef={navRef}
													spoonflowerRedirectUrl={
														spoonflowerRedirectUrl
													}
													recentlyViewedUrl={
														recentlyViewedUrl
													}
												/>
											</List>
										</Flex>
									</React.Fragment>
								) : item.value.title === "upload" &&
								  depth === 1 ? (
									<UploadYourDesignCTA
										key='upload-button'
										spoonflowerRedirectUrl={
											spoonflowerRedirectUrl
										}
									>
										Upload A Design
									</UploadYourDesignCTA>
								) : (
									<Link
										key={`${item.value.key}-link`}
										data-testid={testId}
										className='nav-link'
										href={
											treeItemWithHrefGuard(item)
												? `${spoonflowerRedirectUrl}${makeSecondaryMenuHref(
														item,
														pipe(
															user,
															O.fold(
																() => "",
																u => u.userId,
															),
														),
														pipe(
															user,
															O.fold(
																() => "",
																u => u.username,
															),
														),
														i18n.resolvedLanguage ??
															"en",
													)}`
												: keyValue.href?.startsWith(
															"https://",
													  )
													? keyValue.href
													: `${spoonflowerRedirectUrl}${keyValue.href}` ??
														"no href value for translation"
										}
									>
										{t(`${item.value.key}.text`)}
										{isAuthenticated && isMessages && (
											<Indicator
												count={user.value.messages}
												mt={-1}
												ml={0}
											/>
										)}
									</Link>
								)}
							</ListItem>
						</React.Fragment>
					)
				}),
			)}
			{depth === 1 && recentlyViewedUrl.includes("stage") && (
				<RecentlyViewedLink
					url={recentlyViewedUrl}
					styles={{ display: { base: "inline", lg: "none" } }}
				/>
			)}
		</React.Fragment>
	)
}
