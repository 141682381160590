import posthog from "posthog-js"
import { useEffect } from "react"

import { postHogOptions } from "../../../config/posthog"
import { getENV } from "../../@common"
import { useAuth } from "../api"

export const usePostHogInit = () => {
	const { user } = useAuth()

	useEffect(() => {
		posthog.init(getENV("POSTHOG_API_KEY"), {
			...postHogOptions,
			bootstrap: { distinctID: user?.userId },
		})
	}, [user?.userId])
}
