/* eslint-disable */ 
// @ts-nocheck
import * as Types from '../schema.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AddEntryToChallengeMutationVariables = Types.Exact<{
  dto: Types.CreateChallengeEntryInput;
}>;


export type AddEntryToChallengeMutation = { __typename?: 'Mutation', createChallengeEntry: { __typename?: 'ChallengeEntry', id: string, designId: string, entryImage: string, entryDescription: string, entryPlace?: number | null, entryState: Types.ChallengeEntryState, entryStatus: Types.ChallengeEntryStatus, entryTitle: string, challengeId: string, createdAt: string, validVotes?: number | null, user: { __typename?: 'BaseUser', userId: string, userName: string, bestResult?: number | null } } };


export const AddEntryToChallengeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddEntryToChallenge"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dto"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateChallengeEntryInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createChallengeEntry"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createChallengeEntryData"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dto"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}},{"kind":"Field","name":{"kind":"Name","value":"bestResult"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"designId"}},{"kind":"Field","name":{"kind":"Name","value":"entryImage"}},{"kind":"Field","name":{"kind":"Name","value":"entryDescription"}},{"kind":"Field","name":{"kind":"Name","value":"entryPlace"}},{"kind":"Field","name":{"kind":"Name","value":"entryState"}},{"kind":"Field","name":{"kind":"Name","value":"entryStatus"}},{"kind":"Field","name":{"kind":"Name","value":"entryTitle"}},{"kind":"Field","name":{"kind":"Name","value":"challengeId"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"validVotes"}}]}}]}}]} as unknown as DocumentNode<AddEntryToChallengeMutation, AddEntryToChallengeMutationVariables>;