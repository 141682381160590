import { Workbox } from "workbox-window"

import { basename, basePath, logger } from "../../../@common"

export function registerSW(_: () => Promise<boolean>) {
	if (!("serviceWorker" in navigator)) return

	const wb = new Workbox(basePath("/service-worker.js"), { scope: basename })

	wb.addEventListener("waiting", () => {
		logger.info(
			"A new service worker has installed, but it can't activate until all tabs running the current version have fully unloaded.",
		)

		wb.addEventListener("controlling", () => window.location.reload())
		// Temporally since headers has a breaking changes (CDN assets are replaced in prod)
		// We will force the new SW to take control
		wb.messageSkipWaiting()
	})

	wb.register()
}
