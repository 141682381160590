import { Close } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useSnackbar } from "notistack"
import type { FC } from "react"

interface Props {
	onClick?: () => void
}
export const CloseAction: FC<Props> = ({ onClick }) => {
	const { closeSnackbar } = useSnackbar()

	return (
		<IconButton
			color='inherit'
			onClick={() => {
				closeSnackbar()
				onClick?.()
			}}
		>
			<Close />
		</IconButton>
	)
}
