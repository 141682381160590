import { A, pipe, RA, tr, Tree } from "@artists/sf-fp"
import { match as matchSim } from "@simspace/matchers"
import { match } from "ts-pattern"

export const studioRoute = pipe(tr.RootPath, tr.path("studio"))

export const accountRoute = pipe(
	tr.RootPath,
	tr.path("account"),
	tr.param("userId", tr.stringRC),
	tr.queryParam("sub_action", tr.stringLiteralRC("orders", "settings")),
)

export const favoritesRoute = pipe(
	tr.RootPath,
	tr.path("favorites"),
	tr.param("username", tr.stringRC),
)

export const collectionsRoute = pipe(
	tr.RootPath,
	tr.path("profiles"),
	tr.param("username", tr.stringRC),
	tr.path("collections"),
	tr.queryParam("filter", tr.stringLiteralRC("designed")),
)

export const shopRoute = pipe(
	tr.RootPath,
	tr.path("profiles"),
	tr.param("username", tr.stringRC),
)

export const designsRoute = pipe(tr.RootPath, tr.path("designs"))

export const designsNewRoute = pipe(
	tr.RootPath,
	tr.path("designs"),
	tr.path("new"),
)

export const myDesignChallengeRoute = pipe(
	tr.RootPath,
	tr.path("design-challenge"),
	tr.path("me"),
	tr.path("voting"),
)

export const notificationsRoute = pipe(
	tr.RootPath,
	tr.path("userhome"),
	tr.param("userId", tr.stringRC),
)

export const messagesRoute = pipe(tr.RootPath, tr.path("messages"))

export const logoutRoute = pipe(tr.RootPath, tr.path("logout"))

export const loginRoute = pipe(tr.RootPath, tr.path("login"))

export const joinRoute = pipe(tr.RootPath, tr.path("register"), tr.path("new"))

export const cartRoute = pipe(
	tr.RootPath,
	tr.param("locale", tr.stringRC),
	tr.path("cart"),
)

export const adminRoute = pipe(tr.RootPath, tr.path("admin"))

export interface NavItem {
	title: string
	key: string
	items: Readonly<Array<NavItem>>
	href?: (() => string) | ((value: string) => string)
}

export type NavItems = ReadonlyArray<NavItem>

export type TreeItemWithHref = Tree.Tree<
	Omit<TreeItemMeta, "href"> & {
		href: (value?: string) => string
	}
>

export const makeProfileMenuHref = (
	item: (typeof profileMenuItems)[number],
	userId: string,
	username: string,
): string =>
	matchSim.on("title").i(item)({
		orders: i => i.href(userId),
		account: i => i.href(userId),
		notifications: i => i.href(userId),
		challenges: i => i.href(),
		collections: i => i.href(username),
		favorites: i => i.href(username),
		shop: i => i.href(username),
		dashboard: i => i.href(),
		logout: i => i.href(),
		messages: i => i.href(),
		library: i => i.href(),
		upload: i => i.href(),
	})

export const makeSecondaryMenuHref = (
	item: TreeItemWithHref,
	userId: string,
	username: string,
	locale: string,
): string =>
	match(item)
		.with({ value: { title: "cart" } }, i => i.value.href(locale))
		.with({ value: { title: "my dashboard" } }, i => i.value.href(userId))
		.with({ value: { title: "orders" } }, i => i.value.href(userId))
		.with({ value: { title: "account settings" } }, i =>
			i.value.href(userId),
		)
		.with({ value: { title: "favorites" } }, i => i.value.href(username))
		.with({ value: { title: "collections" } }, i => i.value.href(username))
		.with({ value: { title: "design library" } }, i => i.value.href())
		.with({ value: { title: "view my shop" } }, i => i.value.href(username))
		.with({ value: { title: "notifications" } }, i => i.value.href(userId))
		.with({ value: { title: "messages" } }, i => i.value.href())
		.with({ value: { title: "my design challenges" } }, i =>
			i.value.href(userId),
		)
		.with({ value: { title: "logout" } }, i => i.value.href())
		.otherwise(() => "no href for item")

export const profileMenuItems = [
	{
		title: "dashboard",
		key: "studio",
		href: () => tr.encodeUrl(studioRoute)({}),
	},
	{
		title: "orders",
		key: "orders",
		href: (userId: string) =>
			tr.encodeUrl(accountRoute)({
				route: { userId },
				query: { sub_action: "orders" },
			}),
	},
	{
		title: "account",
		key: "myAccount",
		href: (userId: string) =>
			tr.encodeUrl(accountRoute)({
				route: { userId },
				query: { sub_action: "settings" },
			}),
		namespace: "navigation",
	},
	{
		title: "favorites",
		key: "favorites",
		href: (username: string) =>
			tr.encodeUrl(favoritesRoute)({ route: { username } }),
	},
	{
		title: "collections",
		key: "collections",
		href: (username: string) =>
			tr.encodeUrl(collectionsRoute)({
				route: { username },
				query: { filter: "designed" },
			}),
	},
	{
		title: "library",
		key: "designLibrary",
		href: () => tr.encodeUrl(designsRoute)({}),
	},
	{
		title: "shop",
		key: "myShop",
		href: (username: string) =>
			tr.encodeUrl(shopRoute)({ route: { username } }),
	},
	{
		title: "upload",
		key: "uploadYourDesign",
		href: () => tr.encodeUrl(designsNewRoute)({}),
	},
	{
		title: "notifications",
		key: "notifications",
		href: (userId: string) =>
			tr.encodeUrl(notificationsRoute)({ route: { userId } }),
	},
	{
		title: "messages",
		key: "messages",
		href: () => tr.encodeUrl(messagesRoute)({}),
	},
	{
		title: "challenges",
		key: "myDesignChallenges",
		href: () => tr.encodeUrl(myDesignChallengeRoute)({}),
	},
	{
		title: "logout",
		key: "logOut",
		href: () => tr.encodeUrl(logoutRoute)({}),
	},
] as const

export const navItems = [
	{
		title: "inspiration-menu",
		key: "inspiration-menu",
		items: [
			{
				title: "inspirationInspiration",
				key: "inspirationInspiration",
				items: [],
			},
			{
				title: "category",
				key: "byCategoriesMenu",
				items: [
					{
						title: "top",
						key: "topAndTrending",
						items: [
							{ title: "all", key: "allTrending", items: [] },
							{ title: "art", key: "artNouveau", items: [] },
							{ title: "baby", key: "babyBoy", items: [] },
							{ title: "beach", key: "beach", items: [] },
							{ title: "bicycle", key: "bicycle", items: [] },
							{ title: "birds", key: "birds", items: [] },
							{ title: "black", key: "blackAndWhite", items: [] },
							{ title: "border", key: "border", items: [] },
							{ title: "butterfly", key: "butterfly", items: [] },
							{ title: "cat", key: "cat", items: [] },
							{ title: "chevron", key: "chevron", items: [] },
							{ title: "damask", key: "damask", items: [] },
							{ title: "deer", key: "deer", items: [] },
							{ title: "dog", key: "dog", items: [] },
							{ title: "elephant", key: "elephant", items: [] },
							{ title: "feathers", key: "feathers", items: [] },
							{ title: "fish", key: "fish", items: [] },
							{ title: "floral", key: "floral", items: [] },
							{ title: "forest", key: "forest", items: [] },
							{ title: "fox", key: "fox", items: [] },
							{ title: "fruit", key: "fruit", items: [] },
							{ title: "geek", key: "geek", items: [] },
							{ title: "geometric", key: "geometric", items: [] },
							{ title: "horse", key: "horse", items: [] },
							{ title: "ice cream", key: "iceCream", items: [] },
							{ title: "ikat", key: "ikat", items: [] },
							{ title: "japanese", key: "japanese", items: [] },
							{ title: "kawaii", key: "kawaii", items: [] },
							{ title: "kitchen", key: "kitchen", items: [] },
							{ title: "map", key: "map", items: [] },
							{ title: "maple", key: "mapleLeaf", items: [] },
							{ title: "mermaid", key: "mermaid", items: [] },
							{ title: "mudcloth", key: "mudcloth", items: [] },
							{ title: "nautical", key: "nautical", items: [] },
							{ title: "ocean", key: "ocean", items: [] },
							{ title: "owl", key: "owl", items: [] },
							{ title: "paisley", key: "paisley", items: [] },
							{ title: "peacock", key: "peacock", items: [] },
							{ title: "rainbow", key: "rainbow", items: [] },
							{ title: "retro", key: "retro", items: [] },
							{ title: "science", key: "science", items: [] },
							{ title: "skull", key: "skull", items: [] },
							{ title: "space", key: "space", items: [] },
							{ title: "stars", key: "stars", items: [] },
							{ title: "steampunk", key: "steampunk", items: [] },
							{ title: "stripes", key: "stripes", items: [] },
							{ title: "tea towel", key: "teaTowel", items: [] },
							{ title: "tiki", key: "tiki", items: [] },
							{ title: "toile", key: "toile", items: [] },
							{ title: "victorian", key: "victorian", items: [] },
							{
								title: "watercolor",
								key: "watercolor",
								items: [],
							},
							{
								title: "woodland",
								key: "allWoodland",
								items: [],
							},
							{ title: "yellow", key: "yellow", items: [] },
						],
					},
					{
						title: "style",
						key: "style",
						items: [
							{
								title: "modern",
								key: "modern",
								items: [
									{
										title: "all",
										key: "allModern",
										items: [],
									},
									{
										title: "abstract",
										key: "abstract",
										items: [],
									},
									{
										title: "arrows",
										key: "arrows",
										items: [],
									},
									{ title: "art", key: "artDeco", items: [] },
									{
										title: "chevron",
										key: "chevron",
										items: [],
									},
									{
										title: "geometric",
										key: "geometric",
										items: [],
									},
									{
										title: "hipster",
										key: "hipster",
										items: [],
									},
									{
										title: "modern",
										key: "midCenturyModern",
										items: [],
									},
									{ title: "ikat", key: "ikat", items: [] },
									{ title: "mod", key: "mod", items: [] },
									{ title: "ombre", key: "ombre", items: [] },
								],
							},
							{
								title: "traditional",
								key: "traditional",
								items: [
									{
										title: "all",
										key: "allTraditional",
										items: [],
									},
									{ title: "check", key: "check", items: [] },
									{
										title: "chinoiserie",
										key: "chinoiserie",
										items: [],
									},
									{
										title: "damask",
										key: "damask",
										items: [],
									},
									{ title: "dots", key: "dots", items: [] },
									{
										title: "gingham",
										key: "gingham",
										items: [],
									},
									{
										title: "herringbone",
										key: "herringbone",
										items: [],
									},
									{
										title: "houndstooth",
										key: "houndstooth",
										items: [],
									},
									{
										title: "paisley",
										key: "paisley",
										items: [],
									},
									{ title: "plaid", key: "plaid", items: [] },
									{
										title: "preppy",
										key: "preppy",
										items: [],
									},
									{
										title: "shabby",
										key: "shabbyChic",
										items: [],
									},
									{
										title: "stripes",
										key: "stripes",
										items: [],
									},
									{ title: "toile", key: "toile", items: [] },
									{
										title: "vintage",
										key: "vintage",
										items: [],
									},
								],
							},
							{
								title: "historical",
								key: "historical",
								items: [
									{
										title: "all",
										key: "allHistorical",
										items: [],
									},
									{
										title: "deco",
										key: "artDeco",
										items: [],
									},
									{
										title: "nouveau",
										key: "artNouveau",
										items: [],
									},
									{
										title: "gothic",
										key: "gothic",
										items: [],
									},
									{
										title: "medieval",
										key: "medieval",
										items: [],
									},
									{
										title: "modern",
										key: "midCenturyModern",
										items: [],
									},
									{ title: "pop", key: "popArt", items: [] },
									{
										title: "renaissance",
										key: "renaissance",
										items: [],
									},
									{
										title: "rococo",
										key: "rococo",
										items: [],
									},
									{ title: "retro", key: "retro", items: [] },
									{
										title: "steampunk",
										key: "steampunk",
										items: [],
									},
									{
										title: "victorian",
										key: "victorian",
										items: [],
									},
									{
										title: "vintage",
										key: "vintage",
										items: [],
									},
									{ title: "60s", key: "60s", items: [] },
									{ title: "70s", key: "70s", items: [] },
									{ title: "80s", key: "80s", items: [] },
									{ title: "90s", key: "90s", items: [] },
								],
							},
							{
								title: "nautical",
								key: "nautical",
								items: [
									{
										title: "all",
										key: "allNautical",
										items: [],
									},
									{
										title: "anchors",
										key: "anchors",
										items: [],
									},
									{ title: "boats", key: "boats", items: [] },
									{ title: "buoys", key: "buoys", items: [] },
									{ title: "knots", key: "knots", items: [] },
									{
										title: "lighthouse",
										key: "lighthouse",
										items: [],
									},
									{
										title: "lobster",
										key: "lobster",
										items: [],
									},
									{ title: "map", key: "map", items: [] },
									{
										title: "mermaid",
										key: "mermaid",
										items: [],
									},
									{
										title: "octopus",
										key: "octopus",
										items: [],
									},
									{
										title: "shells",
										key: "shells",
										items: [],
									},
									{ title: "ship", key: "ship", items: [] },
								],
							},
							{
								title: "artistic",
								key: "artistic",
								items: [
									{
										title: "all",
										key: "allArtistic",
										items: [],
									},
									{
										title: "abstract",
										key: "abstract",
										items: [],
									},
									{
										title: "brush",
										key: "brushStroke",
										items: [],
									},
									{
										title: "hand",
										key: "handDrawn",
										items: [],
									},
									{
										title: "photography",
										key: "photography",
										items: [],
									},
									{
										title: "sketches",
										key: "sketches",
										items: [],
									},
									{
										title: "splatter",
										key: "splatterPaint",
										items: [],
									},
									{
										title: "watercolor",
										key: "watercolor",
										items: [],
									},
								],
							},
							{
								title: "shapes",
								key: "shapes",
								items: [
									{
										title: "all",
										key: "allShapes",
										items: [],
									},
									{
										title: "arrows",
										key: "arrows",
										items: [],
									},
									{
										title: "circle",
										key: "circle",
										items: [],
									},
									{ title: "cross", key: "cross", items: [] },
									{ title: "dots", key: "dots", items: [] },
									{
										title: "fleur",
										key: "fleurDeLis",
										items: [],
									},
									{
										title: "geometric",
										key: "geometric",
										items: [],
									},
									{ title: "grid", key: "grid", items: [] },
									{
										title: "diamond",
										key: "diamond",
										items: [],
									},
									{
										title: "hearts",
										key: "hearts",
										items: [],
									},
									{
										title: "hexagon",
										key: "hexagon",
										items: [],
									},
									{
										title: "honeycomb",
										key: "honeycombPattern",
										items: [],
									},
									{ title: "pixel", key: "pixel", items: [] },
									{
										title: "quatrefoil",
										key: "quatrefoil",
										items: [],
									},
									{ title: "stars", key: "stars", items: [] },
									{
										title: "trellis",
										key: "trellis",
										items: [],
									},
									{
										title: "triangles",
										key: "triangles",
										items: [],
									},
								],
							},
							{
								title: "texture",
								key: "texture",
								items: [
									{
										title: "all",
										key: "allTexture",
										items: [],
									},
									{
										title: "birch",
										key: "birchBark",
										items: [],
									},
									{ title: "lace", key: "lace", items: [] },
									{ title: "linen", key: "linen", items: [] },
									{
										title: "marble",
										key: "marble",
										items: [],
									},
									{
										title: "shibori",
										key: "shibori",
										items: [],
									},
									{
										title: "stained",
										key: "stainedGlass",
										items: [],
									},
									{ title: "wood", key: "wood", items: [] },
									{
										title: "woodgrain",
										key: "woodgrain",
										items: [],
									},
								],
							},
							{
								title: "geography",
								key: "geography",
								items: [
									{
										title: "all",
										key: "allGeography",
										items: [],
									},
									{ title: "asian", key: "asian", items: [] },
									{
										title: "chinese",
										key: "chinese",
										items: [],
									},
									{
										title: "scandinavian",
										key: "scandinavian",
										items: [],
									},
									{
										title: "mexican",
										key: "mexican",
										items: [],
									},
									{ title: "paris", key: "paris", items: [] },
									{
										title: "chinoiserie",
										key: "chinoiserie",
										items: [],
									},
									{
										title: "indian",
										key: "indian",
										items: [],
									},
									{
										title: "kawaii",
										key: "kawaii",
										items: [],
									},
									{
										title: "african",
										key: "african",
										items: [],
									},
									{
										title: "london",
										key: "london",
										items: [],
									},
									{
										title: "hawaiian",
										key: "hawaiian",
										items: [],
									},
									{
										title: "french",
										key: "french",
										items: [],
									},
									{
										title: "tropical",
										key: "tropical",
										items: [],
									},
									{
										title: "japanese",
										key: "japanese",
										items: [],
									},
									{
										title: "turkish",
										key: "turkish",
										items: [],
									},
								],
							},
							{
								title: "worldly",
								key: "worldly",
								items: [
									{
										title: "all",
										key: "allWorldly",
										items: [],
									},
									{ title: "aztec", key: "aztec", items: [] },
									{
										title: "bohemian",
										key: "bohemian",
										items: [],
									},
									{
										title: "celtic",
										key: "celtic",
										items: [],
									},
									{ title: "ikat", key: "ikat", items: [] },
									{
										title: "mandala",
										key: "mandala",
										items: [],
									},
									{
										title: "leaf",
										key: "mapleLeaf",
										items: [],
									},
									{
										title: "moroccan",
										key: "moroccan",
										items: [],
									},
									{
										title: "mudcloth",
										key: "mudcloth",
										items: [],
									},
									{
										title: "american",
										key: "nativeAmerican",
										items: [],
									},
									{
										title: "persian",
										key: "persian",
										items: [],
									},
									{
										title: "tribal",
										key: "tribal",
										items: [],
									},
								],
							},
						],
					},
					{
						title: "animals",
						key: "animals",
						items: [
							{ title: "all", key: "allAnimals", items: [] },
							{
								title: "woodland",
								key: "woodlandAnimals",
								items: [
									{
										title: "all",
										key: "allWoodlandAnimals",
										items: [],
									},
									{ title: "bat", key: "bat", items: [] },
									{ title: "bear", key: "bear", items: [] },
									{ title: "deer", key: "deer", items: [] },
									{ title: "fox", key: "fox", items: [] },
									{
										title: "hedgehog",
										key: "hedgehog",
										items: [],
									},
									{ title: "moose", key: "moose", items: [] },
									{ title: "owl", key: "owl", items: [] },
									{
										title: "rabbit",
										key: "rabbit",
										items: [],
									},
									{
										title: "squirrel",
										key: "squirrel",
										items: [],
									},
									{ title: "stag", key: "stag", items: [] },
									{ title: "wolf", key: "wolf", items: [] },
								],
							},
							{
								title: "farmPets",
								key: "farm",
								items: [
									{ title: "all", key: "allFarm", items: [] },
									{
										title: "chicken",
										key: "chicken",
										items: [],
									},
									{ title: "cows", key: "cows", items: [] },
									{
										title: "donkey",
										key: "donkey",
										items: [],
									},
									{ title: "ducks", key: "ducks", items: [] },
									{
										title: "equestrian",
										key: "equestrian",
										items: [],
									},
									{ title: "goat", key: "goat", items: [] },
									{ title: "hen", key: "hen", items: [] },
									{ title: "horse", key: "horse", items: [] },
									{ title: "llama", key: "llama", items: [] },
									{ title: "pig", key: "pig", items: [] },
									{ title: "sheep", key: "sheep", items: [] },
								],
							},
							{
								title: "pets",
								key: "pets",
								items: [
									{ title: "all", key: "allPets", items: [] },
									{ title: "bird", key: "bird", items: [] },
									{ title: "cat", key: "cat", items: [] },
									{ title: "dog", key: "dog", items: [] },
									{ title: "fish", key: "fish", items: [] },
									{
										title: "hamster",
										key: "hamster",
										items: [],
									},
									{
										title: "parrot",
										key: "parrot",
										items: [],
									},
									{ title: "snake", key: "snake", items: [] },
									{
										title: "spider",
										key: "spider",
										items: [],
									},
								],
							},
							{
								title: "ocean",
								key: "ocean",
								items: [
									{
										title: "all",
										key: "allOcean",
										items: [],
									},
									{ title: "crab", key: "crab", items: [] },
									{
										title: "dolphin",
										key: "dolphin",
										items: [],
									},
									{ title: "fish", key: "fish", items: [] },
									{
										title: "jellyfish",
										key: "jellyfish",
										items: [],
									},
									{ title: "koi", key: "koi", items: [] },
									{
										title: "lobster",
										key: "lobster",
										items: [],
									},
									{
										title: "octopus",
										key: "octopus",
										items: [],
									},
									{
										title: "penguin",
										key: "penguin",
										items: [],
									},
									{
										title: "seahorse",
										key: "seahorse",
										items: [],
									},
									{ title: "shark", key: "shark", items: [] },
									{
										title: "starfish",
										key: "starfish",
										items: [],
									},
									{ title: "whale", key: "whale", items: [] },
								],
							},
							{
								title: "insects",
								key: "insects",
								items: [
									{
										title: "all",
										key: "allInsects",
										items: [],
									},
									{ title: "bee", key: "bee", items: [] },
									{
										title: "beetle",
										key: "beetle",
										items: [],
									},
									{ title: "bug", key: "bug", items: [] },
									{
										title: "butterfly",
										key: "butterfly",
										items: [],
									},
									{
										title: "caterpillar",
										key: "caterpillar",
										items: [],
									},
									{
										title: "dragonfly",
										key: "dragonfly",
										items: [],
									},
									{
										title: "firefly",
										key: "firefly",
										items: [],
									},
									{
										title: "spider",
										key: "spider",
										items: [],
									},
								],
							},
							{
								title: "birds",
								key: "birds",
								items: [
									{
										title: "all",
										key: "allBirds",
										items: [],
									},
									{
										title: "flamingo",
										key: "flamingo",
										items: [],
									},
									{
										title: "hummingbird",
										key: "hummingbird",
										items: [],
									},
									{ title: "owl", key: "owl", items: [] },
									{
										title: "parrot",
										key: "parrot",
										items: [],
									},
									{
										title: "peacock",
										key: "peacock",
										items: [],
									},
									{ title: "robin", key: "robin", items: [] },
									{
										title: "sparrow",
										key: "sparrow",
										items: [],
									},
									{
										title: "toucan",
										key: "toucan",
										items: [],
									},
								],
							},
							{
								title: "zoo",
								key: "zoo",
								items: [
									{ title: "all", key: "allZoo", items: [] },
									{ title: "bears", key: "bears", items: [] },
									{
										title: "giraffe",
										key: "giraffe",
										items: [],
									},
									{ title: "hippo", key: "hippo", items: [] },
									{ title: "llama", key: "llama", items: [] },
									{ title: "lions", key: "lions", items: [] },
									{
										title: "monkey",
										key: "monkey",
										items: [],
									},
									{ title: "panda", key: "panda", items: [] },
									{ title: "rhino", key: "rhino", items: [] },
									{
										title: "tigers",
										key: "tigers",
										items: [],
									},
									{ title: "zebra", key: "zebra", items: [] },
								],
							},
							{
								title: "safari",
								key: "safari",
								items: [
									{
										title: "all",
										key: "allSafari",
										items: [],
									},
									{
										title: "cheetah",
										key: "cheetah",
										items: [],
									},
									{
										title: "elephant",
										key: "elephant",
										items: [],
									},
									{
										title: "giraffe",
										key: "giraffe",
										items: [],
									},
									{ title: "hippo", key: "hippo", items: [] },
									{
										title: "leopard",
										key: "leopard",
										items: [],
									},
									{ title: "lions", key: "lions", items: [] },
									{
										title: "monkey",
										key: "monkey",
										items: [],
									},
									{ title: "rhino", key: "rhino", items: [] },
									{ title: "sloth", key: "sloth", items: [] },
									{
										title: "tigers",
										key: "tigers",
										items: [],
									},
									{ title: "zebra", key: "zebra", items: [] },
								],
							},
							{
								title: "dog",
								key: "dogBreeds",
								items: [
									{
										title: "all",
										key: "allDogBreeds",
										items: [],
									},
									{
										title: "bulldog",
										key: "bulldog",
										items: [],
									},
									{ title: "corgi", key: "corgi", items: [] },
									{
										title: "dachshund",
										key: "dachshund",
										items: [],
									},
									{
										title: "dalmatian",
										key: "dalmatian",
										items: [],
									},
									{
										title: "retriever",
										key: "goldenRetriever",
										items: [],
									},
									{
										title: "greyhound",
										key: "greyhound",
										items: [],
									},
									{
										title: "poodle",
										key: "poodle",
										items: [],
									},
									{ title: "pug", key: "pug", items: [] },
									{
										title: "yorkie",
										key: "yorkie",
										items: [],
									},
								],
							},
						],
					},
					{
						title: "nature",
						key: "nature",
						items: [
							{ title: "all", key: "allNature", items: [] },
							{
								title: "botanical",
								key: "botanical",
								items: [
									{
										title: "all",
										key: "allBotanical",
										items: [],
									},
									{
										title: "cactus",
										key: "cactus",
										items: [],
									},
									{
										title: "dandelion",
										key: "dandelion",
										items: [],
									},
									{ title: "fauna", key: "fauna", items: [] },
									{
										title: "feathers",
										key: "feathers",
										items: [],
									},
									{ title: "flora", key: "flora", items: [] },
									{
										title: "flowers",
										key: "flowers",
										items: [],
									},
									{ title: "fruit", key: "fruit", items: [] },
									{
										title: "leaves",
										key: "leaves",
										items: [],
									},
									{
										title: "mushroom",
										key: "mushroom",
										items: [],
									},
									{ title: "palm", key: "palm", items: [] },
									{ title: "peony", key: "peony", items: [] },
									{ title: "poppy", key: "poppy", items: [] },
									{ title: "rose", key: "rose", items: [] },
									{ title: "seeds", key: "seeds", items: [] },
									{
										title: "succulents",
										key: "succulents",
										items: [],
									},
									{
										title: "sunflower",
										key: "sunflower",
										items: [],
									},
									{
										title: "thistles",
										key: "thistles",
										items: [],
									},
									{ title: "trees", key: "trees", items: [] },
								],
							},
							{
								title: "space",
								key: "space",
								items: [
									{
										title: "all",
										key: "allSpace",
										items: [],
									},
									{
										title: "asteroids",
										key: "asteroids",
										items: [],
									},
									{
										title: "astronaut",
										key: "astronaut",
										items: [],
									},
									{
										title: "constellations",
										key: "constellations",
										items: [],
									},
									{
										title: "galaxy",
										key: "galaxy",
										items: [],
									},
									{ title: "moon", key: "moon", items: [] },
									{
										title: "nebula",
										key: "nebula",
										items: [],
									},
									{
										title: "planets",
										key: "planets",
										items: [],
									},
									{ title: "sky", key: "sky", items: [] },
									{ title: "stars", key: "stars", items: [] },
									{ title: "sun", key: "sun", items: [] },
									{
										title: "zodiac",
										key: "zodiac",
										items: [],
									},
								],
							},
							{
								title: "weather",
								key: "weather",
								items: [
									{
										title: "all",
										key: "allWeather",
										items: [],
									},
									{
										title: "clouds",
										key: "clouds",
										items: [],
									},
									{ title: "ice", key: "ice", items: [] },
									{
										title: "lightning",
										key: "lightning",
										items: [],
									},
									{ title: "rain", key: "rain", items: [] },
									{
										title: "rainbow",
										key: "rainbow",
										items: [],
									},
									{ title: "snow", key: "snow", items: [] },
									{ title: "storm", key: "storm", items: [] },
									{ title: "sun", key: "sun", items: [] },
									{
										title: "tropical",
										key: "tropical",
										items: [],
									},
									{
										title: "umbrella",
										key: "umbrella",
										items: [],
									},
								],
							},
							{
								title: "birds",
								key: "birds",
								items: [
									{
										title: "all",
										key: "allBirds",
										items: [],
									},
									{
										title: "feathers",
										key: "feathers",
										items: [],
									},
									{
										title: "flamingo",
										key: "flamingo",
										items: [],
									},
									{
										title: "hummingbird",
										key: "hummingbird",
										items: [],
									},
									{ title: "nest", key: "nest", items: [] },
									{
										title: "parrot",
										key: "parrot",
										items: [],
									},
									{ title: "robin", key: "robin", items: [] },
									{
										title: "sparrow",
										key: "sparrow",
										items: [],
									},
									{
										title: "toucan",
										key: "toucan",
										items: [],
									},
								],
							},
							{
								title: "woodland",
								key: "woodlandNature",
								items: [
									{
										title: "all",
										key: "allWoodlandNature",
										items: [],
									},
									{
										title: "antler",
										key: "antler",
										items: [],
									},
									{ title: "bat", key: "bat", items: [] },
									{ title: "bear", key: "bear", items: [] },
									{
										title: "birch",
										key: "birchBark",
										items: [],
									},
									{ title: "deer", key: "deer", items: [] },
									{ title: "fox", key: "fox", items: [] },
									{
										title: "hedgehog",
										key: "hedgehog",
										items: [],
									},
									{ title: "moose", key: "moose", items: [] },
									{
										title: "mushroom",
										key: "mushroom",
										items: [],
									},
									{ title: "owl", key: "owl", items: [] },
									{
										title: "rabbit",
										key: "rabbit",
										items: [],
									},
									{
										title: "squirrel",
										key: "squirrel",
										items: [],
									},
									{ title: "stag", key: "stag", items: [] },
									{ title: "trees", key: "trees", items: [] },
									{ title: "wolf", key: "wolf", items: [] },
								],
							},
							{
								title: "sea",
								key: "sea",
								items: [
									{ title: "all", key: "allSea", items: [] },
									{ title: "coral", key: "coral", items: [] },
									{
										title: "island",
										key: "island",
										items: [],
									},
									{ title: "ocean", key: "ocean", items: [] },
									{ title: "sand", key: "sand", items: [] },
									{
										title: "seaweed",
										key: "seaweed",
										items: [],
									},
									{
										title: "starfish",
										key: "starfish",
										items: [],
									},
									{ title: "surf", key: "surf", items: [] },
									{ title: "waves", key: "waves", items: [] },
								],
							},
							{
								title: "outdoors",
								key: "outdoors",
								items: [
									{
										title: "all",
										key: "allOutdoors",
										items: [],
									},
									{
										title: "campfire",
										key: "campfire",
										items: [],
									},
									{
										title: "desert",
										key: "desert",
										items: [],
									},
									{
										title: "jungle",
										key: "jungle",
										items: [],
									},
									{
										title: "mountain",
										key: "mountain",
										items: [],
									},
									{
										title: "teepee",
										key: "teepee",
										items: [],
									},
									{ title: "trees", key: "trees", items: [] },
									{
										title: "birch",
										key: "birchBark",
										items: [],
									},
									{
										title: "camping",
										key: "camping",
										items: [],
									},
									{
										title: "forest",
										key: "forest",
										items: [],
									},
									{ title: "lake", key: "lake", items: [] },
									{
										title: "smores",
										key: "smores",
										items: [],
									},
									{ title: "tents", key: "tents", items: [] },
								],
							},
						],
					},
					{
						title: "novelty",
						key: "novelty",
						items: [
							{ title: "all", key: "allNovelty", items: [] },
							{
								title: "food",
								key: "foodAndBeverage",
								items: [
									{ title: "all", key: "allFood", items: [] },
									{
										title: "banana",
										key: "banana",
										items: [],
									},
									{ title: "candy", key: "candy", items: [] },
									{
										title: "coffee",
										key: "coffee",
										items: [],
									},
									{
										title: "cookies",
										key: "cookies",
										items: [],
									},
									{
										title: "donuts",
										key: "donuts",
										items: [],
									},
									{ title: "fruit", key: "fruit", items: [] },
									{ title: "ice", key: "ice", items: [] },
									{ title: "lemon", key: "lemon", items: [] },
									{
										title: "pineapple",
										key: "pineapple",
										items: [],
									},
									{ title: "pizza", key: "pizza", items: [] },
									{
										title: "popcorn",
										key: "popcorn",
										items: [],
									},
									{
										title: "popsicle",
										key: "popsicle",
										items: [],
									},
									{
										title: "sprinkles",
										key: "sprinkles",
										items: [],
									},
									{
										title: "strawberry",
										key: "strawberry",
										items: [],
									},
									{ title: "sushi", key: "sushi", items: [] },
									{
										title: "watermelon",
										key: "watermelon",
										items: [],
									},
								],
							},
							{
								title: "music",
								key: "music",
								items: [
									{
										title: "all",
										key: "allMusic",
										items: [],
									},
									{ title: "drums", key: "drums", items: [] },
									{
										title: "guitar",
										key: "guitar",
										items: [],
									},
									{ title: "jazz", key: "jazz", items: [] },
									{
										title: "notes",
										key: "musicNotes",
										items: [],
									},
									{ title: "piano", key: "piano", items: [] },
									{ title: "radio", key: "radio", items: [] },
									{
										title: "rock",
										key: "rockAndRoll",
										items: [],
									},
									{
										title: "symphony",
										key: "symphony",
										items: [],
									},
									{
										title: "trumpets",
										key: "trumpets",
										items: [],
									},
									{
										title: "violin",
										key: "violin",
										items: [],
									},
								],
							},
							{
								title: "academia",
								key: "academia",
								items: [
									{
										title: "all",
										key: "allAcademia",
										items: [],
									},
									{
										title: "atomic",
										key: "atomic",
										items: [],
									},
									{ title: "book", key: "book", items: [] },
									{ title: "geek", key: "geek", items: [] },
									{ title: "math", key: "math", items: [] },
									{
										title: "school",
										key: "school",
										items: [],
									},
									{
										title: "science",
										key: "science",
										items: [],
									},
									{
										title: "teacher",
										key: "teacher",
										items: [],
									},
								],
							},
							{
								title: "sewing",
								key: "sewingNotions",
								items: [
									{
										title: "all",
										key: "allSewingNotions",
										items: [],
									},
									{
										title: "knitting",
										key: "knitting",
										items: [],
									},
									{
										title: "sewing",
										key: "sewingMachines",
										items: [],
									},
									{
										title: "scissors",
										key: "scissors",
										items: [],
									},
									{
										title: "tape",
										key: "tapeMeasure",
										items: [],
									},
									{
										title: "thimbles",
										key: "thimbles",
										items: [],
									},
									{
										title: "thread",
										key: "thread",
										items: [],
									},
								],
							},
							{
								title: "sports",
								key: "sports",
								items: [
									{
										title: "all",
										key: "allSports",
										items: [],
									},
									{
										title: "ballet",
										key: "ballet",
										items: [],
									},
									{
										title: "baseball",
										key: "baseball",
										items: [],
									},
									{
										title: "basketball",
										key: "basketball",
										items: [],
									},
									{
										title: "bicycle",
										key: "bicycle",
										items: [],
									},
									{
										title: "equestrian",
										key: "equestrian",
										items: [],
									},
									{
										title: "fishing",
										key: "fishing",
										items: [],
									},
									{
										title: "football",
										key: "football",
										items: [],
									},
									{ title: "golf", key: "golf", items: [] },
									{
										title: "gymnastics",
										key: "gymnastics",
										items: [],
									},
									{
										title: "soccer",
										key: "soccer",
										items: [],
									},
									{
										title: "surfing",
										key: "surfing",
										items: [],
									},
									{
										title: "tennis",
										key: "tennis",
										items: [],
									},
								],
							},
							{
								title: "scary",
								key: "scary",
								items: [
									{
										title: "all",
										key: "allScary",
										items: [],
									},
									{ title: "bat", key: "bat", items: [] },
									{ title: "ghost", key: "ghost", items: [] },
									{
										title: "gothic",
										key: "gothic",
										items: [],
									},
									{
										title: "monster",
										key: "monster",
										items: [],
									},
									{ title: "skull", key: "skull", items: [] },
									{
										title: "steampunk",
										key: "steampunk",
										items: [],
									},
									{ title: "wolf", key: "wolf", items: [] },
									{
										title: "zombie",
										key: "zombie",
										items: [],
									},
								],
							},
							{
								title: "transportation",
								key: "transportation",
								items: [
									{
										title: "all",
										key: "allTransportation",
										items: [],
									},
									{
										title: "airplanes",
										key: "airplanes",
										items: [],
									},
									{
										title: "bicycles",
										key: "bicycles",
										items: [],
									},
									{ title: "boats", key: "boats", items: [] },
									{ title: "cars", key: "cars", items: [] },
									{
										title: "construction",
										key: "construction",
										items: [],
									},
									{
										title: "helicopters",
										key: "helicopters",
										items: [],
									},
									{
										title: "balloon",
										key: "hotAirBalloon",
										items: [],
									},
									{
										title: "motorcycles",
										key: "motorcycles",
										items: [],
									},
									{ title: "retro", key: "retro", items: [] },
									{
										title: "rocket",
										key: "rocketShip",
										items: [],
									},
									{ title: "space", key: "space", items: [] },
									{ title: "ships", key: "ships", items: [] },
									{
										title: "tractors",
										key: "tractors",
										items: [],
									},
									{
										title: "trucks",
										key: "trucks",
										items: [],
									},
								],
							},
						],
					},
					{
						title: "occasion",
						key: "occasion",
						items: [
							{
								title: "baby",
								key: "babyAndKids",
								items: [
									{
										title: "all",
										key: "allChildren",
										items: [],
									},
									{
										title: "alphabet",
										key: "alphabet",
										items: [],
									},
									{ title: "boy", key: "babyBoy", items: [] },
									{
										title: "girl",
										key: "babyGirl",
										items: [],
									},
									{
										title: "ballerina",
										key: "ballerina",
										items: [],
									},
									{
										title: "butterfly",
										key: "butterfly",
										items: [],
									},
									{ title: "cars", key: "cars", items: [] },
									{
										title: "circus",
										key: "circus",
										items: [],
									},
									{
										title: "dinosaur",
										key: "dinosaur",
										items: [],
									},
									{ title: "doll", key: "doll", items: [] },
									{
										title: "dragon",
										key: "dragon",
										items: [],
									},
									{ title: "fairy", key: "fairy", items: [] },
									{
										title: "nursery",
										key: "nursery",
										items: [],
									},
									{
										title: "pirate",
										key: "pirate",
										items: [],
									},
									{
										title: "princess",
										key: "princess",
										items: [],
									},
									{ title: "robot", key: "robot", items: [] },
									{
										title: "tractors",
										key: "tractors",
										items: [],
									},
									{
										title: "trucks",
										key: "trucks",
										items: [],
									},
									{
										title: "unicorn",
										key: "unicorn",
										items: [],
									},
								],
							},
							{
								title: "birthday",
								key: "birthday",
								items: [
									{
										title: "all",
										key: "allBirthday",
										items: [],
									},
									{
										title: "balloons",
										key: "balloons",
										items: [],
									},
									{ title: "cake", key: "cake", items: [] },
									{
										title: "confetti",
										key: "confetti",
										items: [],
									},
									{
										title: "cupcake",
										key: "cupcake",
										items: [],
									},
									{ title: "gifts", key: "gifts", items: [] },
									{
										title: "presents",
										key: "presents",
										items: [],
									},
									{
										title: "picnic",
										key: "picnic",
										items: [],
									},
									{
										title: "wedding",
										key: "wedding",
										items: [],
									},
									{
										title: "graduation",
										key: "graduation",
										items: [],
									},
								],
							},
							{ title: "picnic", key: "picnic", items: [] },
							{ title: "wedding", key: "wedding", items: [] },
							{
								title: "graduation",
								key: "graduation",
								items: [],
							},
						],
					},
					{
						title: "seasonal",
						key: "seasonal",
						items: [
							{ title: "all", key: "allSeasonal", items: [] },
							{
								title: "winter",
								key: "winter",
								items: [
									{
										title: "all",
										key: "allWinter",
										items: [],
									},
									{
										title: "antler",
										key: "antler",
										items: [],
									},
									{
										title: "chanukah",
										key: "chanukah",
										items: [],
									},
									{
										title: "christmas",
										key: "christmas",
										items: [],
									},
									{
										title: "holiday",
										key: "holiday",
										items: [],
									},
									{ title: "holly", key: "holly", items: [] },
									{ title: "ice", key: "ice", items: [] },
									{
										title: "penguins",
										key: "penguins",
										items: [],
									},
									{
										title: "presents",
										key: "presents",
										items: [],
									},
									{ title: "bears", key: "bears", items: [] },
									{ title: "santa", key: "santa", items: [] },
									{
										title: "skiing",
										key: "skiing",
										items: [],
									},
									{ title: "snow", key: "snow", items: [] },
									{
										title: "stockings",
										key: "stockings",
										items: [],
									},
								],
							},
							{
								title: "spring",
								key: "spring",
								items: [
									{
										title: "all",
										key: "allSpring",
										items: [],
									},
									{ title: "birds", key: "birds", items: [] },
									{ title: "bunny", key: "bunny", items: [] },
									{
										title: "crocus",
										key: "crocus",
										items: [],
									},
									{
										title: "daffodil",
										key: "daffodil",
										items: [],
									},
									{
										title: "floral",
										key: "floral",
										items: [],
									},
									{
										title: "flowers",
										key: "flowers",
										items: [],
									},
									{ title: "rain", key: "rain", items: [] },
									{
										title: "tulips",
										key: "tulips",
										items: [],
									},
									{
										title: "umbrella",
										key: "umbrella",
										items: [],
									},
								],
							},
							{
								title: "summer",
								key: "summer",
								items: [
									{
										title: "all",
										key: "allSummer",
										items: [],
									},
									{ title: "beach", key: "beach", items: [] },
									{
										title: "camping",
										key: "camping",
										items: [],
									},
									{
										title: "floral",
										key: "floral",
										items: [],
									},
									{
										title: "july",
										key: "july4th",
										items: [],
									},
									{ title: "ice", key: "ice", items: [] },
									{
										title: "lemonade",
										key: "lemonade",
										items: [],
									},
									{
										title: "mermaid",
										key: "mermaid",
										items: [],
									},
									{
										title: "popsicle",
										key: "popsicle",
										items: [],
									},
									{ title: "tiki", key: "tiki", items: [] },
									{
										title: "tropical",
										key: "tropical",
										items: [],
									},
								],
							},
							{
								title: "fall",
								key: "fall",
								items: [
									{ title: "all", key: "allFall", items: [] },
									{ title: "apple", key: "apple", items: [] },
									{
										title: "autumn",
										key: "autumn",
										items: [],
									},
									{
										title: "backpack",
										key: "backpack",
										items: [],
									},
									{
										title: "camping",
										key: "camping",
										items: [],
									},
									{ title: "cider", key: "cider", items: [] },
									{
										title: "halloween",
										key: "halloween",
										items: [],
									},
									{
										title: "leaves",
										key: "leaves",
										items: [],
									},
									{
										title: "pumpkins",
										key: "pumpkins",
										items: [],
									},
									{
										title: "school",
										key: "school",
										items: [],
									},
									{ title: "skull", key: "skull", items: [] },
									{
										title: "teacher",
										key: "teacher",
										items: [],
									},
									{ title: "trees", key: "trees", items: [] },
									{
										title: "woodland",
										key: "woodlandFall",
										items: [],
									},
								],
							},
							{
								title: "holidays",
								key: "holidays",
								items: [
									{
										title: "all",
										key: "allHolidays",
										items: [],
									},
									{
										title: "hearts",
										key: "hearts",
										items: [],
									},
									{
										title: "menorah",
										key: "menorah",
										items: [],
									},
									{
										title: "pumpkins",
										key: "pumpkins",
										items: [],
									},
									{ title: "santa", key: "santa", items: [] },
									{ title: "skull", key: "skull", items: [] },
									{
										title: "spider",
										key: "spider",
										items: [],
									},
									{ title: "stars", key: "stars", items: [] },
									{
										title: "supernatural",
										key: "supernatural",
										items: [],
									},
									{
										title: "witches",
										key: "witches",
										items: [],
									},
									{
										title: "zombies",
										key: "zombies",
										items: [],
									},
								],
							},
						],
					},
					{
						title: "holidays",
						key: "holidays",
						items: [
							{ title: "all", key: "allHolidays", items: [] },
							{
								title: "christmas",
								key: "christmas",
								items: [
									{
										title: "all",
										key: "allHolidays",
										items: [],
									},
									{
										title: "christmas",
										key: "christmas",
										items: [],
									},
									{ title: "holly", key: "holly", items: [] },
									{
										title: "pinecones",
										key: "pinecones",
										items: [],
									},
									{
										title: "reindeer",
										key: "reindeer",
										items: [],
									},
									{ title: "retro", key: "retro", items: [] },
									{ title: "santa", key: "santa", items: [] },
									{ title: "sled", key: "sled", items: [] },
									{
										title: "sleigh",
										key: "sleigh",
										items: [],
									},
									{ title: "snow", key: "snow", items: [] },
									{
										title: "snowmen",
										key: "snowmen",
										items: [],
									},
									{
										title: "stocking",
										key: "stocking",
										items: [],
									},
								],
							},
							{
								title: "july",
								key: "july4th",
								items: [
									{
										title: "all",
										key: "allJuly4th",
										items: [],
									},
									{
										title: "flag",
										key: "americanFlag",
										items: [],
									},
									{
										title: "cookout",
										key: "cookout",
										items: [],
									},
									{
										title: "fireworks",
										key: "fireworks",
										items: [],
									},
									{
										title: "hamburgers",
										key: "hamburgers",
										items: [],
									},
									{
										title: "hotdogs",
										key: "hotdogs",
										items: [],
									},
									{
										title: "independence",
										key: "independenceDay",
										items: [],
									},
									{
										title: "patriotic",
										key: "patriotic",
										items: [],
									},
									{
										title: "picnic",
										key: "picnic",
										items: [],
									},
									{
										title: "rwb",
										key: "redWhiteAndBlue",
										items: [],
									},
									{ title: "stars", key: "stars", items: [] },
									{
										title: "stripes",
										key: "stripes",
										items: [],
									},
								],
							},
							{
								title: "valentine",
								key: "valentinesDay",
								items: [
									{
										title: "all",
										key: "allValentinesDay",
										items: [],
									},
									{
										title: "arrows",
										key: "arrows",
										items: [],
									},
									{ title: "candy", key: "candy", items: [] },
									{ title: "cupid", key: "cupid", items: [] },
									{
										title: "hearts",
										key: "hearts",
										items: [],
									},
									{ title: "love", key: "love", items: [] },
								],
							},
							{
								title: "halloween",
								key: "halloween",
								items: [
									{
										title: "all",
										key: "allHalloween",
										items: [],
									},
									{ title: "bat", key: "bat", items: [] },
									{
										title: "cats",
										key: "blackCats",
										items: [],
									},
									{ title: "candy", key: "candy", items: [] },
									{ title: "ghost", key: "ghost", items: [] },
									{
										title: "jack",
										key: "jack-o-lantern",
										items: [],
									},
									{
										title: "pumpkin",
										key: "pumpkin",
										items: [],
									},
									{
										title: "scarecrow",
										key: "scarecrow",
										items: [],
									},
									{ title: "skull", key: "skull", items: [] },
									{
										title: "spider",
										key: "spider",
										items: [],
									},
									{
										title: "sugar",
										key: "sugarSkulls",
										items: [],
									},
									{
										title: "vampire",
										key: "vampire",
										items: [],
									},
									{ title: "witch", key: "witch", items: [] },
									{
										title: "zombie",
										key: "zombie",
										items: [],
									},
								],
							},
						],
					},
					{
						title: "projects",
						key: "projects",
						items: [
							{ title: "all", key: "allProjects", items: [] },
							{ title: "bags", key: "bags", items: [] },
							{ title: "border", key: "border", items: [] },
							{ title: "bunting", key: "bunting", items: [] },
							{
								title: "calendar",
								key: "calendarTeaTowels",
								items: [],
							},
							{ title: "quilt", key: "cheaterQuilts", items: [] },
							{ title: "cut", key: "cutAndSew", items: [] },
							{ title: "pencil", key: "pencilPouch", items: [] },
							{ title: "plushies", key: "plushies", items: [] },
							{ title: "labels", key: "quiltLabels", items: [] },
							{ title: "stockings", key: "stockings", items: [] },
							{ title: "tea", key: "teaTowels", items: [] },
							{ title: "zipper", key: "zipperPouch", items: [] },
						],
					},
				],
			},
			{
				title: "color",
				key: "byColors",
				items: [
					{
						title: "white",
						key: "white",
						items: [
							{ title: "white", key: "white", items: [] },
							{ title: "beige", key: "beige", items: [] },
							{ title: "cornsilk", key: "cornsilk", items: [] },
							{ title: "ecru", key: "ecru", items: [] },
							{ title: "eggshell", key: "eggshell", items: [] },
							{ title: "flax", key: "flax", items: [] },
							{ title: "ivory", key: "ivory", items: [] },
							{ title: "linen", key: "linen", items: [] },
							{ title: "vanilla", key: "vanilla", items: [] },
							{ title: "smoke", key: "whiteSmoke", items: [] },
						],
					},
					{
						title: "grey",
						key: "grey",
						items: [
							{ title: "gray", key: "gray", items: [] },
							{ title: "grey", key: "grey", items: [] },
							{ title: "ash", key: "ashGray", items: [] },
							{
								title: "battleship",
								key: "battleshipGray",
								items: [],
							},
							{ title: "blueGray", key: "blueGray", items: [] },
							{ title: "charcoal", key: "charcoal", items: [] },
							{ title: "gunmetal", key: "gunmetal", items: [] },
							{ title: "platinum", key: "platinum", items: [] },
							{ title: "silver", key: "silver", items: [] },
							{ title: "slate", key: "slate", items: [] },
							{ title: "taupe", key: "taupe", items: [] },
						],
					},
					{
						title: "pink",
						key: "pink",
						items: [
							{ title: "pink", key: "pink", items: [] },
							{ title: "carnation", key: "carnation", items: [] },
							{ title: "cerise", key: "cerise", items: [] },
							{ title: "coral", key: "coral", items: [] },
							{ title: "cyclamen", key: "cyclamen", items: [] },
							{ title: "fandango", key: "fandango", items: [] },
							{ title: "fuschsia", key: "fuschsia", items: [] },
							{ title: "hot", key: "hotPink", items: [] },
							{ title: "magenta", key: "magenta", items: [] },
							{ title: "mauve", key: "mauve", items: [] },
							{ title: "puce", key: "puce", items: [] },
							{ title: "raspberry", key: "raspberry", items: [] },
							{ title: "rose", key: "rose", items: [] },
							{ title: "quartz", key: "roseQuartz", items: [] },
							{ title: "ruby", key: "ruby", items: [] },
							{ title: "salmon", key: "salmon", items: [] },
							{
								title: "shocking",
								key: "shockingPink",
								items: [],
							},
						],
					},
					{
						title: "red",
						key: "red",
						items: [
							{ title: "red", key: "red", items: [] },
							{ title: "auburn", key: "auburn", items: [] },
							{ title: "burgundy", key: "burgundy", items: [] },
							{ title: "cardinal", key: "cardinal", items: [] },
							{ title: "claret", key: "claret", items: [] },
							{ title: "crimson", key: "crimson", items: [] },
							{ title: "mahogany", key: "mahogany", items: [] },
							{ title: "maroon", key: "maroon", items: [] },
							{ title: "marsala", key: "marsala", items: [] },
							{ title: "oxblood", key: "oxblood", items: [] },
							{ title: "raspberry", key: "raspberry", items: [] },
							{ title: "redwood", key: "redwood", items: [] },
							{ title: "rosewood", key: "rosewood", items: [] },
							{ title: "ruby", key: "ruby", items: [] },
							{ title: "rust", key: "rust", items: [] },
							{ title: "scarlet", key: "scarlet", items: [] },
							{ title: "terra", key: "terraCotta", items: [] },
							{ title: "vermilion", key: "vermilion", items: [] },
						],
					},
					{
						title: "brown",
						key: "brown",
						items: [
							{ title: "brown", key: "brown", items: [] },
							{ title: "beige", key: "beige", items: [] },
							{ title: "bronze", key: "bronze", items: [] },
							{ title: "buff", key: "buff", items: [] },
							{ title: "umber", key: "burntUmber", items: [] },
							{ title: "copper", key: "copper", items: [] },
							{ title: "desert", key: "desert", items: [] },
							{ title: "ecru", key: "ecru", items: [] },
							{ title: "khaki", key: "khaki", items: [] },
							{ title: "mahogany", key: "mahogany", items: [] },
							{ title: "ochre", key: "ochre", items: [] },
							{ title: "russet", key: "russet", items: [] },
							{ title: "rust", key: "rust", items: [] },
							{ title: "sand", key: "sand", items: [] },
							{ title: "sepia", key: "sepia", items: [] },
							{ title: "sienna", key: "sienna", items: [] },
							{ title: "tan", key: "tan", items: [] },
							{ title: "taupe", key: "taupe", items: [] },
							{ title: "wheat", key: "wheat", items: [] },
						],
					},
					{
						title: "orange",
						key: "orange",
						items: [
							{ title: "orange", key: "orange", items: [] },
							{ title: "amber", key: "amber", items: [] },
							{ title: "apricot", key: "apricot", items: [] },
							{ title: "coral", key: "coral", items: [] },
							{ title: "marigold", key: "marigold", items: [] },
							{ title: "red", key: "red", items: [] },
							{ title: "peach", key: "peach", items: [] },
							{ title: "salmon", key: "salmon", items: [] },
							{ title: "tangerine", key: "tangerine", items: [] },
						],
					},
					{
						title: "yellow",
						key: "yellow",
						items: [
							{ title: "yellow", key: "yellow", items: [] },
							{ title: "citrine", key: "citrine", items: [] },
							{ title: "gold", key: "gold", items: [] },
							{ title: "goldenrod", key: "goldenrod", items: [] },
							{ title: "honey", key: "honey", items: [] },
							{ title: "jonquil", key: "jonquil", items: [] },
							{ title: "mustard", key: "mustard", items: [] },
							{ title: "saffron", key: "saffron", items: [] },
						],
					},
					{
						title: "green",
						key: "green",
						items: [
							{ title: "green", key: "green", items: [] },
							{ title: "artichoke", key: "artichoke", items: [] },
							{ title: "celadon", key: "celadon", items: [] },
							{
								title: "chartreuse",
								key: "chartreuse",
								items: [],
							},
							{ title: "emerald", key: "emerald", items: [] },
							{ title: "jade", key: "jade", items: [] },
							{ title: "kelly", key: "kelly", items: [] },
							{ title: "mint", key: "mint", items: [] },
						],
					},
					{
						title: "cyan",
						key: "cyan",
						items: [
							{ title: "cyan", key: "cyan", items: [] },
							{ title: "aqua", key: "aqua", items: [] },
							{
								title: "aquamarine",
								key: "aquamarine",
								items: [],
							},
							{ title: "cerulean", key: "cerulean", items: [] },
							{ title: "teal", key: "teal", items: [] },
							{ title: "turquoise", key: "turquoise", items: [] },
							{ title: "verdigris", key: "verdigris", items: [] },
						],
					},
					{
						title: "blue",
						key: "blue",
						items: [
							{ title: "blue", key: "blue", items: [] },
							{ title: "azure", key: "azure", items: [] },
							{ title: "baby", key: "babyBoy", items: [] },
							{ title: "cobalt", key: "cobalt", items: [] },
							{
								title: "cornflower",
								key: "cornflower",
								items: [],
							},
							{ title: "denim", key: "denim", items: [] },
							{ title: "indigo", key: "indigo", items: [] },
							{ title: "midnight", key: "midnight", items: [] },
							{ title: "navy", key: "navy", items: [] },
							{
								title: "periwinkle",
								key: "periwinkle",
								items: [],
							},
							{ title: "sapphire", key: "sapphire", items: [] },
							{ title: "serenity", key: "serenity", items: [] },
							{
								title: "ultramarine",
								key: "ultramarine",
								items: [],
							},
						],
					},
					{
						title: "violet",
						key: "violet",
						items: [
							{ title: "violet", key: "violet", items: [] },
							{ title: "amethyst", key: "amethyst", items: [] },
							{ title: "aubergine", key: "aubergine", items: [] },
							{ title: "cerise", key: "cerise", items: [] },
							{ title: "eggplant", key: "eggplant", items: [] },
							{ title: "fuchsia", key: "fuchsia", items: [] },
							{ title: "indigo", key: "indigo", items: [] },
							{ title: "lavender", key: "lavender", items: [] },
							{ title: "lilac", key: "lilac", items: [] },
							{ title: "magenta", key: "magenta", items: [] },
							{ title: "orchid", key: "orchid", items: [] },
							{
								title: "periwinkle",
								key: "periwinkle",
								items: [],
							},
							{ title: "plum", key: "plum", items: [] },
							{ title: "purple", key: "purple", items: [] },
							{
								title: "radiant",
								key: "radiantOrchid",
								items: [],
							},
						],
					},
					{
						title: "black",
						key: "black",
						items: [
							{ title: "black", key: "black", items: [] },
							{ title: "charcoal", key: "charcoal", items: [] },
							{ title: "ebony", key: "ebony", items: [] },
							{ title: "midnight", key: "midnight", items: [] },
							{ title: "noir", key: "noir", items: [] },
							{ title: "onyx", key: "onyx", items: [] },
						],
					},
				],
			},
			{ title: "theme", key: "curatedThemes", items: [] },
			{ title: "giftIdeas", key: "giftIdeas", items: [] },
			{ title: "blogMenu", key: "blog", items: [] },
		],
	},
	{
		title: "fabric-menu",
		key: "fabric-menu",
		items: [
			{ title: "allFabricDesigns", key: "allFabric", items: [] },
			{ title: "shopFabricDesigns", key: "shopFabricDesigns", items: [] },
			{ title: "fabricTypes", key: "fabricTypes", items: [] },
			{ title: "samplePack", key: "samplePack", items: [] },
			{ title: "fillAYard", key: "fillAYard", items: [] },
		],
	},
	{
		title: "wallpaper-menu",
		key: "wallpaper-menu",
		items: [
			{ title: "all", key: "allWallpaper", items: [] },
			{
				title: "shopWallpaperDesigns",
				key: "shopWallpaperDesigns",
				items: [],
			},
			{ title: "stick", key: "peelAndStick", items: [] },
			{ title: "remove", key: "prepastedWallpaper", items: [] },
			{
				title: "traditional",
				key: "nonPastedTraditionalPebble",
				items: [],
			},
			{ title: "grasscloth", key: "grasscloth", items: [] },
			{ title: "metallic", key: "metallic", items: [] },
		],
	},
	{
		title: "living-menu",
		key: "livingDecor-menu",
		items: [
			{ title: "all", key: "allLiving", items: [] },
			{ title: "square", key: "squareThrowPillows", items: [] },
			{ title: "lumbar", key: "lumbarThrowPillows", items: [] },
			{ title: "blankets", key: "throwBlankets", items: [] },
			{ title: "curtains", key: "curtains", items: [] },
			{ title: "wall", key: "wallHanging", items: [] },
		],
	},
	{
		title: "dining-menu",
		key: "dining-menu",
		items: [
			{ title: "all", key: "allDining", items: [] },
			{ title: "cocktail", key: "cocktailNapkins", items: [] },
			{ title: "dinner", key: "dinnerNapkins", items: [] },
			{ title: "placemats", key: "placemats", items: [] },
			{ title: "tea", key: "teaTowelsLink", items: [] },
			{ title: "rect", key: "rectangularTablecloths", items: [] },
			{ title: "round", key: "roundTablecloths", items: [] },
			{ title: "table", key: "tableRunners", items: [] },
		],
	},
	{
		title: "bedding-menu",
		key: "bedding-menu",
		items: [
			{ title: "all", key: "allBedding", items: [] },
			{ title: "duvet", key: "duvetCovers", items: [] },
			{ title: "sheet", key: "sheetSets", items: [] },
			{ title: "standard", key: "standardPillowShams", items: [] },
			{ title: "euro", key: "euroPillowShams", items: [] },
			{ title: "extra", key: "longLumbarThrowPillow", items: [] },
		],
	},
	{
		title: "trade-menu",
		key: "trade-menu",
		items: [{ title: "trade", key: "tradeProgram", items: [] }],
	},
	{
		title: "artist-menu",
		key: "artist-menu",
		items: [
			{ title: "upload", key: "uploadYourDesign", items: [] },
			{ title: "challenges", key: "designChallenge", items: [] },
			{ title: "commissions", key: "sellingAndCommissions", items: [] },
			{ title: "agreement", key: "sellerAgreement", items: [] },
			{ title: "designing", key: "designFAQs", items: [] },
			{ title: "selling", key: "sellingFAQs", items: [] },
			{ title: "artist", key: "artist", items: [] },
		],
	},
	{
		title: "about-menu",
		key: "about-menu",
		items: [
			{ title: "aboutUs", key: "aboutUs", items: [] },
			{ title: "how", key: "howItWorks", items: [] },
			{ title: "purpose", key: "purposeImpact", items: [] },
		],
	},
	{
		title: "account",
		key: "account",
		items: [
			{
				title: "my dashboard",
				key: "myDashboard",
				href: () => tr.encodeUrl(studioRoute)({}),
				items: [],
			},
			{
				title: "orders",
				key: "orders",
				href: (userId: string) =>
					tr.encodeUrl(accountRoute)({
						route: { userId },
						query: { sub_action: "orders" },
					}),
				items: [],
			},
			{
				title: "account settings",
				key: "myAccount",
				href: (userId: string) =>
					tr.encodeUrl(accountRoute)({
						route: { userId },
						query: { sub_action: "settings" },
					}),
				items: [],
			},
			{
				title: "favorites",
				key: "favorites",
				href: (username: string) =>
					tr.encodeUrl(favoritesRoute)({ route: { username } }),
				items: [],
			},
			{
				title: "collections",
				key: "collections",
				href: (username: string) =>
					tr.encodeUrl(collectionsRoute)({
						route: { username },
						query: { filter: "designed" },
					}),
				items: [],
			},
			{
				title: "design library",
				key: "designLibrary",
				href: () => tr.encodeUrl(designsRoute)({}),
				items: [],
			},
			{
				title: "view my shop",
				key: "myShop",
				href: (username: string) =>
					tr.encodeUrl(shopRoute)({ route: { username } }),
				items: [],
			},
			{
				title: "notifications",
				key: "notifications",
				href: (userId: string) =>
					tr.encodeUrl(notificationsRoute)({ route: { userId } }),
				items: [],
			},
			{
				title: "messages",
				key: "messages",
				href: () => tr.encodeUrl(messagesRoute)({}),
				items: [],
			},
			{
				title: "my design challenges",
				key: "myDesignChallenges",
				href: () => tr.encodeUrl(myDesignChallengeRoute)({}),
				items: [],
			},
			{
				title: "logout",
				key: "logOut",
				href: () => tr.encodeUrl(logoutRoute)({}),
				items: [],
			},
		],
	},
] as const

type NavItemsKeysValues<T extends NavItems, Keys = never> = T extends readonly [
	infer H,
	...infer T extends NavItems,
]
	? H extends {
			items: infer L extends NavItems
			key: infer K
		}
		? NavItemsKeysValues<[...T, ...L], K | Keys>
		: never
	: Keys

export type NavItemsKeys = NavItemsKeysValues<typeof navItems>

interface NavItemMeta {
	title: string
	items: Array<NavItemMeta>
	depth: number
	key: NavItemsKeys
	parents: ReadonlyArray<string>
	href?: (() => string) | ((value: string) => string)
}

type NavItemsMeta = Array<NavItemMeta>

const recurseNavItems = <NI extends Array<NavItem>>(
	navListItems: NI,
	depth = 0,
	parents: ReadonlyArray<string> = ["root"],
): NavItemsMeta =>
	pipe(
		navListItems,
		A.map<NavItem, NavItemMeta>(i =>
			i.items.length > 0
				? ({
						...i,
						items: recurseNavItems(RA.toArray(i.items), depth + 1, [
							...parents,
							i.key,
						]),
						depth,
						parents,
					} as NavItemMeta)
				: ({ ...i, depth, parents } as NavItemMeta),
		),
	)

export interface TreeItemMeta {
	title: string
	key: NavItemsKeys
	depth: number
	parents: ReadonlyArray<string>
	href?: (() => string) | ((value: string) => string)
}

export const navItemsMetaForest: Tree.Forest<TreeItemMeta> = Tree.unfoldForest(
	recurseNavItems(RA.toArray(navItems)),
	t => {
		const { items, ...rest } = t
		return [rest, items]
	},
)
