import { useQuery, type WatchQueryFetchPolicy } from "@apollo/client"
import { MeDocument } from "@artists/gql/design-challenges"

import type { Auth } from "./auth.types"

export const useAuth = (
	fetchPolicy: WatchQueryFetchPolicy = "cache-first",
): Auth => {
	const { data, loading } = useQuery(MeDocument, {
		variables: { settings: false },
		fetchPolicy,
	})
	const user = data?.me?.user ?? null

	if (user) {
		return { loading, user, authorized: true, isDataReceived: true }
	}

	return { loading, user, authorized: false, isDataReceived: !!data }
}
