import { useEffect } from "react"
import { matchRoutes, useLocation } from "react-router-dom"

import { Root } from "../../routes"
import { useAnalytics } from "./analytics.hook"

const pages = {
	[Root.Home.path]: "home",
	[Root.Past.path]: "past",
	[Root.Vote.path]: "vote",
	[Root.Challenge.path]: "challenge",
	[Root.Submission.path]: "submission",
	[Root.ReviewVotes.path]: "review-vote",
	[Root.Me.Entries.path]: "my-entries",
	[Root.Me.Voting.path]: "my-voting",
	"*": "not-found",
} as const

const routes = Object.keys(pages).map(path => ({ path }))

export const usePageTrack = () => {
	const { send } = useAnalytics()
	const location = useLocation()
	const match = matchRoutes(routes, location)

	useEffect(() => {
		if (match?.[0]?.route.path) {
			const [{ route }] = match
			const path = route.path as keyof typeof pages
			const pageType = `@artists/design-challenges/${pages[path]}`

			send({ event: "virtual_page_view", pageType })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])
}
