import { Announcements } from "@artists/design-schemas"
import { deriveTranscoderPar, pipe, S, TE, tr } from "@artists/sf-fp"

import { asJson } from "./utils/as-json"

/**
 * Request: https://api-gateway.spoonflower.com/alpenrose/announcements
 *
 * Example Response: { "data": [ { "id": 670, "url":
 * "https://www.spoonflower.com/en/shop?on=wallpaper", "title": "30% Off
 * Wallpaper", "message": "Shop now and save 30% on wallpaper through 11:59 p.m.
 * ET on February 15, 2023.", "category": "GENERAL", "sitewide": false,
 * "language": "en" } ] }
 */
const AnnouncementsResponseS = S.Struct({
	data: Announcements.AnnouncementsSchema,
})

export type AnnouncementsResponse = S.TypeOf<typeof AnnouncementsResponseS>

export const announcementsResponseDecoder = deriveTranscoderPar(
	AnnouncementsResponseS,
)

// https://api-gateway.spoonflower.com/alpenrose/announcements
export const announcementsPath = pipe(
	tr.RootPath,
	tr.path("alpenrose"),
	tr.path("announcements"),
)

export const fetchAnnouncements = (basePath: string) =>
	pipe(
		TE.tryCatch(
			() =>
				fetch(`${basePath}${tr.encodeUrl(announcementsPath)({})}`, {
					credentials: "include",
				}),
			e => `Failed to fetch user announcements: ${e}`,
		),
		TE.chain(asJson("announcements")),
		TE.chainW(announcementsResponseDecoder.decode),
	)
