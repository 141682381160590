import type { Theme } from "@artists/chakra-ui-theme"
import { pipe, RA } from "@artists/sf-fp"
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	Link,
	List,
	ListItem,
	Text,
	useTheme,
} from "@chakra-ui/react"
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded"
import ChatBubbleRounded from "@mui/icons-material/ChatBubbleOutlineRounded"
import EmailRounded from "@mui/icons-material/EmailRounded"
import HelpRounded from "@mui/icons-material/HelpRounded"
import * as tr from "@simspace/trout/index"
import { useTranslation } from "react-i18next"

import { UserDataInfo } from "./components/user-data-info"
import { UserPreferencesButton } from "./components/user-preferences-button"
import type { FooterProps } from "./domain/footer"
import { handleCookieDrawer as defaultHandleCookieDrawer } from "./utils/handle-cookie-drawer"
import { handleOpenChat as defaultHandleOpenChat } from "./utils/handle-open-chat"
import {
	aboutPath,
	accessibilityPath,
	catalogPath,
	helpPath,
	jobsBasePath,
	jobsPath,
	plusPath,
	privacyPath,
	submitMessagePath,
	subscribePath,
	termsPath,
} from "./utils/paths"
import { socialMediaIcons } from "./utils/social-media"

export const FooterUI = ({
	handleManageCookiesDrawer = defaultHandleCookieDrawer,
	handleOpenChat = defaultHandleOpenChat,
	userId,
	alpenroseServiceUrl,
}: FooterProps) => {
	const theme: Theme = useTheme()
	const { t } = useTranslation("footer")

	return (
		<Box bgColor='gray.100' as='footer'>
			<Box
				maxW='1728px'
				margin='0 auto'
				px={{ xs: 1, sm: 6, md: 8, lg: 8, xl: 10 }}
			>
				<Grid
					templateColumns={{
						base: "repeat(1, minmax(0, 1fr))",
						md: "58% 42%",
					}}
				>
					<GridItem
						display='flex'
						flexDirection='column'
						borderX={`1px solid ${theme.colors.gray[200]}`}
						borderBottom={`1px solid ${theme.colors.gray[200]}`}
					>
						<Heading
							as='h2'
							size='md'
							borderBottom={`1px solid ${theme.colors.gray[200]}`}
							textAlign='center'
							p={6}
						>
							{t("helpHeadline")}
						</Heading>
						<List
							display='flex'
							flexDir={{ base: "column", lg: "row" }}
							alignItems='stretch'
							height='100%'
							fontSize={{ base: "sm" }}
							sx={{
								[`@media (min-width: ${theme.breakpoints.base})`]:
									{
										"& > li": {
											borderBottom: `1px solid ${theme.colors.gray[200]}`,
											"&:last-child": {
												border: "none",
											},
										},
									},
								[`@media (min-width: ${theme.breakpoints.lg})`]:
									{
										"& > li": {
											borderBottom: 0,
											borderRight: `1px solid ${theme.colors.gray[200]}`,
											"&:last-child": {
												border: "none",
											},
										},
									},
							}}
						>
							<ListItem
								key='help'
								p={{ base: 7, md: 0 }}
								flex='1 1 0px'
							>
								<Link
									variant='footer'
									href={helpPath}
									data-testid='link-help-center'
									display='flex'
									justifyContent='center'
									alignItems='center'
									height='100%'
									_hover={{
										"& div svg": {
											color: theme.colors.teal[600],
										},
										textDecoration: "underline",
									}}
								>
									<Flex
										as='span'
										direction='column'
										alignItems='center'
										gap={4}
									>
										<HelpRounded />
										<Box as='span'>
											{t("navigation.helpCenter")}
										</Box>
									</Flex>
								</Link>
							</ListItem>
							<ListItem
								key='chat'
								p={{ base: 7, md: 0 }}
								flex='1 1 0px'
							>
								<Button
									id='zendesk-live-chat'
									variant='unstyled'
									data-testid='button-chat'
									onClick={handleOpenChat}
									display='flex'
									fontWeight='normal'
									fontSize='sm'
									width='100%'
									justifyContent='center'
									alignItems='center'
									height='100%'
									py={{ base: 0 }}
									_hover={{
										"& div svg": {
											color: theme.colors.teal[600],
										},
										textDecoration: "underline",
									}}
								>
									<Flex
										as='span'
										direction='column'
										alignItems='center'
										gap={4}
									>
										<ChatBubbleRounded />
										<Box as='span'>Chat</Box>
									</Flex>
								</Button>
							</ListItem>
							<ListItem
								key='submit-message'
								p={{ base: 7, md: 0 }}
								flex='1 1 0px'
							>
								<Link
									variant='footer'
									href={submitMessagePath}
									data-testid='link-submit-message'
									display='flex'
									justifyContent='center'
									alignItems='center'
									height='100%'
									_hover={{
										"& div svg": {
											color: theme.colors.teal[600],
										},
										textDecoration: "underline",
									}}
								>
									<Flex
										as='span'
										direction='column'
										alignItems='center'
										gap={4}
									>
										<EmailRounded />
										<Box as='span'>
											{t("navigation.submitMessage")}
										</Box>
									</Flex>
								</Link>
							</ListItem>
						</List>
					</GridItem>
					<GridItem
						borderBottom={`1px solid ${theme.colors.gray[200]}`}
					>
						<Grid
							templateColumns={{
								base: "repeat(2, 1fr)",
								md: "repeat(1, 1fr)",
								lg: "repeat(2, 1fr)",
							}}
							gap={{ base: 0, lg: 2 }}
							borderRight={`1px solid ${theme.colors.gray[200]}`}
						>
							<GridItem
								fontSize={{ base: "sm", lg: "md" }}
								borderRight={{
									base: `1px solid ${theme.colors.gray[200]}`,
									md: 0,
								}}
								borderLeft={{
									base: `1px solid ${theme.colors.gray[200]}`,
									md: 0,
								}}
								p={6}
							>
								<Heading as='h2' size='md' pb={3}>
									{t("navigation.ourCompany")}
								</Heading>
								<List
									display='flex'
									flexDir='column'
									gap={2}
									fontSize='sm'
								>
									<ListItem key='about-us'>
										<Link
											variant='footer'
											href={tr.encodeUrl(aboutPath)({
												route: { locale: "en" },
											})}
											data-testid='link-about'
										>
											{t("navigation.aboutUs")}
										</Link>
									</ListItem>
									<ListItem key='careers'>
										<Link
											variant='footer'
											href={`${jobsBasePath}${tr.encodeUrl(
												jobsPath,
											)({})}`}
											data-testid='link-careers'
										>
											{t("navigation.careers")}
										</Link>
									</ListItem>
									<ListItem key='plus'>
										<Link
											variant='footer'
											href={tr.encodeUrl(plusPath)({
												route: { locale: "en" },
											})}
											data-testid='link-plus'
										>
											{t("navigation.plus")}
										</Link>
									</ListItem>
									<ListItem key='subscribe'>
										<Link
											variant='footer'
											href={tr.encodeUrl(subscribePath)({
												route: { locale: "en" },
											})}
											data-testid='link-subscribe'
										>
											{t("navigation.subscribe")}
										</Link>
									</ListItem>
									<ListItem key='catalog'>
										<Link
											variant='footer'
											href={tr.encodeUrl(catalogPath)({
												route: { locale: "en" },
											})}
											data-testid='link-catalog'
										>
											{t("navigation.catalog")}
										</Link>
									</ListItem>
								</List>
							</GridItem>
							<GridItem
								p={{ base: 6 }}
								pt={{ base: 6, md: 0, lg: 6 }}
							>
								<Heading as='h2' size='md' pb={3}>
									{t("navigation.policies")}
								</Heading>
								<List
									display='flex'
									flexDir='column'
									gap={2}
									fontSize={{ base: "sm" }}
								>
									<ListItem key='terms-of-service'>
										<Link
											variant='footer'
											href={tr.encodeUrl(termsPath)({
												route: { locale: "en" },
											})}
											data-testid='link-terms'
										>
											{t("navigation.termsOfService")}
										</Link>
									</ListItem>
									<ListItem key='privacy'>
										<Link
											variant='footer'
											href={tr.encodeUrl(privacyPath)({
												route: { locale: "en" },
											})}
											data-testid='link-privacy'
										>
											{t("navigation.privacy")}
										</Link>
									</ListItem>
									<ListItem key='accessibility'>
										<Link
											variant='footer'
											href={tr.encodeUrl(
												accessibilityPath,
											)({
												route: { locale: "en" },
											})}
											data-testid='link-accessibility'
										>
											{t("navigation.accessibility")}
										</Link>
									</ListItem>
									<ListItem key='manage-cookies'>
										<Button
											variant='footer-policies-deprecated'
											onClick={handleManageCookiesDrawer}
											data-testid='button-cookies'
										>
											{t("navigation.cookies")}
										</Button>
									</ListItem>
									<ListItem key='data-privacy'>
										<UserDataInfo
											userId={userId}
											handleManageCookiesDrawer={
												handleManageCookiesDrawer
											}
										/>
									</ListItem>
								</List>
							</GridItem>
						</Grid>
					</GridItem>
				</Grid>
				<Flex pt={5} pb={10} flexDirection='column' gap={10}>
					<Flex
						justifyContent='space-between'
						alignItems='center'
						flexDirection={{ base: "column-reverse", md: "row" }}
						gap={{ base: 10, md: 0 }}
					>
						<List display='flex' gap={2}>
							{pipe(
								socialMediaIcons,
								RA.map(([themeColor, Icon, path]) => (
									<ListItem key={themeColor}>
										<Link
											href={path}
											isExternal
											display='block'
											_hover={{
												"& > svg": {
													fill: theme.colors[
														themeColor
													],
												},
											}}
											data-testid={`link-${themeColor}`}
										>
											<Icon />
										</Link>
									</ListItem>
								)),
							)}
						</List>
						<Flex
							gap={{ base: 5 }}
							flexDirection={{
								base: "column-reverse",
								md: "row",
							}}
							alignItems='center'
						>
							<UserPreferencesButton
								basePath={alpenroseServiceUrl}
							/>
							<Button
								data-testid='button-top'
								display='flex'
								alignItems='center'
								variant='outline'
								colorScheme='granite'
								onClick={() => {
									document.body.scrollTop = 0 // Safari
									document.documentElement.scrollTop = 0 // Chrome, Firefox
								}}
								leftIcon={
									<ArrowUpwardRounded fontSize='small' />
								}
							>
								{t("navigation.top")}
							</Button>
						</Flex>
					</Flex>
					<Flex justifyContent={{ base: "center", md: "flex-start" }}>
						<Text fontSize='sm'>
							© 2008-{new Date().getFullYear()} Spoonflower Inc.
						</Text>
					</Flex>
				</Flex>
			</Box>
		</Box>
	)
}
