import type { StyleProps, Theme } from "@chakra-ui/react"
import { Box, Link, useTheme } from "@chakra-ui/react"

import { SpoonflowerFlower } from "./spoonflower-flower-logo"
import { SpoonflowerLogo } from "./spoonflower-logo"

interface LogoProps {
	styleProps: StyleProps
	spoonflowerRedirectUrl: string
}

export const Logo = (props: LogoProps) => {
	const theme: Theme = useTheme()
	return (
		<Link
			href={props.spoonflowerRedirectUrl}
			title='Go to the Spoonflower homepage'
			display='inline-block'
			{...props.styleProps}
		>
			<Box
				as='span'
				role='img'
				aria-label='Spoonflower'
				sx={{
					"& > svg": {
						marginBottom: 1,
					},
					[`@media (min-width: ${theme.breakpoints.lg})`]: {
						"& > svg": { height: "55px", width: "193px" },
					},
				}}
			>
				<Box
					display={{ base: "none", md: "inline-block" }}
					sx={{
						[`@media (min-width: ${theme.breakpoints.sm})`]: {
							"& > svg": {
								height: "33px",
								width: "138px",
							},
						},
						[`@media (min-width: ${theme.breakpoints.lg})`]: {
							"& > svg": {
								height: "55px",
								width: "193px",
							},
							paddingTop: ".5rem",
						},
					}}
				>
					<SpoonflowerLogo />
				</Box>

				<Box display={{ base: "inline-block", md: "none" }}>
					<SpoonflowerFlower height='28px' />
				</Box>
			</Box>
		</Link>
	)
}
