import { ApolloClient, ApolloLink } from "@apollo/client"
import type { Request } from "express"

import {
	authLink,
	errorLink,
	httpLink,
	loggerLink,
	timeoutLink,
} from "../links"
import type { ApolloContext } from "../types"
import { getApolloCache } from "./cache"

export function getApolloClient(req?: Request, context?: ApolloContext) {
	const cache = getApolloCache()

	if (IS_CLIENT && window.APOLLO_STATE) {
		cache.restore(window.APOLLO_STATE)
	}

	return new ApolloClient({
		cache,
		connectToDevTools: IS_DEV,
		ssrMode: IS_SERVER,
		link: ApolloLink.from(
			[
				errorLink(context),
				(IS_SERVER || IS_DEV) && loggerLink,
				IS_SERVER && timeoutLink,
				authLink(req),
				httpLink(req),
			].filter(Boolean),
		),
	})
}
