import { Suspense } from "react"
import { Outlet, ScrollRestoration } from "react-router-dom"

import { Loader } from "../../loader"
import { useLayout } from "./hooks"
import { Prompt } from "./prompt"
import { SF } from "./sf"
import { SkipLink } from "./skip"
import { Main } from "./styles"

export const Layout = () => {
	const { mainRef, focusMain } = useLayout()

	return (
		<>
			<SkipLink onClick={focusMain} />
			<SF>
				<Main ref={mainRef} tabIndex={-1}>
					<Prompt />
					<Suspense fallback={<Loader />}>
						<Outlet />
					</Suspense>
				</Main>
			</SF>
			<ScrollRestoration />
		</>
	)
}
