export default {
	"full-size": {
		height: "100%",
		width: "100%",
	},
	"full-width": {
		width: "100%",
	},
	"scrollable-textarea": {
		height: 40,
		overflow: "auto",
		border: "solid 1px",
		borderColor: "gray.400",
		borderRadius: "base",
		padding: 3,
		marginBottom: 4,
	},
	"select-menu-button": {
		minWidth: 44,
	},
	"select-menu-button-large": {
		minWidth: 56,
		textAlign: "left",
	},
	"select-menu-button-xl": {
		textAlign: "left",
		minWidth: 64,
	},
	"menu-wrapper": {
		overflowY: "auto",
		maxHeight: "80vh",
		transform: "translate(16px, 85px) !important",
	},
	"menu-item": {
		paddingRight: 4,
		paddingLeft: 4,
		paddingTop: 3,
		paddingBottom: 3,
		maxWidth: 96,
	},
	"border-right": {
		borderRight: "solid 1px",
		borderColor: "gray.300",
	},
	border: {
		paddingLeft: 12,
		paddingRight: 12,
		paddingTop: 10,
		paddingBottom: 10,
		borderStyle: "solid",
		borderWidth: "1px",
		borderColor: "gray.200",
		borderRadius: "base",
	},
	"footer-button-user-preferences": {
		alignItems: "center",
		height: 10,
		borderRadius: "md",
		fontWeight: "semibold",
		color: "granite.600",
		transitionProperty: "border",
		transitionDuration: "200ms",
		borderWidth: "1px",
		borderStyle: "solid",
		borderColor: "granite.600",
		_hover: {
			bg: "granite.50",
		},
		_focus: {
			bg: "granite.100",
		},
		_active: {
			bg: "granite.100",
		},
	},
	"footer-button-child-user-preferences": {
		px: 4,
		height: "100%",
		display: "flex",
		alignItems: "center",
		_notLast: {
			borderRightWidth: "1px",
			borderRightStyle: "solid",
			borderRightColor: "granite.400",
		},
	},
	"digital-proofing-tile-edge-indicator": {
		display: "none",
		borderWidth: "3px",
		borderColor: "rgba(255, 0, 199, 0.8)",
	},
	disabled: {
		opacity: 0.4,
	},
	"card-header-image": {
		borderTopRadius: "md",
		objectFit: "cover",
		width: "full",
	},
	"info-footer-link": {
		color: "black",
		textDecor: "none",
		_hover: {
			textDecor: "underline",
		},
	},
	"inline-tag": {
		display: "inline",
		fontWeight: "bold",
		fontStyle: "italic",
	},
}
