/* eslint-disable */ 
// @ts-nocheck
import * as Types from '../schema.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllChallengeResultsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type AllChallengeResultsQuery = { __typename?: 'Query', challengeResults?: { __typename?: 'ResultsOfChallenge', id: string, challengeEntries?: Array<{ __typename?: 'ChallengeEntry', id: string, challengeId: string, entryTitle: string, entryPlace?: number | null, validVotes?: number | null, pdpUrl: string, user: { __typename?: 'BaseUser', userId: string, userName: string } }> | null } | null };


export const AllChallengeResultsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AllChallengeResults"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"slug"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"challengeResults"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"slug"},"value":{"kind":"Variable","name":{"kind":"Name","value":"slug"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"challengeEntries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"challengeId"}},{"kind":"Field","name":{"kind":"Name","value":"entryTitle"}},{"kind":"Field","name":{"kind":"Name","value":"entryPlace"}},{"kind":"Field","name":{"kind":"Name","value":"validVotes"}},{"kind":"Field","name":{"kind":"Name","value":"pdpUrl"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}}]}}]}}]}}]}}]} as unknown as DocumentNode<AllChallengeResultsQuery, AllChallengeResultsQueryVariables>;