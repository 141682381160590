import type { Palette, PaletteMode, Theme } from "@mui/material"
import type { TypographyOptions } from "@mui/material/styles/createTypography"

export const getPartialPalette = (
	mode: PaletteMode,
): { palette: Partial<Palette> } => ({
	palette: {
		mode,
		primary: {
			main: "#333333",
			light: "#666666",
			dark: "#000000",
			contrastText: "#FFFFFF",
			containedHoverBackground: "rgba(51, 51, 51, 1)",
			outlinedHoverBackground: "rgba(51, 51, 51, 0.08)",
			outlinedRestingBackground: "rgba(51, 51, 51, 0.3)",
		},
		secondary: {
			main: "#2D6E76",
			light: "#4B8E96",
			dark: "#145159",
			contrastText: "#FFFFFF",
			containedHoverBackground: "rgba(45, 110, 118, 1)",
			outlinedHoverBackground: "rgba(45, 110, 118, 0.08)",
			outlinedRestingBackground: "rgba(45, 110, 118, 0.5)",
		},
		tertiary: {
			main: "#B68900",
			light: "#CEA834",
			dark: "#977200",
			contrastText: "#FFFFFF",
			containedHoverBackground: "rgba(182, 137, 0, 1)",
			outlinedHoverBackground: "rgba(182, 137, 0, 0.08)",
			outlinedRestingBackground: "rgba(182, 137, 0, 0.5)",
		},
		info: {
			main: "#2196F3",
			light: "#64B6F7",
			dark: "#0B79D0",
			contrastText: "#FFFFFF",
		},
		success: {
			main: "#789A24",
			light: "#88AC30",
			dark: "#54710D",
			contrastText: "#FFFFFF",
		},
		warning: {
			main: "#EF6C00",
			light: "#FB8C00",
			dark: "#E65100",
			contrastText: "#FFFFFF",
		},
		error: {
			main: "#B10000",
			light: "#EA0000",
			dark: "#950000",
			contrastText: "#FFFFFF",
		},
		other: {
			ratingActive: "rgba(255, 180, 0, 1)",
			outlinedBorder: "rgba(0, 0, 0, 0.23)",
		},
		paper: {
			secondary: "#EEEEEE",
		},
	},
})

export const mutateFonts = (theme: Theme) => {
	theme.typography.h1 = {
		...theme.typography.h1,
		letterSpacing: -0.5,
		[theme.breakpoints.down("md")]: {
			fontSize: "4.5rem",
			lineHeight: 1.222223,
			letterSpacing: 0,
		},
	}

	theme.typography.h2 = {
		...theme.typography.h3,
		fontSize: "4rem",
		lineHeight: "1.125",
		letterSpacing: 0,
		[theme.breakpoints.down("md")]: {
			fontSize: "3.5rem",
			lineHeight: 1.14286,
			letterSpacing: 0,
		},
	}

	theme.typography.h3 = {
		...theme.typography.h3,
		[theme.breakpoints.down("md")]: {
			fontSize: "2.5rem",
			lineHeight: 1.2,
			letterSpacing: 0,
		},
	}

	theme.typography.h4 = {
		...theme.typography.h4,
		fontSize: "2rem",
		lineHeight: 1.25,
		letterSpacing: 0,
		[theme.breakpoints.down("md")]: {
			fontSize: "1.75rem",
			lineHeight: 1.142858,
			letterSpacing: 0,
		},
	}

	theme.typography.body3 = {
		...theme.typography.body2,
		fontSize: "0.8125rem",
		lineHeight: 1.125,
	}

	theme.typography.h4Serif = {
		...theme.typography.h4,
		...theme.typography.h4Serif,
	}

	return theme
}

export const getTypography = (): {
	typography: TypographyOptions
} => ({
	typography: {
		allVariants: {
			fontFamily: "Lato, 'Trebuchet MS', sans-serif",
		},
		h4Serif: {
			fontFamily: "ParryPro",
		},
	},
})

export const getComponents = (): { components: Theme["components"] } => ({
	components: {
		MuiCssBaseline: {
			// Enables body to stretch to 100% on mobile with no scroll
			styleOverrides: `
				html {
					min-height: 100%;
					display: flex;
					flex-direction: column;
					scroll-behavior: smooth;
				}
				
				body {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
				}
			
				#root {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
				}`,
		},
	},
})

export const getBreakpoints = (): {
	breakpoints: Partial<Theme["breakpoints"]>
} => ({
	breakpoints: {
		values: {
			xs: 0,
			sm: 504,
			md: 792,
			lg: 1080,
			xl: 1374,
		},
	},
})
