import {
	fetchOrderCount,
	fetchOriumOrderCount,
	fetchUserQuery,
} from "@artists/api-client"
import { E, N, pipe, TE } from "@artists/sf-fp"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useEffect, useState } from "react"
import { type Float } from "schemata-ts/float"

const isNumber = (n: unknown): n is number => N.isNumber(n)

export const useCartCount = (
	basePath: string,
	cartPath: string,
	orderIdPending?: number,
) => {
	const [cartCount, setCount] = useState(0)
	const migrateCartEnabled = useFeatureFlagEnabled("migrateCart")
	const migrateCartRef = Boolean(migrateCartEnabled)

	useEffect(() => {
		async function fetchUserCartItemsCount() {
			const cartItemsCountE = await pipe(
				migrateCartRef,
				TE.fromPredicate(
					v => v === true,
					() => "migrateCart feature flag is not enabled",
				),
				TE.chainW(() =>
					pipe(
						fetchOriumOrderCount(cartPath),
						TE.map(ca => ({
							data: {
								count: ca.cartCount.cartCount,
							},
						})),
						TE.orElse(() => TE.of({ data: { count: 0 as Float } })),
					),
				),
				TE.orElse(() =>
					pipe(
						orderIdPending,
						TE.fromPredicate(
							isNumber,
							() => "orderIdPending is not defined",
						),
						TE.orElseW(() =>
							pipe(
								fetchUserQuery(basePath),
								TE.map(u => u.data.order_id_pending),
							),
						),
						TE.chain(fetchOrderCount(basePath)),
					),
				),
			)()

			pipe(
				cartItemsCountE,
				E.fold(
					() => {},
					({ data: { count } }) => {
						setCount(count)
					},
				),
			)
		}

		fetchUserCartItemsCount()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [migrateCartRef])

	return { cartCount }
}
