import { ErrorOutline } from "@mui/icons-material"
import { Alert, Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const Noscript = () => {
	const [t] = useTranslation()

	return (
		<Box
			sx={{
				zIndex: 1,
				gridArea: "1 / 1 / 1 / 1",
				pointerEvents: "none",
			}}
		>
			<noscript>
				<Alert
					icon={false}
					severity='error'
					sx={{
						py: 1,
						justifyContent: "center",
						"& .MuiAlert-message": {
							display: "flex",
							justifyContent: "center",
							gap: 1,
						},
					}}
				>
					<ErrorOutline />

					<Typography fontSize='small' alignSelf='center'>
						{t("noscript")}
					</Typography>
				</Alert>
			</noscript>
		</Box>
	)
}
