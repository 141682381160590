import { useRef } from "react"

import { useApolloError } from "../../../../../../@common"
import { usePageTrack, usePostHogInit, usePWA } from "../../../../../utils"

export const useLayout = () => {
	useApolloError()
	usePostHogInit()
	usePageTrack()
	usePWA()

	const mainRef = useRef<HTMLElement>(null)
	const focusMain = () => mainRef.current?.focus()

	return { mainRef, focusMain }
}
