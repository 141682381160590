import { S } from "@artists/sf-fp"

import { getInstances } from "../utils/getInstances"

export const CartItemsSchema = S.Struct({
	count: S.Number,
})

export const { Eq, Transcoder, Guard, TranscoderPar } =
	getInstances(CartItemsSchema)

export type CartItems = S.TypeOf<typeof CartItemsSchema>
