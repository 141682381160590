import { sliderAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(sliderAnatomy.keys)

const baseStyle = definePartsStyle({
	thumb: { bg: "teal.600", zIndex: 0 },
	filledTrack: { bg: "teal.500" },
})

export const sliderTheme = defineMultiStyleConfig({ baseStyle })
