import type { SVGProps } from "react"

export const YouTubeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height={25}
		width={25}
		viewBox='0 0 152 152'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g data-name='Layer 2'>
			<g data-name='02.YouTube'>
				<path d='m65.46 88.26 21.08-12.23-21.08-12.29z' />
				<path d='M76 0a76 76 0 1 0 76 76A76 76 0 0 0 76 0zm40 89.45a15.13 15.13 0 0 1-15.13 15.14H51.13A15.13 15.13 0 0 1 36 89.45v-26.9a15.13 15.13 0 0 1 15.13-15.14h49.74A15.13 15.13 0 0 1 116 62.55z' />
			</g>
		</g>
	</svg>
)
