export default {
	"4xs": "10rem",
	icon: {
		"4xs": ".75rem",
		"3xs": ".875rem",
		"2xs": "1rem",
		xs: "1.125rem",
		sm: "1.25rem",
		md: "1.5rem",
		lg: "1.75rem",
		xl: "2rem",
		"2xl": "2.25rem",
		"3xl": "2.5rem",
		"4xl": "3rem",
	},
}
