import { deriveTranscoderPar, pipe, S, type TCE, TE, tr } from "@artists/sf-fp"

import { UserStatsS } from "./models/alpenrose-models"
import { asJson } from "./utils/as-json"

/**
 * Request:
 * https://api-gateway.spoonflower.com/alpenrose/user/<user_id>/user_stats
 *
 * Example Response: { "data": { "cookie_value": "2.14.7-9m.0.a", "data_layer":
 * { "userID": "97496927", "segment": "Admin", "contestVoter": "false",
 * "designForSale": "true", "marketplaceBuyer": "false", "accountAge": "7-9m",
 * "numOrders": "0", "proUser": "false", "currencyCode": "AUD", "countryCode":
 * "AU", "em":
 * "0051e57ffb83367b1e1bf0d7893451e731bd6fe8eabb0537fd6425d798f62d5b",
 * "customerStatus": "new", "optOut": false } } }
 */
const UserStatsResponseS = S.Struct({
	data: UserStatsS,
})

export type UserStatsResponse = S.TypeOf<typeof UserStatsResponseS>

export const userStatsResponseDecoder = deriveTranscoderPar(UserStatsResponseS)

// https://api-gateway.spoonflower.com/alpenrose/user/<user_id>/user_stats
export const userStatsPath = pipe(
	tr.RootPath,
	tr.path("alpenrose"),
	tr.path("user"),
	tr.param("userId", tr.stringRC),
	tr.path("user_stats"),
)

export const fetchUserStats =
	(basePath: string) =>
	(
		userId: number,
	): TE.TaskEither<string | TCE.TranscodeErrors, UserStatsResponse> =>
		pipe(
			TE.tryCatch(
				() =>
					fetch(
						`${basePath}${tr.encodeUrl(userStatsPath)({
							route: { userId: userId.toString() },
						})}`,
						{
							credentials: "include",
						},
					),
				e => `Failed to fetch user stats: ${e}`,
			),
			TE.chain(asJson("user stats")),
			TE.chainW(userStatsResponseDecoder.decode),
		)
