import { modalAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system"

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(modalAnatomy.keys)

const baseStyle = definePartsStyle({
	header: { px: 0, pt: 0, pb: 4 },
	body: { p: 0 },
	dialog: { p: 6 },
	footer: { pb: 0, px: 0 },
})

export const modalTheme = defineMultiStyleConfig({
	baseStyle,
	variants: {
		fullModalContent: {
			dialog: { p: 0 },
		},
	},
})
