import "./styles.scss"

import type { FC } from "react"

import { type AppProps, getENV } from "../../../../@common"
import { Providers } from "./providers"

export const App: FC<AppProps> = ({ children, nonce, ...props }) => {
	__webpack_nonce__ = nonce
	__webpack_public_path__ = getENV("PUBLIC_PATH")

	return <Providers {...props}>{children}</Providers>
}
