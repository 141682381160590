import * as Ap from "fp-ts/Apply"
import { type Const } from "fp-ts/Const"
import * as E from "fp-ts/Either"
import { tuple } from "fp-ts/function"
import * as RA from "fp-ts/ReadonlyArray"
import * as T from "fp-ts/Task"
import * as TE from "fp-ts/TaskEither"
import { fromPredicate, type Guard } from "schemata-ts/Guard"
import * as TCE from "schemata-ts/TranscodeError"

export * from "schemata-ts/TranscodeError"

export const guardDecodeError: Guard<TCE.TranscodeErrors> = fromPredicate(
	(u): u is TCE.TranscodeErrors => u instanceof TCE.TranscodeErrors,
)

/** Collates field errors and discards additional structure */
export const getFieldErrors: <A extends Record<string, unknown>>(
	getErrorMessage: (k: keyof A) => string,
) => (
	e: Const<TCE.TranscodeErrors, A>,
) => ReadonlyArray<
	readonly [propKey: string, error: string]
> = getErrorMessage =>
	TCE.foldMapDepthFirst(RA.getMonoid<readonly [string, string]>())({
		TypeMismatch: () => [],
		SerializationError: () => [],
		UnexpectedValue: () => [],
		ErrorAtKey: key => [tuple(key, getErrorMessage(key))],
		ErrorAtIndex: (_, errors) => errors,
		ErrorAtUnionMember: (_, errors) => errors,
	})

export const Applicative = E.getApplicativeValidation(TCE.Semigroup)

export const ApplicativePar = TE.getApplicativeTaskValidation(
	T.ApplicativePar,
	TCE.Semigroup,
)

export const apS = Ap.apS(Applicative)
export const sequenceS = Ap.sequenceS(Applicative)
export const sequenceT = Ap.sequenceT(Applicative)

export const apSPar = Ap.apS(ApplicativePar)
export const sequenceSPar = Ap.sequenceS(ApplicativePar)
export const sequenceTPar = Ap.sequenceT(ApplicativePar)
