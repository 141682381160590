import type { Locale } from "@artists/regional-preferences"
import { locales } from "@artists/regional-preferences"
import { O, pipe } from "@artists/sf-fp"
import type { StyleProps, Theme } from "@chakra-ui/react"
import {
	Box,
	Flex,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	List,
	ListItem,
	Text,
	useOutsideClick,
	useTheme,
} from "@chakra-ui/react"
import { default as SearchIcon } from "@mui/icons-material/Search"
import * as tr from "@simspace/trout/index"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

const searchCategories = [
	"fabric",
	"wallpaper",
	"designers",
	"collections",
	"bedding",
	"living&decor",
	"dining",
] as const

// /${i18n.resolvedLanguage}/shop?on=${key}&q=${searchInput}&sort=bestSelling
const searchRoute = pipe(
	tr.RootPath,
	tr.param("locale", tr.stringLiteralRC(...locales)),
	tr.path("shop"),
	tr.queryParam("on", tr.stringLiteralRC(...searchCategories)),
	tr.queryParam("q", tr.stringRC),
	tr.queryParam("sort", tr.stringLiteralRC("bestSelling")),
	tr.queryParamOp("autoApply", tr.booleanRC),
)

interface SearchProps {
	styleProps?: StyleProps
	spoonflowerRedirectUrl: string
	searchSuffix?: string
}

export const Search = (props: SearchProps) => {
	const [searchInput, setSearchInput] = useState("")
	const { t, i18n } = useTranslation("search")
	const theme: Theme = useTheme()
	const ref = useRef<HTMLUListElement>(null)
	useOutsideClick({
		ref,
		handler: () => setSearchInput(""),
	})

	const SearchWithPadding = () => (
		<Box display='flex' mr={4}>
			<SearchIcon />
		</Box>
	)

	const handleSearch = () => {
		window.location.assign(
			`${props.spoonflowerRedirectUrl}${tr.encodeUrl(searchRoute)({
				route: { locale: i18n.resolvedLanguage as Locale },
				query: {
					on: "fabric",
					q: searchInput,
					sort: "bestSelling",
					autoApply: O.some(true),
				},
			})}`,
		)
	}

	const suffix = props.searchSuffix ?? "desktop"

	return (
		<Box
			flexGrow={1}
			maxWidth={{ base: "full" }}
			position='relative'
			zIndex={1}
			{...props.styleProps}
		>
			<form
				onSubmit={e => {
					e.preventDefault()
					handleSearch()
				}}
			>
				<InputGroup alignSelf='center'>
					<Input
						type='text'
						id={`search-${suffix}`}
						role='combobox'
						aria-controls='search-suggestions'
						aria-autocomplete='list'
						aria-expanded={searchInput.length > 2}
						value={searchInput}
						onChange={e => setSearchInput(e.target.value)}
						placeholder={t("queryPlaceholder")}
						borderTopLeftRadius={
							searchInput.length > 2 ? "20px" : "full"
						}
						borderTopRightRadius={
							searchInput.length > 2 ? "20px" : "full"
						}
						borderBottomLeftRadius={
							searchInput.length > 2 ? "0px" : "full"
						}
						borderBottomRightRadius={
							searchInput.length > 2 ? "0px" : "full"
						}
						borderColor='gray.200'
						_focusVisible={{
							borderColor: theme.colors.teal[600],
							boxShadow: `0 0 0 1px ${theme.colors.teal[600]}`,
						}}
						_placeholder={{
							color: theme.colors.gray[800],
						}}
					/>
					<InputRightElement
						onClick={handleSearch}
						title={t("searchButtonTitle")}
						children={<SearchWithPadding />}
					/>
				</InputGroup>
			</form>
			<List
				id='search-suggestions'
				role='listbox'
				aria-label='search-suggestions'
				border={`1px solid ${theme.colors.gray[50]}`}
				position='absolute'
				width='full'
				borderBottomLeftRadius='2xl'
				borderBottomRightRadius='2xl'
				bgColor='white'
				zIndex={2}
				display={searchInput.length < 3 ? "none" : "block"}
				ref={ref}
			>
				{searchCategories.map(key => (
					<ListItem
						id={`search-suggestion-${key}`}
						key={key}
						role='option'
						value={`${searchInput}-${key}`}
						_hover={{ bgColor: theme.colors.gray[50] }}
						_last={{
							borderBottomLeftRadius: "2xl",
							borderBottomRightRadius: "2xl",
						}}
					>
						<Link
							href={`${
								props.spoonflowerRedirectUrl
							}${tr.encodeUrl(searchRoute)({
								route: {
									locale: i18n.resolvedLanguage as Locale,
								},
								query: {
									on: key,
									q: searchInput,
									sort: "bestSelling",
									autoApply: O.none,
								},
							})}`}
							id={`search-${key}`}
							display='block'
							textDecoration='none'
							_hover={{
								textDecoration: "none",
							}}
						>
							<Flex p={1} ps={3}>
								<Text fontWeight='500' mr={1}>
									{searchInput}{" "}
								</Text>
								{t(`substrateOptions.preposition.${key}`)}{" "}
								{t(`substrateOptions.${key}`)}
							</Flex>
						</Link>
					</ListItem>
				))}
			</List>
		</Box>
	)
}
