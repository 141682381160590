import type { Locale } from "@artists/regional-preferences"
import { DEFAULT_LOCALE } from "@artists/regional-preferences"
import { deriveTranscoderPar, O, pipe, S, TE, tr } from "@artists/sf-fp"

import { UserQueryS, UserS } from "./models/alpenrose-models"
import { asJson } from "./utils/as-json"

/**
 * Request: https://api-gateway.spoonflower.com/alpenrose/user/me
 *
 * Example Response: { "data": { "id": 97496927, "email":
 * "adam.recvlohe@spoonflower.com", "screen_name": "adam_", "pro": false,
 * "guest": false, "design_owner": true, "order_id_pending": 100562989,
 * "spoondollars": { "available": "0,00 €", "raw_available": 0.0, "currency":
 * "EUR" }, "favorites": { "design_ids": [ 12412192 ] }, "business_tools":
 * false, "preferences": { "currency": "EUR", "measurement_system": "IMPERIAL",
 * "country": "AF", "locale": "es", "adult_content": "ADULT_CONTENT_OFF" },
 * "newsletter_opt_in": false } }
 */
export const UserResponseS = S.Struct({
	data: UserS,
})

export type UserResponse = S.TypeOf<typeof UserResponseS>

export const userResponseTaskDecoder = deriveTranscoderPar(UserResponseS)

export const makeDefaultLocale: (locale: O.Option<Locale>) => Locale =
	O.getOrElse(() => DEFAULT_LOCALE)

// https://api-gateway.spoonflower.com/alpenrose/user/me
export const userPath = pipe(
	tr.RootPath,
	tr.path("alpenrose"),
	tr.path("user"),
	tr.path("me"),
)

export const fetchUser = (basePath: string) =>
	pipe(
		TE.tryCatch(
			() =>
				fetch(`${basePath}${tr.encodeUrl(userPath)({})}`, {
					credentials: "include",
				}),
			e => `Failed to fetch user: ${e}`,
		),
		TE.chain(asJson("user")),
		TE.chainW(userResponseTaskDecoder.decode),
	)

// https://api-gateway.spoonflower.com/alpenrose/user/me?preferences=true
export const userQueryPath = pipe(
	tr.RootPath,
	tr.path("alpenrose"),
	tr.path("user"),
	tr.path("me"),
	tr.queryParam("preferences", tr.booleanRC),
)

const UserQueryResponseS = S.Struct({
	data: UserQueryS,
})

export type UserQueryResponse = S.TypeOf<typeof UserQueryResponseS>

export const userQueryResponseTranscoderPar =
	deriveTranscoderPar(UserQueryResponseS)

export const fetchUserQuery = (
	basePath: string,
	query: { preferences: boolean } = { preferences: true },
) =>
	pipe(
		TE.tryCatch(
			() =>
				fetch(
					`${basePath}${tr.encodeUrl(userQueryPath)({
						query,
					})}`,
					{
						credentials: "include",
					},
				),
			e => `Failed to fetch user query: ${e}`,
		),
		TE.chain(asJson("user query")),
		TE.chainW(userQueryResponseTranscoderPar.decode),
	)
