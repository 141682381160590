export const countryCodeMap = {
	AF: {
		id: 1,
		alpha2: "AF",
		localized_names: {
			en: "Afghanistan",
		},
	},
	AL: {
		id: 2,
		alpha2: "AL",
		localized_names: {
			en: "Albania",
		},
	},
	DZ: {
		id: 3,
		alpha2: "DZ",
		localized_names: {
			en: "Algeria",
		},
	},
	AS: {
		id: 4,
		alpha2: "AS",
		localized_names: {
			en: "American Samoa",
		},
	},
	AD: {
		id: 5,
		alpha2: "AD",
		localized_names: {
			en: "Andorra",
		},
	},
	AO: {
		id: 6,
		alpha2: "AO",
		localized_names: {
			en: "Angola",
		},
	},
	AI: {
		id: 7,
		alpha2: "AI",
		localized_names: {
			en: "Anguilla",
		},
	},
	AG: {
		id: 8,
		alpha2: "AG",
		localized_names: {
			en: "Antigua and Barbuda",
		},
	},
	AR: {
		id: 9,
		alpha2: "AR",
		localized_names: {
			en: "Argentina",
		},
	},
	AM: {
		id: 10,
		alpha2: "AM",
		localized_names: {
			en: "Armenia",
		},
	},
	AW: {
		id: 11,
		alpha2: "AW",
		localized_names: {
			en: "Aruba",
		},
	},
	AU: {
		id: 12,
		alpha2: "AU",
		localized_names: {
			en: "Australia",
		},
	},
	AT: {
		id: 13,
		alpha2: "AT",
		localized_names: {
			en: "Austria",
		},
	},
	AZ: {
		id: 14,
		alpha2: "AZ",
		localized_names: {
			en: "Azerbaijan",
		},
	},
	BS: {
		id: 15,
		alpha2: "BS",
		localized_names: {
			en: "Bahamas",
		},
	},
	BH: {
		id: 16,
		alpha2: "BH",
		localized_names: {
			en: "Bahrain",
		},
	},
	BD: {
		id: 17,
		alpha2: "BD",
		localized_names: {
			en: "Bangladesh",
		},
	},
	BB: {
		id: 18,
		alpha2: "BB",
		localized_names: {
			en: "Barbados",
		},
	},
	BE: {
		id: 19,
		alpha2: "BE",
		localized_names: {
			en: "Belgium",
		},
	},
	BZ: {
		id: 20,
		alpha2: "BZ",
		localized_names: {
			en: "Belize",
		},
	},
	BJ: {
		id: 21,
		alpha2: "BJ",
		localized_names: {
			en: "Benin",
		},
	},
	BM: {
		id: 22,
		alpha2: "BM",
		localized_names: {
			en: "Bermuda",
		},
	},
	BT: {
		id: 23,
		alpha2: "BT",
		localized_names: {
			en: "Bhutan",
		},
	},
	BO: {
		id: 24,
		alpha2: "BO",
		localized_names: {
			en: "Bolivia",
		},
	},
	BA: {
		id: 25,
		alpha2: "BA",
		localized_names: {
			en: "Bosnia and Herzegovina",
		},
	},
	BW: {
		id: 26,
		alpha2: "BW",
		localized_names: {
			en: "Botswana",
		},
	},
	BV: {
		id: 27,
		alpha2: "BV",
		localized_names: {
			en: "Bouvet Island",
		},
	},
	BR: {
		id: 28,
		alpha2: "BR",
		localized_names: {
			en: "Brazil",
		},
	},
	IO: {
		id: 29,
		alpha2: "IO",
		localized_names: {
			en: "British Indian Ocean Territory",
		},
	},
	BN: {
		id: 30,
		alpha2: "BN",
		localized_names: {
			en: "Brunei Darussalam",
		},
	},
	BG: {
		id: 31,
		alpha2: "BG",
		localized_names: {
			en: "Bulgaria",
		},
	},
	BF: {
		id: 32,
		alpha2: "BF",
		localized_names: {
			en: "Burkina Faso",
		},
	},
	BI: {
		id: 33,
		alpha2: "BI",
		localized_names: {
			en: "Burundi",
		},
	},
	KH: {
		id: 34,
		alpha2: "KH",
		localized_names: {
			en: "Cambodia",
		},
	},
	CM: {
		id: 35,
		alpha2: "CM",
		localized_names: {
			en: "Cameroon",
		},
	},
	CA: {
		id: 36,
		alpha2: "CA",
		localized_names: {
			en: "Canada",
		},
	},
	CV: {
		id: 37,
		alpha2: "CV",
		localized_names: {
			en: "Cabo Verde",
		},
	},
	KY: {
		id: 38,
		alpha2: "KY",
		localized_names: {
			en: "Cayman Islands",
		},
	},
	CF: {
		id: 39,
		alpha2: "CF",
		localized_names: {
			en: "Central African Republic",
		},
	},
	TD: {
		id: 40,
		alpha2: "TD",
		localized_names: {
			en: "Chad",
		},
	},
	CL: {
		id: 41,
		alpha2: "CL",
		localized_names: {
			en: "Chile",
		},
	},
	CN: {
		id: 42,
		alpha2: "CN",
		localized_names: {
			en: "China",
		},
	},
	CX: {
		id: 43,
		alpha2: "CX",
		localized_names: {
			en: "Christmas Island",
		},
	},
	CC: {
		id: 44,
		alpha2: "CC",
		localized_names: {
			en: "Cocos (Keeling) Islands",
		},
	},
	CO: {
		id: 45,
		alpha2: "CO",
		localized_names: {
			en: "Colombia",
		},
	},
	KM: {
		id: 46,
		alpha2: "KM",
		localized_names: {
			en: "Comoros",
		},
	},
	CK: {
		id: 47,
		alpha2: "CK",
		localized_names: {
			en: "Cook Islands",
		},
	},
	CR: {
		id: 48,
		alpha2: "CR",
		localized_names: {
			en: "Costa Rica",
		},
	},
	HR: {
		id: 49,
		alpha2: "HR",
		localized_names: {
			en: "Croatia",
		},
	},
	CW: {
		id: 50,
		alpha2: "CW",
		localized_names: {
			en: "Curaçao",
		},
	},
	CY: {
		id: 51,
		alpha2: "CY",
		localized_names: {
			en: "Cyprus",
		},
	},
	CZ: {
		id: 52,
		alpha2: "CZ",
		localized_names: {
			en: "Czechia",
		},
	},
	DK: {
		id: 53,
		alpha2: "DK",
		localized_names: {
			en: "Denmark",
		},
	},
	DJ: {
		id: 54,
		alpha2: "DJ",
		localized_names: {
			en: "Djibouti",
		},
	},
	DM: {
		id: 55,
		alpha2: "DM",
		localized_names: {
			en: "Dominica",
		},
	},
	DO: {
		id: 56,
		alpha2: "DO",
		localized_names: {
			en: "Dominican Republic",
		},
	},
	EC: {
		id: 57,
		alpha2: "EC",
		localized_names: {
			en: "Ecuador",
		},
	},
	EG: {
		id: 58,
		alpha2: "EG",
		localized_names: {
			en: "Egypt",
		},
	},
	SV: {
		id: 59,
		alpha2: "SV",
		localized_names: {
			en: "El Salvador",
		},
	},
	GQ: {
		id: 60,
		alpha2: "GQ",
		localized_names: {
			en: "Equatorial Guinea",
		},
	},
	ER: {
		id: 61,
		alpha2: "ER",
		localized_names: {
			en: "Eritrea",
		},
	},
	EE: {
		id: 62,
		alpha2: "EE",
		localized_names: {
			en: "Estonia",
		},
	},
	ET: {
		id: 63,
		alpha2: "ET",
		localized_names: {
			en: "Ethiopia",
		},
	},
	FK: {
		id: 64,
		alpha2: "FK",
		localized_names: {
			en: "Falkland Islands (Malvinas)",
		},
	},
	FO: {
		id: 65,
		alpha2: "FO",
		localized_names: {
			en: "Faroe Islands",
		},
	},
	FJ: {
		id: 66,
		alpha2: "FJ",
		localized_names: {
			en: "Fiji",
		},
	},
	FI: {
		id: 67,
		alpha2: "FI",
		localized_names: {
			en: "Finland",
		},
	},
	FR: {
		id: 68,
		alpha2: "FR",
		localized_names: {
			en: "France",
		},
	},
	GF: {
		id: 69,
		alpha2: "GF",
		localized_names: {
			en: "French Guiana",
		},
	},
	PF: {
		id: 70,
		alpha2: "PF",
		localized_names: {
			en: "French Polynesia",
		},
	},
	TF: {
		id: 71,
		alpha2: "TF",
		localized_names: {
			en: "French Southern Territories",
		},
	},
	GA: {
		id: 72,
		alpha2: "GA",
		localized_names: {
			en: "Gabon",
		},
	},
	GM: {
		id: 73,
		alpha2: "GM",
		localized_names: {
			en: "Gambia",
		},
	},
	GE: {
		id: 74,
		alpha2: "GE",
		localized_names: {
			en: "Georgia",
		},
	},
	DE: {
		id: 75,
		alpha2: "DE",
		localized_names: {
			en: "Germany",
		},
	},
	GH: {
		id: 76,
		alpha2: "GH",
		localized_names: {
			en: "Ghana",
		},
	},
	GI: {
		id: 77,
		alpha2: "GI",
		localized_names: {
			en: "Gibraltar",
		},
	},
	GR: {
		id: 78,
		alpha2: "GR",
		localized_names: {
			en: "Greece",
		},
	},
	GL: {
		id: 79,
		alpha2: "GL",
		localized_names: {
			en: "Greenland",
		},
	},
	GD: {
		id: 80,
		alpha2: "GD",
		localized_names: {
			en: "Grenada",
		},
	},
	GP: {
		id: 81,
		alpha2: "GP",
		localized_names: {
			en: "Guadeloupe",
		},
	},
	GU: {
		id: 82,
		alpha2: "GU",
		localized_names: {
			en: "Guam",
		},
	},
	GT: {
		id: 83,
		alpha2: "GT",
		localized_names: {
			en: "Guatemala",
		},
	},
	GG: {
		id: 84,
		alpha2: "GG",
		localized_names: {
			en: "Guernsey",
		},
	},
	GN: {
		id: 85,
		alpha2: "GN",
		localized_names: {
			en: "Guinea",
		},
	},
	GW: {
		id: 86,
		alpha2: "GW",
		localized_names: {
			en: "Guinea-Bissau",
		},
	},
	GY: {
		id: 87,
		alpha2: "GY",
		localized_names: {
			en: "Guyana",
		},
	},
	HT: {
		id: 88,
		alpha2: "HT",
		localized_names: {
			en: "Haiti",
		},
	},
	HM: {
		id: 89,
		alpha2: "HM",
		localized_names: {
			en: "Heard Island and McDonald Islands",
		},
	},
	VA: {
		id: 90,
		alpha2: "VA",
		localized_names: {
			en: "Holy See (Vatican City State)",
		},
	},
	HN: {
		id: 91,
		alpha2: "HN",
		localized_names: {
			en: "Honduras",
		},
	},
	HK: {
		id: 92,
		alpha2: "HK",
		localized_names: {
			en: "Hong Kong",
		},
	},
	HU: {
		id: 93,
		alpha2: "HU",
		localized_names: {
			en: "Hungary",
		},
	},
	IS: {
		id: 94,
		alpha2: "IS",
		localized_names: {
			en: "Iceland",
		},
	},
	IN: {
		id: 95,
		alpha2: "IN",
		localized_names: {
			en: "India",
		},
	},
	ID: {
		id: 96,
		alpha2: "ID",
		localized_names: {
			en: "Indonesia",
		},
	},
	IE: {
		id: 97,
		alpha2: "IE",
		localized_names: {
			en: "Ireland",
		},
	},
	IM: {
		id: 98,
		alpha2: "IM",
		localized_names: {
			en: "Isle of Man",
		},
	},
	IL: {
		id: 99,
		alpha2: "IL",
		localized_names: {
			en: "Israel",
		},
	},
	IT: {
		id: 100,
		alpha2: "IT",
		localized_names: {
			en: "Italy",
		},
	},
	JM: {
		id: 101,
		alpha2: "JM",
		localized_names: {
			en: "Jamaica",
		},
	},
	JP: {
		id: 102,
		alpha2: "JP",
		localized_names: {
			en: "Japan",
		},
	},
	JE: {
		id: 103,
		alpha2: "JE",
		localized_names: {
			en: "Jersey",
		},
	},
	JO: {
		id: 104,
		alpha2: "JO",
		localized_names: {
			en: "Jordan",
		},
	},
	KZ: {
		id: 105,
		alpha2: "KZ",
		localized_names: {
			en: "Kazakhstan",
		},
	},
	KE: {
		id: 106,
		alpha2: "KE",
		localized_names: {
			en: "Kenya",
		},
	},
	KI: {
		id: 107,
		alpha2: "KI",
		localized_names: {
			en: "Kiribati",
		},
	},
	KR: {
		id: 108,
		alpha2: "KR",
		localized_names: {
			en: "Korea, Republic of",
		},
	},
	KW: {
		id: 110,
		alpha2: "KW",
		localized_names: {
			en: "Kuwait",
		},
	},
	KG: {
		id: 111,
		alpha2: "KG",
		localized_names: {
			en: "Kyrgyzstan",
		},
	},
	LA: {
		id: 112,
		alpha2: "LA",
		localized_names: {
			en: "Lao People's Democratic Republic",
		},
	},
	LV: {
		id: 113,
		alpha2: "LV",
		localized_names: {
			en: "Latvia",
		},
	},
	LS: {
		id: 114,
		alpha2: "LS",
		localized_names: {
			en: "Lesotho",
		},
	},
	LR: {
		id: 115,
		alpha2: "LR",
		localized_names: {
			en: "Liberia",
		},
	},
	LI: {
		id: 116,
		alpha2: "LI",
		localized_names: {
			en: "Liechtenstein",
		},
	},
	LT: {
		id: 117,
		alpha2: "LT",
		localized_names: {
			en: "Lithuania",
		},
	},
	LU: {
		id: 118,
		alpha2: "LU",
		localized_names: {
			en: "Luxembourg",
		},
	},
	MO: {
		id: 119,
		alpha2: "MO",
		localized_names: {
			en: "Macao",
		},
	},
	MK: {
		id: 120,
		alpha2: "MK",
		localized_names: {
			en: "Macedonia, Republic of",
		},
	},
	MG: {
		id: 121,
		alpha2: "MG",
		localized_names: {
			en: "Madagascar",
		},
	},
	MW: {
		id: 122,
		alpha2: "MW",
		localized_names: {
			en: "Malawi",
		},
	},
	MY: {
		id: 123,
		alpha2: "MY",
		localized_names: {
			en: "Malaysia",
		},
	},
	MV: {
		id: 124,
		alpha2: "MV",
		localized_names: {
			en: "Maldives",
		},
	},
	ML: {
		id: 125,
		alpha2: "ML",
		localized_names: {
			en: "Mali",
		},
	},
	MT: {
		id: 126,
		alpha2: "MT",
		localized_names: {
			en: "Malta",
		},
	},
	MH: {
		id: 127,
		alpha2: "MH",
		localized_names: {
			en: "Marshall Islands",
		},
	},
	MQ: {
		id: 128,
		alpha2: "MQ",
		localized_names: {
			en: "Martinique",
		},
	},
	MR: {
		id: 129,
		alpha2: "MR",
		localized_names: {
			en: "Mauritania",
		},
	},
	MU: {
		id: 130,
		alpha2: "MU",
		localized_names: {
			en: "Mauritius",
		},
	},
	YT: {
		id: 131,
		alpha2: "YT",
		localized_names: {
			en: "Mayotte",
		},
	},
	MX: {
		id: 132,
		alpha2: "MX",
		localized_names: {
			en: "Mexico",
		},
	},
	FM: {
		id: 133,
		alpha2: "FM",
		localized_names: {
			en: "Micronesia, Federated States of",
		},
	},
	MD: {
		id: 134,
		alpha2: "MD",
		localized_names: {
			en: "Moldova",
		},
	},
	MC: {
		id: 135,
		alpha2: "MC",
		localized_names: {
			en: "Monaco",
		},
	},
	MN: {
		id: 136,
		alpha2: "MN",
		localized_names: {
			en: "Mongolia",
		},
	},
	ME: {
		id: 137,
		alpha2: "ME",
		localized_names: {
			en: "Montenegro",
		},
	},
	MS: {
		id: 138,
		alpha2: "MS",
		localized_names: {
			en: "Montserrat",
		},
	},
	MA: {
		id: 139,
		alpha2: "MA",
		localized_names: {
			en: "Morocco",
		},
	},
	MZ: {
		id: 140,
		alpha2: "MZ",
		localized_names: {
			en: "Mozambique",
		},
	},
	MM: {
		id: 141,
		alpha2: "MM",
		localized_names: {
			en: "Myanmar",
		},
	},
	NA: {
		id: 142,
		alpha2: "NA",
		localized_names: {
			en: "Namibia",
		},
	},
	NR: {
		id: 143,
		alpha2: "NR",
		localized_names: {
			en: "Nauru",
		},
	},
	NP: {
		id: 144,
		alpha2: "NP",
		localized_names: {
			en: "Nepal",
		},
	},
	NL: {
		id: 145,
		alpha2: "NL",
		localized_names: {
			en: "Netherlands",
		},
	},
	AN: {
		id: 146,
		alpha2: "AN",
		localized_names: {
			en: "Netherlands Antilles",
		},
	},
	NC: {
		id: 147,
		alpha2: "NC",
		localized_names: {
			en: "New Caledonia",
		},
	},
	NZ: {
		id: 148,
		alpha2: "NZ",
		localized_names: {
			en: "New Zealand",
		},
	},
	NI: {
		id: 149,
		alpha2: "NI",
		localized_names: {
			en: "Nicaragua",
		},
	},
	NE: {
		id: 150,
		alpha2: "NE",
		localized_names: {
			en: "Niger",
		},
	},
	NG: {
		id: 151,
		alpha2: "NG",
		localized_names: {
			en: "Nigeria",
		},
	},
	NU: {
		id: 152,
		alpha2: "NU",
		localized_names: {
			en: "Niue",
		},
	},
	NF: {
		id: 153,
		alpha2: "NF",
		localized_names: {
			en: "Norfolk Island",
		},
	},
	MP: {
		id: 154,
		alpha2: "MP",
		localized_names: {
			en: "Northern Mariana Islands",
		},
	},
	NO: {
		id: 155,
		alpha2: "NO",
		localized_names: {
			en: "Norway",
		},
	},
	OM: {
		id: 156,
		alpha2: "OM",
		localized_names: {
			en: "Oman",
		},
	},
	PK: {
		id: 157,
		alpha2: "PK",
		localized_names: {
			en: "Pakistan",
		},
	},
	PW: {
		id: 158,
		alpha2: "PW",
		localized_names: {
			en: "Palau",
		},
	},
	PS: {
		id: 159,
		alpha2: "PS",
		localized_names: {
			en: "Palestine, State of",
		},
	},
	PA: {
		id: 160,
		alpha2: "PA",
		localized_names: {
			en: "Panama",
		},
	},
	PG: {
		id: 161,
		alpha2: "PG",
		localized_names: {
			en: "Papua New Guinea",
		},
	},
	PY: {
		id: 162,
		alpha2: "PY",
		localized_names: {
			en: "Paraguay",
		},
	},
	PE: {
		id: 163,
		alpha2: "PE",
		localized_names: {
			en: "Peru",
		},
	},
	PH: {
		id: 164,
		alpha2: "PH",
		localized_names: {
			en: "Philippines",
		},
	},
	PN: {
		id: 165,
		alpha2: "PN",
		localized_names: {
			en: "Pitcairn",
		},
	},
	PL: {
		id: 166,
		alpha2: "PL",
		localized_names: {
			en: "Poland",
		},
	},
	PT: {
		id: 167,
		alpha2: "PT",
		localized_names: {
			en: "Portugal",
		},
	},
	QA: {
		id: 168,
		alpha2: "QA",
		localized_names: {
			en: "Qatar",
		},
	},
	RE: {
		id: 169,
		alpha2: "RE",
		localized_names: {
			en: "Réunion",
		},
	},
	RO: {
		id: 170,
		alpha2: "RO",
		localized_names: {
			en: "Romania",
		},
	},
	RU: {
		id: 171,
		alpha2: "RU",
		localized_names: {
			en: "Russian Federation",
		},
	},
	RW: {
		id: 172,
		alpha2: "RW",
		localized_names: {
			en: "Rwanda",
		},
	},
	BL: {
		id: 173,
		alpha2: "BL",
		localized_names: {
			en: "Saint Barthélemy",
		},
	},
	SH: {
		id: 174,
		alpha2: "SH",
		localized_names: {
			en: "Saint Helena, Ascension and Tristan da Cunha",
		},
	},
	KN: {
		id: 175,
		alpha2: "KN",
		localized_names: {
			en: "Saint Kitts and Nevis",
		},
	},
	LC: {
		id: 176,
		alpha2: "LC",
		localized_names: {
			en: "Saint Lucia",
		},
	},
	MF: {
		id: 177,
		alpha2: "MF",
		localized_names: {
			en: "Saint Martin (French part)",
		},
	},
	PM: {
		id: 178,
		alpha2: "PM",
		localized_names: {
			en: "Saint Pierre and Miquelon",
		},
	},
	VC: {
		id: 179,
		alpha2: "VC",
		localized_names: {
			en: "Saint Vincent and the Grenadines",
		},
	},
	WS: {
		id: 180,
		alpha2: "WS",
		localized_names: {
			en: "Samoa",
		},
	},
	SM: {
		id: 181,
		alpha2: "SM",
		localized_names: {
			en: "San Marino",
		},
	},
	ST: {
		id: 182,
		alpha2: "ST",
		localized_names: {
			en: "Sao Tome and Principe",
		},
	},
	SA: {
		id: 183,
		alpha2: "SA",
		localized_names: {
			en: "Saudi Arabia",
		},
	},
	SN: {
		id: 184,
		alpha2: "SN",
		localized_names: {
			en: "Senegal",
		},
	},
	RS: {
		id: 185,
		alpha2: "RS",
		localized_names: {
			en: "Serbia",
		},
	},
	SC: {
		id: 186,
		alpha2: "SC",
		localized_names: {
			en: "Seychelles",
		},
	},
	SL: {
		id: 187,
		alpha2: "SL",
		localized_names: {
			en: "Sierra Leone",
		},
	},
	SG: {
		id: 188,
		alpha2: "SG",
		localized_names: {
			en: "Singapore",
		},
	},
	SK: {
		id: 189,
		alpha2: "SK",
		localized_names: {
			en: "Slovakia",
		},
	},
	SI: {
		id: 190,
		alpha2: "SI",
		localized_names: {
			en: "Slovenia",
		},
	},
	SB: {
		id: 191,
		alpha2: "SB",
		localized_names: {
			en: "Solomon Islands",
		},
	},
	ZA: {
		id: 192,
		alpha2: "ZA",
		localized_names: {
			en: "South Africa",
		},
	},
	GS: {
		id: 193,
		alpha2: "GS",
		localized_names: {
			en: "South Georgia and the South Sandwich Islands",
		},
	},
	ES: {
		id: 194,
		alpha2: "ES",
		localized_names: {
			en: "Spain",
		},
	},
	LK: {
		id: 195,
		alpha2: "LK",
		localized_names: {
			en: "Sri Lanka",
		},
	},
	SR: {
		id: 197,
		alpha2: "SR",
		localized_names: {
			en: "Suriname",
		},
	},
	SJ: {
		id: 198,
		alpha2: "SJ",
		localized_names: {
			en: "Svalbard and Jan Mayen",
		},
	},
	SZ: {
		id: 199,
		alpha2: "SZ",
		localized_names: {
			en: "Swaziland",
		},
	},
	SE: {
		id: 200,
		alpha2: "SE",
		localized_names: {
			en: "Sweden",
		},
	},
	CH: {
		id: 201,
		alpha2: "CH",
		localized_names: {
			en: "Switzerland",
		},
	},
	TW: {
		id: 202,
		alpha2: "TW",
		localized_names: {
			en: "Taiwan",
		},
	},
	TJ: {
		id: 203,
		alpha2: "TJ",
		localized_names: {
			en: "Tajikistan",
		},
	},
	TZ: {
		id: 204,
		alpha2: "TZ",
		localized_names: {
			en: "Tanzania",
		},
	},
	TH: {
		id: 205,
		alpha2: "TH",
		localized_names: {
			en: "Thailand",
		},
	},
	TL: {
		id: 206,
		alpha2: "TL",
		localized_names: {
			en: "Timor-Leste",
		},
	},
	TG: {
		id: 207,
		alpha2: "TG",
		localized_names: {
			en: "Togo",
		},
	},
	TK: {
		id: 208,
		alpha2: "TK",
		localized_names: {
			en: "Tokelau",
		},
	},
	TO: {
		id: 209,
		alpha2: "TO",
		localized_names: {
			en: "Tonga",
		},
	},
	TT: {
		id: 210,
		alpha2: "TT",
		localized_names: {
			en: "Trinidad and Tobago",
		},
	},
	TN: {
		id: 211,
		alpha2: "TN",
		localized_names: {
			en: "Tunisia",
		},
	},
	TR: {
		id: 212,
		alpha2: "TR",
		localized_names: {
			en: "Turkey",
		},
	},
	TM: {
		id: 213,
		alpha2: "TM",
		localized_names: {
			en: "Turkmenistan",
		},
	},
	TC: {
		id: 214,
		alpha2: "TC",
		localized_names: {
			en: "Turks and Caicos Islands",
		},
	},
	TV: {
		id: 215,
		alpha2: "TV",
		localized_names: {
			en: "Tuvalu",
		},
	},
	UG: {
		id: 216,
		alpha2: "UG",
		localized_names: {
			en: "Uganda",
		},
	},
	UA: {
		id: 217,
		alpha2: "UA",
		localized_names: {
			en: "Ukraine",
		},
	},
	AE: {
		id: 218,
		alpha2: "AE",
		localized_names: {
			en: "United Arab Emirates",
		},
	},
	GB: {
		id: 219,
		alpha2: "GB",
		localized_names: {
			en: "United Kingdom",
		},
	},
	US: {
		id: 220,
		alpha2: "US",
		localized_names: {
			en: "United States",
		},
	},
	UY: {
		id: 222,
		alpha2: "UY",
		localized_names: {
			en: "Uruguay",
		},
	},
	UZ: {
		id: 223,
		alpha2: "UZ",
		localized_names: {
			en: "Uzbekistan",
		},
	},
	VU: {
		id: 224,
		alpha2: "VU",
		localized_names: {
			en: "Vanuatu",
		},
	},
	VE: {
		id: 225,
		alpha2: "VE",
		localized_names: {
			en: "Venezuela",
		},
	},
	VN: {
		id: 226,
		alpha2: "VN",
		localized_names: {
			en: "Vietnam",
		},
	},
	VG: {
		id: 227,
		alpha2: "VG",
		localized_names: {
			en: "Virgin Islands, British",
		},
	},
	VI: {
		id: 228,
		alpha2: "VI",
		localized_names: {
			en: "Virgin Islands, U.S.",
		},
	},
	WF: {
		id: 229,
		alpha2: "WF",
		localized_names: {
			en: "Wallis and Futuna",
		},
	},
	EH: {
		id: 230,
		alpha2: "EH",
		localized_names: {
			en: "Western Sahara",
		},
	},
	ZM: {
		id: 231,
		alpha2: "ZM",
		localized_names: {
			en: "Zambia",
		},
	},
	ZW: {
		id: 232,
		alpha2: "ZW",
		localized_names: {
			en: "Zimbabwe",
		},
	},
	AX: {
		id: 233,
		alpha2: "AX",
		localized_names: {
			en: "Åland Islands",
		},
	},
	CU: {
		id: 234,
		alpha2: "CU",
		localized_names: {
			en: "Cuba",
		},
	},
	IC: {
		id: 236,
		alpha2: "IC",
		localized_names: {
			en: "Canary Islands",
		},
	},
	PR: {
		id: 237,
		alpha2: "PR",
		localized_names: {
			en: "Puerto Rico",
		},
	},
} as const
