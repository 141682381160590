import type { ProductName } from "@artists/gql/design-challenges"

import {
	AnalyticsPageTitle,
	PRODUCT_CATEGORY_1,
	PRODUCT_CATEGORY_2,
	PRODUCT_CATEGORY_3,
	PRODUCT_NAME,
} from "../config"
import type { BaseEntry, ViewListAnalytics, ViewListProps } from "./types"

export const createViewListAnalytics = ({
	challenge,
	page,
	entries,
}: ViewListProps): ViewListAnalytics => {
	const {
		name,
		product: { name: productName },
		slug,
	} = challenge
	const { title: pageTitle } = page

	const productList = entries.map((entry, index) =>
		createListEntryAnalytics(entry, index, pageTitle, productName),
	)

	return {
		event: "view_item_list",
		ecommerce: {
			item_list_id: slug,
			item_list_name: name,
			items: productList,
			...(page.title !== AnalyticsPageTitle.ResultsPublic
				? {
						actionField: {
							totalQuantity: page.limit,
							pageNumber:
								Math.floor(page.offset / page.limit) + 1,
						},
					}
				: {}),
		},
	}
}

function createListEntryAnalytics(
	entry: BaseEntry,
	index: number,
	page: AnalyticsPageTitle,
	productName: ProductName,
): ViewListAnalytics["ecommerce"]["items"][0] {
	return {
		item_name: PRODUCT_NAME[productName],
		item_brand: entry.user.userName,
		currency: "USD",
		item_category: PRODUCT_CATEGORY_1[productName],
		...(PRODUCT_CATEGORY_2[productName]
			? { item_category2: PRODUCT_CATEGORY_2[productName] }
			: {}),
		...(PRODUCT_CATEGORY_3[productName]
			? { item_category3: PRODUCT_CATEGORY_3[productName] }
			: {}),
		design_id: entry.designId,
		index: index + 1,
		item_list_name: page,
	}
}
