import { useSnackbar } from "@artists/uikit"
import { useEffect } from "react"

import { Controls } from "./pwa.controls"
import { registerSW } from "./pwa.util"

export const usePWA = () => {
	const { show, close } = useSnackbar()

	useEffect(() => {
		if (IS_DEV) return

		const prompt = () =>
			new Promise<boolean>(resolve => {
				show("New version is available.", {
					preventDuplicate: true,
					persist: true,
					action: (
						<Controls
							onUpdate={() => {
								resolve(true)
								close()
							}}
							onCancel={() => resolve(false)}
						/>
					),
				})
			})

		registerSW(prompt)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}
