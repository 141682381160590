import { ApolloProvider } from "@apollo/client"
import { SnackbarProvider, ThemeProvider } from "@artists/uikit"
import { CacheProvider } from "@emotion/react"
import { posthog } from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { type FC, StrictMode } from "react"
import { CookiesProvider } from "react-cookie"
import { HelmetProvider } from "react-helmet-async"

import type { AppProps } from "../../../../../@common"
import { BrowserProvider, RedirectProvider } from "../../../../utils"

export const Providers: FC<Omit<AppProps, "nonce">> = ({
	cookies,
	browser,
	redirectContext,
	helmetContext,
	apolloClient,
	emotionCache,
	children,
}) => (
	<StrictMode>
		<CacheProvider value={emotionCache}>
			<ThemeProvider>
				<SnackbarProvider>
					<RedirectProvider context={redirectContext}>
						<HelmetProvider context={helmetContext}>
							<ApolloProvider client={apolloClient}>
								<CookiesProvider cookies={cookies}>
									<BrowserProvider browser={browser}>
										<PostHogProvider client={posthog}>
											{children}
										</PostHogProvider>
									</BrowserProvider>
								</CookiesProvider>
							</ApolloProvider>
						</HelmetProvider>
					</RedirectProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</CacheProvider>
	</StrictMode>
)
