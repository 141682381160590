import type { Dispatch } from "react"
import { useEffect } from "react"

import { NotificationDispatcher } from "./dispatcher"

export const useNotificatorDispatcher = (dispatch: Dispatch<string>) => {
	useEffect(() => {
		const unsubscribe =
			NotificationDispatcher.getInstance().subscribe(dispatch)

		return () => unsubscribe()
	}, [dispatch])
}
