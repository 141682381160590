import type { FC } from "react"
import { Navigate as Redirect } from "react-router-dom"
import { useIsomorphicLayoutEffect } from "react-use"

import { isExternalURL } from "./navigate.utils"

interface Props {
	url: string
}
export const Navigate: FC<Props> = ({ url }) => {
	const isExternal = isExternalURL(url)

	useIsomorphicLayoutEffect(() => {
		if (isExternal) {
			window.location.replace(url)
		}
	}, [isExternal, url])

	return isExternal ? null : <Redirect replace to={url} />
}
