import type { ChallengeStatusDetails } from "@artists/gql/design-challenges"
import { ChallengeStatusName } from "@artists/gql/design-challenges"
import type { TFunction } from "i18next"

import { format } from "../dates"

export const getStatus = (
	t: TFunction<"common">,
	status: ChallengeStatusDetails,
	f = "MMM d 'at' ha z",
) => {
	const { date, name } = status
	const statuses = {
		[ChallengeStatusName.Deactivated]: t("statuses.deactivated"),
		[ChallengeStatusName.EntriesClosed]: t("statuses.entriesClosed"),
		[ChallengeStatusName.EntriesOpen]: t("statuses.entriesOpen"),
		[ChallengeStatusName.ResultsFinal]: t("statuses.resultsFinal"),
		[ChallengeStatusName.ResultsPublic]: t("statuses.resultsPublic"),
		[ChallengeStatusName.Scheduled]: t("statuses.scheduled"),
		[ChallengeStatusName.VotingClosed]: t("statuses.votingClosed"),
		[ChallengeStatusName.VotingOpen]: t("statuses.votingOpen"),
	}

	return {
		label: statuses[name],
		time: format(new Date(date ?? Date.now()), f),
	}
}

export const isPublic = (status?: ChallengeStatusName | string) =>
	status === ChallengeStatusName.ResultsPublic

export const isVoting = (status?: ChallengeStatusName | string) =>
	status === ChallengeStatusName.VotingOpen
