import { A, O, pipe } from "@artists/sf-fp"
import type { Theme } from "@chakra-ui/react"
import {
	Box,
	Button,
	Flex,
	Grid,
	IconButton,
	List,
	useBreakpointValue,
	useDisclosure,
	useOutsideClick,
	useTheme,
} from "@chakra-ui/react"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import Close from "@mui/icons-material/Close"
import Menu from "@mui/icons-material/Menu"
import { matchSI } from "@simspace/matchers"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { Announcements } from "./components/announcements"
import { Logo } from "./components/logo"
import { RecentlyViewedLink } from "./components/recently-viewed-link"
import { RecursiveNavList } from "./components/recursive-nav-list"
import { stylesForRecursiveNavList } from "./components/recursive-nav-list-style-wrapper"
import { Search } from "./components/search"
import { UserNavigation } from "./components/user-navigation"
import type { HeaderProps } from "./domain/header"
import type { NavItemsKeys } from "./utils/nav-items"
import { navItemsMetaForest } from "./utils/nav-items"

// should trigger design-challenges build
export const getMaxWidth: (app: NonNullable<HeaderProps["app"]>) => string =
	matchSI({
		classic: () => "1294px",
		baerlauch: () => "1294px",
		artist: () => "1728px",
	})

export function HeaderUI({
	user,
	announcements = [],
	app = "artist",
	cartItems = 0,
	ssr = true,
	cartUrl = "https://cart.spoonflower.com",
	spoonflowerRedirectUrl = "https://www.spoonflower.com",
	recentlyViewedUrl = "https://recent.spoonflower.com",
	isNewCartHardcoded = false,
}: HeaderProps) {
	const theme: Theme = useTheme()
	const desktopValue = useBreakpointValue({ base: false, lg: true }, { ssr })
	const isDesktop = Boolean(desktopValue)
	const { t } = useTranslation(["header", "navigation", "search"])
	const [selectedItem, setSelectedItem] = useState<Array<string>>(["root"])
	const { isOpen: isDrawerOpen, onToggle: toggleDrawer } = useDisclosure()
	const {
		isOpen: isProfileOpenDesktop,
		onToggle: toggleProfileDesktop,
		onClose: closeProfileDesktop,
		onOpen: openProfileDesktop,
	} = useDisclosure()
	const {
		isOpen: isProfileOpenMobile,
		onToggle: toggleProfileMobile,
		onClose: closeProfileMobile,
		onOpen: openProfileMobile,
	} = useDisclosure()
	const profileRefDesktop = useRef<HTMLDivElement>(null)
	const profileRefMobile = useRef<HTMLDivElement>(null)
	const navRef = useRef<HTMLDivElement>(null)

	useOutsideClick({
		ref: profileRefDesktop,
		handler: closeProfileDesktop,
	})

	useOutsideClick({
		ref: profileRefMobile,
		handler: closeProfileMobile,
	})

	useOutsideClick({
		ref: navRef,
		handler: () => {
			if (isDrawerOpen) {
				toggleDrawer()
				setSelectedItem(["root"])
			}
		},
	})

	useEffect(() => {
		/**
		 * This is a common effect, to prevent scroll when there is a modal
		 * overlaying the entire app
		 *
		 * AR - 2023-02-14
		 */
		document.body.style.overflow = isDrawerOpen ? "hidden" : "auto"
	}, [isDrawerOpen])

	const maxWidth = getMaxWidth(app)

	return (
		<Box
			as='header'
			position='relative'
			height={{ base: "full", lg: "auto" }}
		>
			<AnimatePresence>
				{!isDesktop && isDrawerOpen && (
					<motion.div
						aria-hidden={true}
						style={{
							position: "fixed",
							top: 0,
							left: 0,
							width: "100vw",
							height: "100vh",
							background: theme.colors.blackAlpha[600],
						}}
						initial={{ opacity: 0, zIndex: 2 }}
						animate={{
							opacity: 1,
							zIndex: 3,
						}}
						exit={{ opacity: 0, zIndex: 2 }}
						transition={{ ease: "easeIn", duration: 0.25 }}
					/>
				)}
			</AnimatePresence>
			<Box bgColor='gray.50'>
				<Grid
					templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
					justifyItems='center'
					alignItems='center'
					maxWidth={{
						lg: maxWidth,
					}}
					px={{ xs: 1, sm: 6, md: 8, lg: 8, xl: 10 }}
					margin='0 auto'
				>
					<Announcements announcements={announcements} />
					<UserNavigation
						user={user}
						isProfileOpen={isProfileOpenDesktop}
						profileRef={profileRefDesktop}
						toggleProfile={toggleProfileDesktop}
						openProfile={openProfileDesktop}
						closeProfile={closeProfileDesktop}
						styles={{ display: { base: "none", lg: "flex" } }}
						cartItems={cartItems}
						cartUrl={cartUrl}
						spoonflowerRedirectUrl={spoonflowerRedirectUrl}
						isNewCartHardcoded={isNewCartHardcoded}
					/>
				</Grid>
			</Box>

			<Flex
				alignItems='center'
				justifyContent='space-between'
				maxWidth={{ lg: maxWidth }}
				margin='0 auto'
				height={{ lg: 20 }}
				px={{ base: 3, md: 8, lg: 8, xl: 10 }}
				gap={20}
			>
				<Logo
					styleProps={{
						display: { base: "none", lg: "inline-block" },
					}}
					spoonflowerRedirectUrl={spoonflowerRedirectUrl}
				/>

				<Search
					styleProps={{ display: { base: "none", lg: "block" } }}
					spoonflowerRedirectUrl={spoonflowerRedirectUrl}
				/>
				{recentlyViewedUrl.includes("stage") && (
					<RecentlyViewedLink
						url={recentlyViewedUrl}
						styles={{
							display: { base: "none", lg: "inline" },
						}}
					/>
				)}
			</Flex>

			<Box
				display={{ base: "flex", lg: "block" }}
				justifyContent={{ base: "space-between", lg: "none" }}
				px={{ base: 3.5, md: 8, lg: 0 }}
				py={{ base: 2.5, lg: 0 }}
			>
				<Flex alignItems='center'>
					<IconButton
						aria-label='open navigation menu'
						icon={<Menu />}
						data-testid='hamburger-menu'
						display={{
							base: "block",
							lg: "none",
						}}
						onClick={toggleDrawer}
						variant='unstyled'
					/>
					<Logo
						styleProps={{
							display: { base: "inline-block", lg: "none" },
							ml: { base: 2, lg: 0 },
						}}
						spoonflowerRedirectUrl={spoonflowerRedirectUrl}
					/>
				</Flex>
				<Box
					borderTop={{
						base: "none",
						lg: `1px solid ${theme.colors.gray[300]}`,
					}}
					borderBottom={{
						base: "none",
						lg: `1px solid ${theme.colors.gray[300]}`,
					}}
				>
					<Box
						className={
							isDrawerOpen ? "drawer-opened" : "drawer-closed"
						}
						ref={navRef}
						as='nav'
						aria-label='main menu'
						aria-hidden={!isDrawerOpen}
						overflowY={{ base: "auto", lg: "inherit" }}
						overflowX={{ base: "hidden", lg: "inherit" }}
						height={{ base: "100vh", lg: "auto" }}
						position={{ base: "absolute", lg: "relative" }}
						left={{ base: -80, lg: 0 }}
						display='flex'
						flexDirection='column'
						transform={{
							base: isDrawerOpen
								? `translateX(100%)`
								: `translateX(0%)`,
							lg: "none",
						}}
						transition={{
							base: "transform 250ms ease-in-out",
							lg: "none",
						}}
						top={{ base: "0px" }}
						bgColor='white'
						maxWidth={{ lg: maxWidth }}
						margin='0 auto'
						zIndex={3}
						px={{ base: 0, lg: 8, xl: 10 }}
					>
						<Box
							display={{
								base:
									selectedItem.length > 1 ? "block" : "none",
								lg: "none",
							}}
						>
							<Flex
								alignItems='center'
								justifyContent='space-between'
								px={5}
								py={3}
							>
								<Button
									variant='unstyled'
									display='flex'
									alignItems='center'
									size='sm'
									leftIcon={<ChevronLeft fontSize='small' />}
									onClick={() => {
										setSelectedItem(s =>
											pipe(s, A.takeLeft(s.length - 1)),
										)
										if (navRef?.current) {
											navRef.current.scrollTop = 0
										}
									}}
								>
									Back
								</Button>
								<IconButton
									aria-label='close menu'
									size='sm'
									icon={<Close />}
									variant='unstyled'
									onClick={() => {
										toggleDrawer()
										setSelectedItem(["root"])
									}}
									data-testid='close-button-nested'
								/>
							</Flex>
							<Flex
								backgroundColor='gray.800'
								textTransform='capitalize'
								color='white'
								p={5}
								alignItems='center'
							>
								<Box fontSize='lg'>
									{pipe(
										selectedItem,
										A.last,
										O.fold(
											() => "",
											v =>
												/**
												 * Update with decoder to ensure
												 * these are NavItemsKeys
												 *
												 * Ticket:
												 * https://spoonflower.atlassian.net/browse/SP-15357
												 *
												 * AR - 2023-02-16
												 */
												t(
													`navigation:${
														v as NavItemsKeys
													}.text`,
												),
										),
									)}
								</Box>
							</Flex>
						</Box>
						<Flex
							alignItems='center'
							justifyContent='space-between'
							px={5}
							py={3}
							display={{
								base: selectedItem.length < 2 ? "flex" : "none",
								lg: "none",
							}}
						>
							{pipe(
								user,
								O.fold(
									() => <Box>{t("navigation:welcome")}</Box>,
									u => <Box>{u.username}</Box>,
								),
							)}
							<IconButton
								aria-label='close menu'
								size='sm'
								icon={<Close />}
								variant='unstyled'
								onClick={() => {
									toggleDrawer()
									setSelectedItem(["root"])
								}}
								data-testid='close-button'
							/>
						</Flex>
						<List
							display='flex'
							flexDirection={{ base: "column", lg: "row" }}
							height={{ base: "auto", lg: "full" }}
							overflowY={{ base: "auto", lg: "inherit" }}
							flex={1}
							alignItems={{ base: "left", lg: "center" }}
							position={{ base: "relative" }}
							width={{ base: 80, lg: "full" }}
							justifyContent={{
								base: "flex-start",
								lg: "space-between",
							}}
							sx={stylesForRecursiveNavList}
							bgColor={{ base: "white" }}
						>
							<RecursiveNavList
								navList={navItemsMetaForest}
								selectedItem={selectedItem}
								setSelectedItem={setSelectedItem}
								depth={1}
								user={user}
								isDesktop={isDesktop}
								navRef={navRef}
								spoonflowerRedirectUrl={spoonflowerRedirectUrl}
								recentlyViewedUrl={recentlyViewedUrl}
							/>
						</List>
					</Box>
				</Box>
				<UserNavigation
					user={user}
					isProfileOpen={isProfileOpenMobile}
					profileRef={profileRefMobile}
					toggleProfile={toggleProfileMobile}
					openProfile={openProfileMobile}
					closeProfile={closeProfileMobile}
					styles={{ display: { base: "flex", lg: "none" } }}
					cartItems={cartItems}
					cartUrl={cartUrl}
					spoonflowerRedirectUrl={spoonflowerRedirectUrl}
					isNewCartHardcoded={isNewCartHardcoded}
				/>
			</Box>
			<Box
				px={{ base: 5, md: 8, lg: 8, xl: 10 }}
				display={{ base: "block", lg: "none" }}
			>
				<Search
					spoonflowerRedirectUrl={spoonflowerRedirectUrl}
					searchSuffix={"mobile"}
				/>
			</Box>
		</Box>
	)
}

export default HeaderUI
