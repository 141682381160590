import { useMemo } from "react"

import { getENV } from "../../../../@common"

export const useAnalytics = () => {
	const sendAnalytics = getENV("SEND_ANALYTICS")

	return useMemo(
		() => ({
			send(data: object) {
				if (!sendAnalytics || IS_SERVER || IS_SPA) return
				if (!window.dataLayer) throw new Error("Failed to setup GTM")

				window.dataLayer.push(data)
			},
		}),
		[sendAnalytics],
	)
}
