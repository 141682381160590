import { Box } from "@mui/material"
import { styled } from "@mui/system"

export const Container = styled(Box)(({ theme }) =>
	theme.unstable_sx({
		gap: 2,
		m: "auto",
		display: "grid",
		height: "auto",
		alignItems: "center",
		placeContent: "center",
		gridTemplateColumns: { sm: "150px 1fr" },
	}),
)
export const Icon = styled(Box)(({ theme }) =>
	theme.unstable_sx({
		m: "auto",
		width: "100%",
		fontSize: 100,
		maxWidth: 150,
		bgcolor: "#e2e2e2",
		display: "grid",
		aspectRatio: "1",
		borderRadius: "3px",
		placeContent: "center",
		color: "text.secondary",
	}),
)
