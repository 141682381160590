import { ProductName } from "@artists/gql/design-challenges"

export const PRODUCT_NAME: Collection<ProductName, string> = {
	[ProductName.TeaTowel]: "Tea Towel",
	[ProductName.SheetSet]: "Sheet Set",
	[ProductName.SheetSetTopView]: "Sheet Set",
	[ProductName.Tablecloth]: "Rectangular Tablecloth",
	[ProductName.SquareThrowPillow]: "Square Throw Pillow Cover",
	[ProductName.Wallpaper]: "Prepasted Removable Smooth",
	[ProductName.WallpaperSilverMetallic]: "Prepasted Removable Smooth",
	[ProductName.WallpaperGoldMetallic]: "Prepasted Removable Smooth",
	[ProductName.Duvet]: "Duvet Cover",
	[ProductName.DuvetTopView]: "Duvet Cover",
	[ProductName.Curtains]: "Curtain Panel",
	[ProductName.TableRunner]: "Table Runner",
	[ProductName.WallHanging]: "Wall Hanging",
	[ProductName.FabricYard]: "Petal Signature Cotton",
	[ProductName.FabricSwatch]: "Petal Signature Cotton",
	[ProductName.FabricFatQuarter]: "Petal Signature Cotton",
	[ProductName.FabricCrop]: "Petal Signature Cotton",
} as const

export const PRODUCT_CATEGORY_1: Collection<ProductName, string> = {
	[ProductName.TeaTowel]: "Home Good",
	[ProductName.SheetSet]: "Home Good",
	[ProductName.SheetSetTopView]: "Home Good",
	[ProductName.Tablecloth]: "Home Good",
	[ProductName.SquareThrowPillow]: "Home Good",
	[ProductName.Wallpaper]: "Wallpaper",
	[ProductName.WallpaperSilverMetallic]: "Wallpaper",
	[ProductName.WallpaperGoldMetallic]: "Wallpaper",
	[ProductName.Duvet]: "Home Good",
	[ProductName.DuvetTopView]: "Home Good",
	[ProductName.Curtains]: "Home Good",
	[ProductName.TableRunner]: "Home Good",
	[ProductName.WallHanging]: "Home Good",
	[ProductName.FabricYard]: "Fabric",
	[ProductName.FabricSwatch]: "Fabric",
	[ProductName.FabricFatQuarter]: "Fabric",
	[ProductName.FabricCrop]: "Fabric",
} as const

export const PRODUCT_CATEGORY_2: Partial<Collection<ProductName, string>> = {
	[ProductName.TeaTowel]: "Dining",
	[ProductName.SheetSet]: "Bedding",
	[ProductName.SheetSetTopView]: "Bedding",
	[ProductName.Tablecloth]: "Dining",
	[ProductName.SquareThrowPillow]: "Living",
	[ProductName.Duvet]: "Bedding",
	[ProductName.DuvetTopView]: "Bedding",
	[ProductName.Curtains]: "Living",
	[ProductName.TableRunner]: "Dining",
	[ProductName.WallHanging]: "Living",
} as const

export const PRODUCT_CATEGORY_3: Partial<Collection<ProductName, string>> = {
	[ProductName.TeaTowel]: "Tea Towel",
	[ProductName.SheetSet]: "Sheet Set",
	[ProductName.SheetSetTopView]: "Sheet Set",
	[ProductName.Tablecloth]: "Rectangular Tablecloth",
	[ProductName.SquareThrowPillow]: "Square Throw Pillow Cover",
	[ProductName.Duvet]: "Duvet Cover",
	[ProductName.DuvetTopView]: "Duvet Cover",
	[ProductName.Curtains]: "Curtain Panel",
	[ProductName.TableRunner]: "Table Runner",
	[ProductName.WallHanging]: "Wall Hanging",
} as const

export const PRODUCT_DEPARTMENT: Collection<ProductName, string> = {
	[ProductName.TeaTowel]: "Home Good",
	[ProductName.SheetSet]: "Home Good",
	[ProductName.SheetSetTopView]: "Home Good",
	[ProductName.Tablecloth]: "Home Good",
	[ProductName.SquareThrowPillow]: "Home Good",
	[ProductName.Wallpaper]: "Wallpaper",
	[ProductName.WallpaperSilverMetallic]: "Wallpaper",
	[ProductName.WallpaperGoldMetallic]: "Wallpaper",
	[ProductName.Duvet]: "Home Good",
	[ProductName.DuvetTopView]: "Home Good",
	[ProductName.Curtains]: "Home Good",
	[ProductName.TableRunner]: "Home Good",
	[ProductName.WallHanging]: "Home Good",
	[ProductName.FabricYard]: "Fabric",
	[ProductName.FabricSwatch]: "Fabric",
	[ProductName.FabricFatQuarter]: "Fabric",
	[ProductName.FabricCrop]: "Fabric",
} as const

export enum AnalyticsPageTitle {
	Vote = "Design Challenges: Vote",
	ResultsPublic = "Design Challenges: Results Public",
}
