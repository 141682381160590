import { defineStyleConfig } from "@chakra-ui/react"

export default defineStyleConfig({
	variants: {
		tooltip: {
			content: {
				color: "white",
				background: "gray.700",
				textAlign: "center",
				fontSize: "14px",
				border: "none",
			},
		},
	},
})
