export enum ErrorEnum {
	Unauthenticated = "UNAUTHENTICATED",
	JwtExpired = "JWT_EXPIRED",
	GraphQLError = "GRAPHQL_ERROR",
	NetworkError = "NETWORK_ERROR",
}

export const DEFAULT_GRAPHQL_ERROR_MESSAGE = "Request failed."

export const APP_ERROR_MAP: Record<string, string> = {
	[ErrorEnum.Unauthenticated]: "Authentication error",
	[ErrorEnum.GraphQLError]: DEFAULT_GRAPHQL_ERROR_MESSAGE,
	[ErrorEnum.NetworkError]: "Network error. Check network connection.",
}
