import { InMemoryCache } from "@apollo/client"

export const getApolloCache = () =>
	new InMemoryCache({
		possibleTypes: {
			Partner: ["ChallengePartnerWithPhoto", "ChallengePartnerWithLogo"],
		},
		typePolicies: {
			ChallengeStatusObject: {
				merge: true,
			},
			VotingStats: {
				keyFields: [],
			},
			UserInfo: {
				keyFields: ["userId"],
			},
			BaseUser: {
				keyFields: ["userId"],
			},
			Product: {
				keyFields: ["name"],
			},
		},
	})
