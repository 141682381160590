import { Navigate } from "@artists/uikit"
import type { FC } from "react"

import { basePath } from "../../../@common"
import { useRedirect } from "./redirect.hooks"

interface BaseProps {
	status?: number
}

type Props =
	| ({ to: string; href?: never } & BaseProps)
	| ({ to?: never; href: string } & BaseProps)

export const Redirect: FC<Props> = ({ to, href, status }) => {
	const { context } = useRedirect()

	context.url = href ?? basePath(to)
	context.status = status

	return IS_SERVER ? null : <Navigate url={href ?? to} />
}
