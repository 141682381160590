import { useTheme } from "@artists/uikit"
import { type Breakpoint, useMediaQuery } from "@mui/material"

export function useWidth() {
	const { theme } = useTheme()
	const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse()

	return (
		keys.reduce((output: Nullable<Breakpoint>, key: Breakpoint) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const matches = useMediaQuery(theme.breakpoints.up(key))
			return !output && matches ? key : output
		}, null) || "xs"
	)
}
