/* eslint-disable */ 
// @ts-nocheck
import * as Types from '../schema.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DiscardVotesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DiscardVotesMutation = { __typename?: 'Mutation', discardVotes: boolean };


export const DiscardVotesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DiscardVotes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"discardVotes"}}]}}]} as unknown as DocumentNode<DiscardVotesMutation, DiscardVotesMutationVariables>;