import accordionTheme from "./accordion"
import avatarTheme from "./avatar"
import buttonTheme from "./button"
import cardTheme from "./card"
import checkboxTheme from "./checkbox"
import headingTheme from "./heading"
import { inputTheme } from "./input"
import linkTheme from "./link"
import { modalTheme } from "./modal"
import popoverTheme from "./popover"
import radioTheme from "./radio"
import { sliderTheme } from "./slider"

/**
 * For components that expose a theme API, create a file for that component
 * inside of this directory. In that file, use the API that the component
 * exposes by clicking on the `View Theme Source` button of the component you
 * are viewing in the Chakra docs. Import that file into here in order to
 * overwrite the default values for that component's theme.
 */
export const components = {
	Accordion: accordionTheme,
	Avatar: avatarTheme,
	Button: buttonTheme,
	Card: cardTheme,
	Checkbox: checkboxTheme,
	Heading: headingTheme,
	Modal: modalTheme,
	Link: linkTheme,
	Popover: popoverTheme,
	Radio: radioTheme,
	Slider: sliderTheme,
	Input: inputTheme,
}
