import type { Challenge, ChallengeStatus } from "@artists/gql/design-challenges"
import { ChallengeStatusName } from "@artists/gql/design-challenges"
import { Trans, useTranslation } from "react-i18next"

import type { NotFoundProps } from "../../components/not-found"
import { format } from "../dates"
import { Root } from "../routes"

type MinimalChallenge = Pick<Challenge, "name" | "slug" | "votingClosesAt"> & {
	status: Pick<ChallengeStatus, "current">
}

export function useNoVoting(
	challenge: Maybe<MinimalChallenge>,
): NotFoundProps | null {
	const { t } = useTranslation("common")

	if (!challenge) {
		return null
	}

	const {
		votingClosesAt,
		status: {
			current: { name: status },
		},
		name,
		slug,
	} = challenge

	const isBeforeVotingStarted = [
		ChallengeStatusName.EntriesOpen,
		ChallengeStatusName.EntriesClosed,
	].includes(status)

	if (status === ChallengeStatusName.VotingOpen) {
		return null
	}

	return {
		buttonText: t("goTo.challenge"),
		to: Root.Challenge.buildPath({ slug }),
		state: Root.Challenge.buildState({ status }),
		title: (
			<Trans
				ns='common'
				i18nKey={`${
					isBeforeVotingStarted
						? "errors.votingNotYetOpen"
						: "errors.votingClosed"
				}`}
				values={{
					challengeName: name,
					date: format(votingClosesAt, "eee, MM/dd/y"),
					time: format(votingClosesAt, "ha z"),
				}}
			>
				<em />
			</Trans>
		),
	}
}
