import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"

const baseStyle = defineStyle({
	transitionProperty: "common",
	transitionDuration: "fast",
	transitionTimingFunction: "ease-out",
	cursor: "pointer",
	textDecoration: "underline",
	outline: "none",
	color: "teal.500",
	_hover: {
		textDecoration: "underline",
	},
	_focusVisible: {
		boxShadow: "outline",
	},
})

const userNavigation = defineStyle({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	fontSize: "sm",
	borderRadius: "md",
	padding: 2.5,
	lineHeight: 5,
	color: "gray.800",
	textDecoration: "none",
	_hover: {
		color: "gray.900",
		bg: "gray.200",
		textDecoration: "underline",
	},
	_active: {
		color: "gray.900",
		bg: "gray.300",
		textDecoration: "underline",
	},
	_focus: {
		color: "gray.900",
		bg: "gray.300",
		textDecoration: "underline",
	},
})

const admin = defineStyle({
	fontSize: "sm",
	bgColor: "teal.500",
	py: 0,
	px: 2,
	color: "white",
	border: "1px solid transparent",
	borderRadius: "md",
	textDecoration: "none",
	_hover: {
		bg: "teal.600",
		textDecoration: "none",
	},
	_active: {
		bg: "teal.700",
	},
	_focus: {
		bg: "teal.700",
	},
})

const header = defineStyle({
	color: "gray.800",
	textDecoration: "none",
	_hover: {
		textDecoration: "underline",
	},
	_focus: {
		textDecoration: "underline",
	},
	_active: {
		textDecoration: "underline",
	},
})

const footer = defineStyle({
	color: "gray.800",
	textDecoration: "none",
})

const externalButton = defineStyle({
	bgColor: "white",
	fontSize: "sm",
	fontWeight: "semibold",
	textDecoration: "none",
	color: "external-link-button-color",
	borderStyle: "solid",
	borderWidth: "1px",
	borderColor: "granite.600",
	borderRadius: "md",
	display: "flex",
	alignItems: "center",
	px: 1.5,
	py: 0.5,
	gap: 1,
})

const goToEditPage = defineStyle({
	display: "inline-block",
	borderWidth: "1px",
	borderStyle: "solid",
	borderColor: "granite.600",
	borderRadius: "md",
	fontSize: "sm",
	color: "granite.600",
	fontWeight: "semibold",
	px: 3,
	py: 1,
	height: 8,
	textDecoration: "none",
	_hover: {
		textDecoration: "none",
		bgColor: "granite.100",
	},
})

const linkOutlineButton = defineStyle({
	borderWidth: "1px",
	borderStyle: "solid",
	borderColor: "granite.600",
	borderRadius: "md",
	color: "granite.800",
	px: 5,
	py: 2,
	textDecoration: "none",
	_hover: {
		textDecoration: "none",
		bgColor: "granite.100",
	},
})

const variants = {
	"user-navigation": userNavigation,
	admin,
	header,
	footer,
	"external-button": externalButton,
	"go-to-edit-page": goToEditPage,
	"link-outline-button": linkOutlineButton,
}

export default defineStyleConfig({
	baseStyle,
	variants,
})
