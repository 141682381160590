import type { ApolloError } from "@apollo/client"
import type { ErrorResponse } from "@apollo/client/link/error"

import {
	APP_ERROR_MAP,
	DEFAULT_GRAPHQL_ERROR_MESSAGE,
	ErrorEnum,
} from "./config"
import type { Exception } from "./types"
import { getGraphQlErrors, hasNetworkErrorOnly } from "./utils"

export function getErrorCodes(error: ErrorResponse | ApolloError) {
	return hasNetworkErrorOnly(error)
		? [ErrorEnum.NetworkError]
		: [...new Set(getGraphQlErrors(error))].map(
				({ extensions }) =>
					String(extensions?.code) ?? ErrorEnum.GraphQLError,
			)
}

export function getErrorMessages(
	error: ErrorResponse,
	customErrorMap?: Record<string, string>,
) {
	return getErrorCodes(error).map(code =>
		mapCodesToMessage(code, customErrorMap),
	)
}

export function mapCodesToMessage(
	code: string,
	customErrorMap?: Record<string, string>,
): string {
	const fullErrorMap = { ...APP_ERROR_MAP, ...customErrorMap }
	return fullErrorMap[code] ?? DEFAULT_GRAPHQL_ERROR_MESSAGE
}

export function logErrorsInNewRelic(error: ErrorResponse) {
	const { graphQLErrors, networkError } = error

	graphQLErrors?.forEach(
		({ message, locations, path, extensions: { exception } }) => {
			if (!exception) return

			const knownException = exception as Exception
			const msg = message ?? knownException.message
			const lct = locations ?? knownException.locations
			const pth = path ?? knownException.path

			window.newrelic?.noticeError(
				new Error(
					`[GraphQL error]: Message: ${msg}, Location: ${lct}, Path: ${pth}`,
				),
			)
		},
	)

	if (networkError) window.newrelic?.noticeError(networkError)
}
