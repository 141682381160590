import { defineStyleConfig } from "@chakra-ui/react"

export default defineStyleConfig({
	variants: {
		"big-badge": {
			container: {
				margin: 4,
			},
			badge: {
				border: "none",
				width: 14,
				margin: -4,
			},
		},
	},
})
