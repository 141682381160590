import type { ErrorResponse } from "@apollo/client/link/error"
import {
	getAuthUrl,
	getErrorCodes,
	logErrorsInNewRelic,
	mapCodesToMessage,
} from "@artists/devkit"
import { CustomErrorResponses } from "@artists/gql/design-challenges"

import { getENV } from "../../../env.util"
import { logger } from "../../../logger"
import { NotificationDispatcher } from "../../hooks"
import type { ApolloContext } from "../../types"
import { shouldSignIn } from "./auth.error"

export const APP_ERROR_MAP = {
	[CustomErrorResponses.Unauthenticated]: "Authentication error",
	[CustomErrorResponses.WrongDesignDate]:
		"Design was created after the announcement of the challenge",
	[CustomErrorResponses.WrongRepeat]: "Design has a wrong repeat pattern",
	[CustomErrorResponses.AlreadyActiveEntry]:
		"This design has been submitted to another challenge",
	[CustomErrorResponses.OtherDesignInChallenge]:
		"You have already submitted another design to the challenge",
	[CustomErrorResponses.WrongUser]: "This design belongs to another user",
	[CustomErrorResponses.AccessDenied]: "Access denied",
	[CustomErrorResponses.NotFound]: "Not found",
	[CustomErrorResponses.AlreadyExist]: "Entity already exists",
	[CustomErrorResponses.EmptyFields]: "Some of the required fields are empty",
	[CustomErrorResponses.InvalidActionType]:
		"The requested action requires a different set of fields",
	[CustomErrorResponses.VotingClosed]: "Voting is already closed",
}

export interface ApolloErrorContext {
	skipErrors?: string[]
}

export const errorHandler =
	(context?: ApolloContext) => (error: ErrorResponse) => {
		const allErrorCodes = getErrorCodes(error)

		if (IS_CLIENT) {
			const context = error.operation.getContext() as ApolloErrorContext
			const skippedErrors = context.skipErrors ?? []
			const codesToBeProcessed = allErrorCodes.filter(
				elem => !skippedErrors.includes(elem),
			)

			if (IS_PROD) logErrorsInNewRelic(error)

			if (shouldSignIn(codesToBeProcessed)) {
				window.location.href = getAuthUrl({
					host: getENV("SPOONFLOWER_HOST"),
					fallbackUrl: window.location.href,
				})
			} else {
				codesToBeProcessed
					.map(code => mapCodesToMessage(code, APP_ERROR_MAP))
					.forEach(message => {
						NotificationDispatcher.getInstance().dispatch(message)
					})
			}
		}

		logger.error("Apollo error-link error %o", error)

		if (context) {
			context.errorCodes = allErrorCodes
			context.statusCode = 500
		}
	}
