import { Link, type StyleProps } from "@chakra-ui/react"

interface RecentlyViewedProps {
	url: string
	styles?: StyleProps
}

export const RecentlyViewedLink = ({ url, styles }: RecentlyViewedProps) => (
	<Link
		href={url}
		color='gray.700'
		textDecor='none'
		px={{
			base: 5,
			lg: 0,
		}}
		py={{
			base: 2,
			lg: 0,
		}}
		fontSize={{
			base: "md",
			lg: "sm",
		}}
		_hover={{
			textDecor: { base: "none", lg: "underline" },
			bg: { base: "gray.50", lg: "none" },
		}}
		{...styles}
	>
		Recently Viewed
	</Link>
)
