import { locales } from "@artists/regional-preferences"
import { S } from "@artists/sf-fp"

import { getInstances } from "../utils/getInstances"

export const AnnouncementSchema = S.Struct({
	id: S.Number,
	url: S.String(),
	title: S.String(),
	message: S.String(),
	category: S.String(),
	sitewide: S.Boolean,
	language: S.Literal(...locales),
})

export type Announcement = S.TypeOf<typeof AnnouncementSchema>

export const AnnouncementsSchema = S.Array(AnnouncementSchema)

export type Announcements = S.TypeOf<typeof AnnouncementsSchema>

export const { Eq, Transcoder, Guard, TranscoderPar } =
	getInstances(AnnouncementSchema)
