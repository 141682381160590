import { defineStyleConfig } from "@chakra-ui/react"

export default defineStyleConfig({
	variants: {
		"design-file": {
			container: {
				boxShadow: "none",
				bg: "gray.100",
				borderRadius: "0",
			},
			body: {
				pb: 4,
			},
			footer: {
				paddingTop: "0px",
			},
		},
	},
})
