import joinPath from "url-join"

interface Props {
	host: string
	fallbackUrl?: string
	action?: "login" | "register" | "new_session"
}

export const getAuthUrl = ({ host, fallbackUrl, action = "login" }: Props) => {
	const fallback = fallbackUrl ? `?rd=${fallbackUrl}` : ""

	const actions = {
		login: "login",
		register: "register/new",
		new_session: "session/new",
	}

	return joinPath(host, actions[action], fallback)
}
