import breakpoints from "./breakpoints"
import colors from "./colors"
import layerStyles from "./layer-styles"
import sizes from "./sizes"
import textStyles from "./text-styles"
import typography from "./typography"

export const foundations = {
	breakpoints,
	colors,
	layerStyles,
	sizes,
	textStyles,
	...typography,
}
