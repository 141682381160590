import type { Theme } from "@chakra-ui/react"
import { Box, Flex, Link, useTheme } from "@chakra-ui/react"
import AddCircle from "@mui/icons-material/AddCircle"
import type { ReactNode } from "react"

export const UploadYourDesignCTA = ({
	children,
	spoonflowerRedirectUrl,
}: {
	children: ReactNode
	spoonflowerRedirectUrl: string
}) => {
	const theme: Theme = useTheme()
	return (
		<Link
			display='inline-block'
			href={`${spoonflowerRedirectUrl}/designs/new`}
			textDecoration='none'
			_hover={{ textDecoration: "none" }}
		>
			<Flex
				alignItems='center'
				bgColor='teal.600'
				color='white'
				px={{ base: 3 }}
				py={{ base: 2 }}
				mt={{ base: 3, md: 1 }}
				mr={{ base: 3, md: 3 }}
				mb={{ base: 3, md: 3 }}
				ml={{ base: 3, md: 3 }}
				borderRadius={4}
				fontSize='xs'
				textTransform='uppercase'
				flexShrink={0}
				gap={2}
				_hover={{
					backgroundColor: theme.colors.teal[700],
				}}
			>
				<AddCircle fontSize='inherit' /> <Box>{children}</Box>
			</Flex>
		</Link>
	)
}
