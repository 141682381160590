import type { CircularProgressProps } from "@mui/material"
import { Box, CircularProgress } from "@mui/material"
import type { FC } from "react"

export const Loader: FC<CircularProgressProps> = ({
	color = "secondary",
	...props
}) => (
	<Box display='grid' justifyContent='center' py={5}>
		<CircularProgress color={color} {...props} />
	</Box>
)
