import * as S from "schemata-ts/index"
import { deriveTranscoder } from "schemata-ts/Transcoder"

export const locales = ["en"] as const
export type Locale = (typeof locales)[number]
export const LocaleS = S.Literal(...locales)
export const localeDecoder = deriveTranscoder(LocaleS)

export const localeLanguage: Record<Locale, string> = {
	en: "English",
}

export const DEFAULT_LOCALE: Locale = "en"
