import { useTranslation } from "react-i18next"

import type { NotFoundProps } from "../../components/not-found"
import { Root } from "../routes"

export function useNoChallenge(noChallenge: boolean): NotFoundProps | null {
	const { t } = useTranslation("common")

	return noChallenge
		? {
				buttonText: t("goTo.home"),
				to: Root.Home.path,
				title: t("errors.noChallenge"),
			}
		: null
}
