/* eslint-disable */ 
// @ts-nocheck
import * as Types from '../schema.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateVotesMutationVariables = Types.Exact<{
  updateVotesDto: Types.ChallengeVotesInput;
}>;


export type UpdateVotesMutation = { __typename?: 'Mutation', voteForEntries: boolean };


export const UpdateVotesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateVotes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"updateVotesDto"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChallengeVotesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"voteForEntries"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"challengeVotesData"},"value":{"kind":"Variable","name":{"kind":"Name","value":"updateVotesDto"}}}]}]}}]} as unknown as DocumentNode<UpdateVotesMutation, UpdateVotesMutationVariables>;