import { defineStyleConfig } from "@chakra-ui/react"

export default defineStyleConfig({
	variants: {
		outline: {
			textDecoration: "none",
		},
		link: {
			lineHeight: "inherit",
			fontWeight: "inherit",
			transitionProperty: "common",
			transitionDuration: "fast",
			transitionTimingFunction: "ease-out",
			cursor: "pointer",
			textDecoration: "underline",
			outline: "none",
			color: "teal.500",
			_hover: {
				textDecoration: "underline",
			},
			_focusVisible: {
				boxShadow: "outline",
			},
		},
		/**
		 * This button variant is utilized to match parity with the legacy
		 * footer. Should not be used outside of the footer.
		 */
		"footer-policies-deprecated": {
			padding: 0,
			fontSize: "sm",
			fontWeight: "normal",
			height: "max-content",
			whiteSpace: "normal",
			textAlign: "left",
		},
		"flat-outline": {
			border: "solid 1px",
			borderColor: "gray.500",
			fontWeight: "normal",
			marginLeft: 2,
			_hover: {
				borderColor: "gray.700",
				background: "teal.200",
			},
		},
		"user-navigation": {
			display: "flex",
			padding: 2,
			color: "gray.800",
			_hover: {
				color: "gray.900",
				bg: "gray.200",
			},
			_active: {
				color: "gray.900",
				bg: "gray.300",
			},
			_focus: {
				color: "gray.900",
				bg: "gray.300",
			},
		},
	},
})
