import { HttpLink } from "@apollo/client"
import { fetch } from "cross-fetch"
import type { Request } from "express"

import { services } from "../../../../config/services"

export const httpLink = (req?: Request) => {
	const { "set-cookie": _, ...sanitizedHeaders } = req?.headers ?? {}

	return new HttpLink({
		fetch,
		headers: {
			...(req && { ...sanitizedHeaders, host: "" }),
		},
		uri: services.titanarum,
		credentials: "include",
	})
}
