export default {
	black: "#0F1415",
	white: "#FEFEFE",
	gray: {
		50: "#F1F7F8",
		100: "#EBF1F2",
		200: "#D2DDDF",
		300: "#BAC8CC",
		400: "#AAB5B8",
		500: "#61696B",
		600: "#424A4B",
		700: "#273133",
		800: "#212829",
		900: "#1B2527",
	},
	granite: {
		50: "#F1F7F8",
		100: "#EBF1F2",
		200: "#D2DDDF",
		300: "#BAC8CC",
		400: "#AAB5B8",
		500: "#61696B",
		600: "#424A4B",
		700: "#273133",
		800: "#212829",
		900: "#1B2527",
	},
	teal: {
		50: "#F4FEFC",
		100: "#EAFCF9",
		200: "#D4F9F3",
		300: "#ACF3ED",
		400: "#7CDCDB",
		500: "#267D8C",
		600: "#1B6378",
		700: "#134C64",
		800: "#0C3651",
		900: "#00394b",
	},
	gold: {
		50: "#FFFBEA",
		100: "#FEF4CB",
		200: "#FEE598",
		300: "#FDD265",
		400: "#F9A200",
		500: "#D68300",
		600: "#CA6800",
		700: "#B36700",
		800: "#904E00",
		900: "#773C00",
	},
	blue: {
		50: "#F5FCFF",
		100: "#EAF9FE",
		200: "#CAF0FD",
		300: "#38AAE1",
		400: "#007FCE",
		500: "#0062B1",
		600: "#004994",
		700: "#00407D",
		800: "#003477",
		900: "#002562",
	},
	green: {
		50: "#F4FDF0",
		100: "#E2FAD8",
		200: "#C0F6B3",
		300: "#91E688",
		400: "#28943B",
		500: "#1B7C35",
		600: "#00641D",
		700: "#11642F",
		800: "#0A532A",
		900: "#0D4B31",
	},
	red: {
		50: "#FFF7F4",
		100: "#FDE0D4",
		200: "#FCBBAA",
		300: "#F68C7E",
		400: "#ED605D",
		500: "#C21F38",
		600: "#B3112C",
		700: "#A21538",
		800: "#830D35",
		900: "#6C0832",
	},
	fuchsia: {
		50: "#F9F2F7",
		100: "#F3E4EF",
		200: "#E1BCD8",
		300: "#CD90BE",
		400: "#B866A5",
		500: "#982B82",
		600: "#8C267D",
		700: "#7C1F75",
		800: "#6D1B6C",
		900: "#53145C",
	},
	"gray-opacity": {
		50: "rgba(0, 0, 0, 0.04)",
		100: "rgba(0, 0, 0, 0.06)",
		200: "rgba(0, 0, 0, 0.08)",
		300: "rgba(0, 0, 0, 0.16)",
		400: "rgba(0, 0, 0, 0.24)",
		500: "rgba(0, 0, 0, 0.36)",
		600: "rgba(0, 0, 0, 0.48)",
		700: "rgba(0, 0, 0, 0.64)",
		800: "rgba(0, 0, 0, 0.80)",
		900: "rgba(0, 0, 0, 0.92)",
	},
	"white-opacity": {
		50: "rgba(255, 255, 255, 0.04)",
		100: "rgba(255, 255, 255, 0.06)",
		200: "rgba(255, 255, 255, 0.08)",
		300: "rgba(255, 255, 255, 0.16)",
		400: "rgba(255, 255, 255, 0.24)",
		500: "rgba(255, 255, 255, 0.36)",
		600: "rgba(255, 255, 255, 0.48)",
		700: "rgba(255, 255, 255, 0.64)",
		800: "rgba(255, 255, 255, 0.80)",
		900: "rgba(255, 255, 255, 0.92)",
	},
	facebook: "#1877F2",
	flickr: "#ff0084",
	instagram: "#ff0078",
	pinterest: "#d2242c",
	twitter: "#1D9BF0",
	youtube: "#ff0002",
	tiktok: "#fe2c55",
	"proofing-magenta": "#FF00C7",
	"cart-experience": "#5F9DA4",
	"cart-experience-hover": "#174F5F",
}
