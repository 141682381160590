import type { ProductName } from "@artists/gql/design-challenges"

import {
	PRODUCT_CATEGORY_1,
	PRODUCT_CATEGORY_2,
	PRODUCT_CATEGORY_3,
	PRODUCT_DEPARTMENT,
} from "../config"

interface ProductClickProps {
	userId: string | null
	pageTitle: string
	index: number
	designId: string
	authorName: string
	productName: ProductName
	link: string
}

export const createProductClickAnalytics = ({
	userId,
	pageTitle,
	index,
	designId,
	authorName,
	link,
	productName,
}: ProductClickProps) => ({
	event: "productClick",
	ecommerce: {
		currencyCode: "USD",
		userId,
		click: {
			actionField: {
				action: "click",
				list: pageTitle,
			},
			products: [
				{
					id: designId,
					brand: authorName,
					item_category: PRODUCT_CATEGORY_1[productName],
					...(PRODUCT_CATEGORY_2[productName]
						? { item_category2: PRODUCT_CATEGORY_2[productName] }
						: {}),
					...(PRODUCT_CATEGORY_3[productName]
						? { item_category3: PRODUCT_CATEGORY_3[productName] }
						: {}),
					department: PRODUCT_DEPARTMENT[productName],
					position: index + 1,
					product_link: link,
				},
			],
		},
	},
})
