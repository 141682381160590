import {
	ErrorOutlineRounded,
	InfoOutlined,
	ReportProblemOutlined,
	TaskAlt,
} from "@mui/icons-material"
import { SnackbarProvider as NoticeProvider } from "notistack"
import type { FC, ReactNode } from "react"

interface Props {
	children: ReactNode
}
export const SnackbarProvider: FC<Props> = ({ children }) => (
	<NoticeProvider
		iconVariant={{
			error: <ErrorOutlineRounded sx={{ mr: 1 }} />,
			warning: <ReportProblemOutlined sx={{ mr: 1 }} />,
			info: <InfoOutlined sx={{ mr: 1 }} />,
			success: <TaskAlt sx={{ mr: 1 }} />,
		}}
	>
		{children}
	</NoticeProvider>
)
