import {
	boolean,
	number,
	route,
	string,
} from "react-router-typesafe-routes/dom"

export const Root = route(
	"",
	{
		searchParams: {
			offset: number().default(0),
			search: string().default(""),
		},
	},
	{
		Home: route(""),
		Past: route("past"),
		Challenge: route(":slug", {
			state: { status: string() },
		}),
		Vote: route(":slug/vote", {
			searchParams: { entryId: string() },
			state: { hideWarning: boolean() },
		}),
		ReviewVotes: route(":slug/review-votes"),
		Me: route(
			"me",
			{},
			{ Entries: route("entries"), Voting: route("voting") },
		),
		Submission: route("submission/:designId"),
	},
)
