import { setContext } from "@apollo/client/link/context"
import type { Request } from "express"
import Cookies from "universal-cookie"

export const authLink = (req?: Request) =>
	setContext((_, { headers }) => {
		const cookies = new Cookies(req?.headers.cookie ?? null)

		return {
			headers: {
				...headers,
				"X-CSRF-TOKEN": cookies.get("csrf-token") ?? "",
			},
		}
	})
