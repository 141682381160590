import { CloseAction } from "@artists/uikit"
import { Button } from "@mui/material"
import { pink } from "@mui/material/colors"
import { alpha } from "@mui/material/styles"
import type { FC } from "react"

interface Props {
	onUpdate: () => void
	onCancel: () => void
}
export const Controls: FC<Props> = ({ onUpdate, onCancel }) => (
	<>
		<Button
			size='small'
			onClick={onUpdate}
			sx={{
				color: pink.A400,
				"&:hover": {
					backgroundColor: alpha(pink.A400, 0.08),
				},
			}}
		>
			Refresh
		</Button>

		<CloseAction onClick={onCancel} />
	</>
)
