import createCache from "@emotion/cache"

// prepend: true moves MUI styles to the top of the <head> so they're loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export const createEmotionCache = (nonce?: string) =>
	createCache({ nonce, key: "dc", prepend: true })

/** @issue https://github.com/emotion-js/emotion/issues/1105 */
export const disableEmotionWarnings = () => {
	if (!IS_SPA) return

	const log = console.error.bind(console)
	console.error = (...args) => {
		if (
			args.indexOf("The pseudo class") &&
			args.indexOf(
				"is potentially unsafe when doing server-side rendering.",
			)
		) {
			return
		}

		log(...args)
	}
}
