import {
	extendTheme,
	useTheme as useChakraTheme,
	withDefaultColorScheme,
} from "@chakra-ui/react"
import type {
	Theme as ChakraTheme,
	ThemeConfig,
	ThemeDirection,
} from "@chakra-ui/theme"

import { components } from "./components"
import { foundations } from "./foundations"
import { semanticTokens } from "./semantic-tokens"
import { styles } from "./styles"

const direction: ThemeDirection = "ltr"

const config: ThemeConfig = {
	useSystemColorMode: false,
	initialColorMode: "light",
	cssVarPrefix: "chakra",
}

/**
 * The theme is composed of:
 *
 * - SemanticTokens
 * - Direction
 * - ...Foundations
 * - Components
 * - Styles
 * - Config
 *
 *   https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/index.ts
 */

const extendedTheme = {
	semanticTokens,
	direction,
	...foundations,
	components,
	config,
	styles,
} as const

export type Theme = typeof extendedTheme & ChakraTheme

export const theme = extendTheme(
	extendedTheme,
	withDefaultColorScheme({ colorScheme: "teal" }),
) as Theme

export const useTheme = () => useChakraTheme<Theme>()
