import type { Locale } from "@artists/regional-preferences"
import { O, pipe, RA, tr } from "@artists/sf-fp"
import type { BoxProps, ListItemProps, Theme } from "@chakra-ui/react"
import {
	Box,
	Divider,
	Flex,
	IconButton,
	Link,
	List,
	ListItem,
	useTheme,
} from "@chakra-ui/react"
import Favorite from "@mui/icons-material/Favorite"
import Person from "@mui/icons-material/Person"
import ShoppingCart from "@mui/icons-material/ShoppingCart"
import { PostHogFeature } from "posthog-js/react"
import type { ReactNode, RefObject } from "react"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"

import type { HeaderProps } from "../domain/header"
import {
	adminRoute,
	cartRoute,
	favoritesRoute,
	joinRoute,
	loginRoute,
	makeProfileMenuHref,
	profileMenuItems,
	studioRoute,
} from "../utils/nav-items"
import { Indicator } from "./indicator"
import { UploadYourDesignCTA } from "./upload-design-cta"

const ProfileListItem = ({
	children,
	styles,
	href,
	user,
	id,
}: {
	children: ReactNode
	styles?: ListItemProps
	href: string
	user: HeaderProps["user"]
	id: string
}) => {
	const theme: Theme = useTheme()

	return (
		<ListItem
			role='presentation'
			display='flex'
			alignItems='center'
			_hover={{ backgroundColor: theme.colors.gray[50] }}
			{...styles}
		>
			<Link
				href={href}
				px={5}
				py={2.5}
				flex={1}
				height='full'
				display='flex'
				alignItems='center'
				textDecoration='none'
				color='grey.700'
			>
				{children}
				{pipe(
					user,
					O.filter(u => u.messages > 0 && id === "messages"),
					O.fold(
						() => undefined,
						u => <Indicator count={u.messages} ml={1} />,
					),
				)}
			</Link>
		</ListItem>
	)
}

const ProfileDivider = () => <Divider width='90%' margin='0 auto' />

interface UserNavigationProps {
	user: HeaderProps["user"]
	styles?: BoxProps
	profileRef: RefObject<HTMLDivElement>
	isProfileOpen: boolean
	toggleProfile: () => void
	openProfile: () => void
	closeProfile: () => void
	cartItems: number
	cartUrl: string
	spoonflowerRedirectUrl: string
	isNewCartHardcoded: boolean
}

export const UserNavigation = ({
	user,
	styles,
	profileRef,
	isProfileOpen,
	toggleProfile,
	openProfile,
	closeProfile,
	cartItems,
	cartUrl,
	spoonflowerRedirectUrl,
	isNewCartHardcoded,
}: UserNavigationProps) => {
	const { t, i18n } = useTranslation("navigation")
	const isAuthenticated = O.isSome(user)
	const theme: Theme = useTheme()

	return (
		<Flex
			alignItems='center'
			justifySelf='end'
			position='relative'
			role='presentation'
			gap={{ base: 0, sm: 1 }}
			{...styles}
		>
			{pipe(
				user,
				O.filter(u => u.isAdmin),
				O.fold(
					() => undefined,
					() => (
						<Link
							variant='admin'
							title='Go to admin'
							href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
								adminRoute,
							)({})}`}
						>
							Admin
						</Link>
					),
				),
			)}
			{isAuthenticated && (
				<Link
					variant='user-navigation'
					title={t("favorites.text")}
					href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
						favoritesRoute,
					)({
						route: { username: user.value.username },
					})}`}
				>
					<Favorite fontSize='small' />
				</Link>
			)}
			{!isAuthenticated && (
				<Flex gap={2.5} display={{ base: "none", lg: "flex" }}>
					<Link
						variant='user-navigation'
						href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
							loginRoute,
						)({})}`}
					>
						{t("logIn.text")}
					</Link>
					<Link
						variant='user-navigation'
						href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
							joinRoute,
						)({})}`}
					>
						{t("join.text")}
					</Link>
				</Flex>
			)}
			{isAuthenticated && (
				<Box ref={profileRef} key='profile-button'>
					<Box role='heading' display='flex' alignItems='center'>
						<IconButton
							id='profile'
							data-testid='profile-button'
							aria-label='profile'
							aria-expanded={isProfileOpen}
							variant='user-navigation'
							icon={<Person fontSize='medium' />}
							onClick={toggleProfile}
							onMouseEnter={() => {
								openProfile()
								;(document.activeElement as HTMLElement).blur()
							}}
							onMouseLeave={() => {
								closeProfile()
								;(document.activeElement as HTMLElement).blur()
							}}
						/>
						<Indicator
							count={user.value.messages}
							mt={-3}
							ml={-4}
						/>
					</Box>
					<Box
						role='region'
						aria-hidden={!isProfileOpen}
						aria-labelledby='profile'
						key='profile-menu'
					>
						<List
							data-testid='profile-menu'
							position='absolute'
							top={"39px"}
							right='0px'
							width={64}
							bgColor='white'
							border={`1px solid ${theme.colors.gray[200]}`}
							zIndex={4}
							sx={{
								"& li a:hover": {
									textDecoration: "none",
								},
							}}
							display={isProfileOpen ? "block" : "none"}
							fontSize='sm'
							color='gray.800'
							onMouseEnter={() => {
								openProfile()
								;(document.activeElement as HTMLElement).blur()
							}}
							onMouseLeave={() => {
								closeProfile()
								;(document.activeElement as HTMLElement).blur()
							}}
						>
							{pipe(
								profileMenuItems,
								RA.mapWithIndex((idx, i) =>
									match(i)
										.with({ key: "studio" }, v => (
											<ListItem
												role='presentation'
												key={v.key}
											>
												<Link
													aria-label='my dashboard'
													href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
														studioRoute,
													)({})}`}
													display='block'
													color='grey.700'
													textDecoration='none'
												>
													<Flex
														direction='column'
														justifyContent='center'
														alignItems='center'
														gap={1}
														py={4}
														bgColor='teal.100'
														_hover={{
															backgroundColor:
																theme.colors
																	.gray[50],
														}}
													>
														<Box
															fontSize={{
																base: "md",
															}}
															fontWeight={600}
															key={
																user.value
																	.username
															}
														>
															{
																user.value
																	.username
															}
														</Box>
														<Box
															fontSize={{
																base: "xs",
															}}
															textDecoration='underline'
															key='my-dashboard'
														>
															{t(
																"myDashboard.text",
															)}
														</Box>
													</Flex>
												</Link>
											</ListItem>
										))
										.with(
											{ key: "uploadYourDesign" },
											v => (
												<ListItem
													role='presentation'
													key={v.key}
												>
													<UploadYourDesignCTA
														spoonflowerRedirectUrl={
															spoonflowerRedirectUrl
														}
													>
														{t(
															"uploadYourDesign.text",
														)}
													</UploadYourDesignCTA>
												</ListItem>
											),
										)
										.otherwise(o => (
											<Fragment key={`fragment-${idx}`}>
												{match(o)
													.with(
														{
															key: "designLibrary",
														},
														v => (
															<ProfileDivider
																key={`${v.key}-divider`}
															/>
														),
													)
													.with(
														{
															key: "notifications",
														},
														v => (
															<ProfileDivider
																key={`${v.key}-divider`}
															/>
														),
													)
													.with(
														{ key: "logOut" },
														v => (
															<ProfileDivider
																key={`${v.key}-divider`}
															/>
														),
													)
													.otherwise(() => undefined)}
												<ProfileListItem
													id={o.key}
													user={user}
													key={o.key}
													href={`${spoonflowerRedirectUrl}${makeProfileMenuHref(
														o,
														user.value.userId,
														user.value.username,
													)}`}
												>
													{t(`${o.key}.text`)}
												</ProfileListItem>
											</Fragment>
										)),
								),
							)}
						</List>
					</Box>
				</Box>
			)}
			{isNewCartHardcoded ? (
				<Link
					variant='user-navigation'
					title='View cart'
					href={cartUrl}
					p={2}
					pr={cartItems > 0 ? 0 : 2}
					color='cart-experience'
					_hover={{
						textDecoration: "none",
						color: "cart-experience-hover",
						bg: "gray.200",
					}}
				>
					<ShoppingCart fontSize='medium' />
					<Indicator count={0} />
				</Link>
			) : (
				<PostHogFeature
					flag='migrateCart'
					match={true}
					fallback={
						<Link
							variant='user-navigation'
							title='View cart'
							href={`${spoonflowerRedirectUrl}${tr.encodeUrl(
								cartRoute,
							)({
								route: {
									locale: i18n.resolvedLanguage as Locale,
								},
							})}`}
							p={2}
							pr={cartItems > 0 ? 0 : 2}
							_hover={{
								textDecoration: "none",
								color: "gray.900",
								bg: "gray.200",
							}}
						>
							<ShoppingCart fontSize='medium' />{" "}
							<Indicator count={cartItems} />
						</Link>
					}
				>
					<Link
						variant='user-navigation'
						title='View cart'
						href={cartUrl}
						p={2}
						pr={cartItems > 0 ? 0 : 2}
						color='cart-experience'
						_hover={{
							textDecoration: "none",
							color: "cart-experience-hover",
							bg: "gray.200",
						}}
					>
						<ShoppingCart fontSize='medium' />
						<Indicator count={cartItems} />
					</Link>
				</PostHogFeature>
			)}
		</Flex>
	)
}
