import type { BoxProps } from "@chakra-ui/react"
import { Box } from "@chakra-ui/react"

type IndicatorProps = {
	count: number
} & BoxProps

export const Indicator = ({ count, ...boxProps }: IndicatorProps) =>
	count > 0 ? (
		<Box
			as='span'
			display='flex'
			alignItems='center'
			justifyContent='center'
			bgColor='red.600'
			color='white'
			borderRadius='8px'
			px={2}
			lineHeight={4}
			height={4}
			fontSize='xs'
			width={4}
			zIndex={1}
			mt={-3}
			ml={-1}
			{...boxProps}
		>
			{count}
		</Box>
	) : null
