import { joinPath } from "@artists/devkit"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import type { HttpBackendOptions } from "i18next-http-backend"
import HttpBackend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

import { services } from "../../../config/services"
import { publicPath } from "../path"
import { appNamespaces, baseConfig } from "./i18.base"

i18n.use(HttpBackend).use(initReactI18next).use(LanguageDetector)

if (!i18n.isInitialized) {
	i18n.init<HttpBackendOptions>({
		...baseConfig,
		react: { useSuspense: true },
		backend: {
			loadPath: ([_], [ns = ""]) => {
				if ((appNamespaces as string[]).includes(ns)) {
					return publicPath("locales/en/{{ns}}.json")
				}

				return joinPath(
					services.CDN,
					"/header/locales/{{lng}}/{{ns}}.json",
				)
			},
		},
		ns: [],
	})
}

export default i18n
