/* eslint-disable */ 
// @ts-nocheck
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** PercentSignScalar === %  */
  PercentSignScalar: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File; }
};

export type AiModel = {
  __typename?: 'AIModel';
  model: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export enum AcceptableRepeat {
  Basic = 'BASIC',
  Center = 'CENTER',
  HalfBrick = 'HALF_BRICK',
  HalfDrop = 'HALF_DROP',
  Mirror = 'MIRROR'
}

export type AdminChallenge = {
  __typename?: 'AdminChallenge';
  acceptableRepeats: Array<AcceptableRepeat>;
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  designsCount?: Maybe<Scalars['Int']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  entriesCloseAt: Scalars['String']['output'];
  entriesOpenAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: ChallengeImages;
  intro: Scalars['String']['output'];
  migrationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  partner?: Maybe<Partner>;
  prizes: Array<Prizes>;
  product: Product;
  resultsFinalAt?: Maybe<Scalars['String']['output']>;
  resultsPublicAt: Scalars['String']['output'];
  resultsPublicAtYear?: Maybe<Scalars['String']['output']>;
  rules: Scalars['String']['output'];
  searchUrl: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  state: ChallengeState;
  status: ChallengeStatus;
  tags: Array<Scalars['String']['output']>;
  topDesignsCount: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
  votersCount?: Maybe<Scalars['Int']['output']>;
  votesCount?: Maybe<Scalars['Int']['output']>;
  votingClosesAt: Scalars['String']['output'];
  votingOpensAt: Scalars['String']['output'];
};

export type AdminChallengeEntries = {
  __typename?: 'AdminChallengeEntries';
  info: ChallengeEntriesInfo;
  results: Array<AdminChallengeEntry>;
};

export type AdminChallengeEntry = {
  __typename?: 'AdminChallengeEntry';
  actions: Array<ChallengeEntryAction>;
  challenge: Challenge;
  challengeId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  dQVotes: Scalars['Int']['output'];
  designId: Scalars['ID']['output'];
  entryDescription: Scalars['String']['output'];
  entryImage: Scalars['String']['output'];
  entryPlace?: Maybe<Scalars['Int']['output']>;
  entryState: ChallengeEntryState;
  entryStatus: ChallengeEntryStatus;
  entryTitle: Scalars['String']['output'];
  fraudCheck: Scalars['Boolean']['output'];
  hive?: Maybe<HiveResult>;
  id: Scalars['ID']['output'];
  image: DesignImage;
  isDesignDeleted: Scalars['Boolean']['output'];
  isDesignPrivate: Scalars['Boolean']['output'];
  pdpUrl: Scalars['String']['output'];
  product: ProductName;
  updatedAt: Scalars['String']['output'];
  user: User;
  validVotes?: Maybe<Scalars['Int']['output']>;
};

export type AdminChallenges = {
  __typename?: 'AdminChallenges';
  info: AdminChallengesInfo;
  results: Array<AdminChallenge>;
};

export type AdminChallengesInfo = {
  __typename?: 'AdminChallengesInfo';
  count: ChallengesInfoCount;
  pagination: Pagination;
};

export type AppInfo = {
  __typename?: 'AppInfo';
  commit: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseUser = {
  __typename?: 'BaseUser';
  bestResult?: Maybe<Scalars['Float']['output']>;
  userId: Scalars['String']['output'];
  userImage?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
};

export type BaseUserInput = {
  bestResult?: InputMaybe<Scalars['Float']['input']>;
  userId: Scalars['String']['input'];
  userImage?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
};

export type Challenge = {
  __typename?: 'Challenge';
  acceptableRepeats: Array<AcceptableRepeat>;
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  designsCount?: Maybe<Scalars['Int']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  entriesCloseAt: Scalars['String']['output'];
  entriesOpenAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: ChallengeImages;
  intro: Scalars['String']['output'];
  migrationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  partner?: Maybe<Partner>;
  prizes: Array<Prizes>;
  product: Product;
  resultsFinalAt?: Maybe<Scalars['String']['output']>;
  resultsPublicAt: Scalars['String']['output'];
  resultsPublicAtYear?: Maybe<Scalars['String']['output']>;
  rules: Scalars['String']['output'];
  searchUrl: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  state: ChallengeState;
  status: ChallengeStatus;
  tags: Array<Scalars['String']['output']>;
  topDesignsCount: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
  votersCount?: Maybe<Scalars['Int']['output']>;
  votesCount?: Maybe<Scalars['Int']['output']>;
  votingClosesAt: Scalars['String']['output'];
  votingOpensAt: Scalars['String']['output'];
  youtubeVideoId?: Maybe<Scalars['String']['output']>;
};

export type ChallengeEntriesByUser = {
  __typename?: 'ChallengeEntriesByUser';
  challengeEntries: Array<ChallengeEntry>;
  challengeEntriesVotesCount: Scalars['Int']['output'];
  challengesParticipationCount: Scalars['Int']['output'];
  top10PlacesCount: Scalars['Int']['output'];
};

export type ChallengeEntriesByUserResults = {
  __typename?: 'ChallengeEntriesByUserResults';
  info: ChallengeEntriesInfo;
  results: ChallengeEntriesByUser;
};

export type ChallengeEntriesForVoting = {
  __typename?: 'ChallengeEntriesForVoting';
  info: ChallengeEntriesVotingInfo;
  results: ChallengeEntriesForVotingResults;
};

export type ChallengeEntriesForVotingResults = {
  __typename?: 'ChallengeEntriesForVotingResults';
  entries: Array<ChallengeEntryWithUserValues>;
  guestVoting?: Maybe<VotesToMerge>;
  promotedEntry?: Maybe<ChallengeEntryWithUserValues>;
};

export type ChallengeEntriesInfo = {
  __typename?: 'ChallengeEntriesInfo';
  pagination: Pagination;
};

export type ChallengeEntriesReponseWithError = {
  __typename?: 'ChallengeEntriesReponseWithError';
  data?: Maybe<Array<AdminChallengeEntry>>;
  error?: Maybe<Array<ErrorResponseWithId>>;
};

export type ChallengeEntriesVotingInfo = {
  __typename?: 'ChallengeEntriesVotingInfo';
  pagination: Pagination;
  votingStats: VotingStats;
};

export type ChallengeEntry = {
  __typename?: 'ChallengeEntry';
  challenge: Challenge;
  challengeId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  designId: Scalars['ID']['output'];
  entryDescription: Scalars['String']['output'];
  entryImage: Scalars['String']['output'];
  entryPlace?: Maybe<Scalars['Int']['output']>;
  entryState: ChallengeEntryState;
  entryStatus: ChallengeEntryStatus;
  entryTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: DesignImage;
  isDesignDeleted: Scalars['Boolean']['output'];
  isDesignPrivate: Scalars['Boolean']['output'];
  pdpUrl: Scalars['String']['output'];
  product: ProductName;
  updatedAt: Scalars['String']['output'];
  user: BaseUser;
  validVotes?: Maybe<Scalars['Int']['output']>;
};

export type ChallengeEntryAction = {
  __typename?: 'ChallengeEntryAction';
  actionAdditionalData: ChallengeEntryActionAdditionalData;
  actionAt: Scalars['String']['output'];
  actionBy: BaseUser;
  actionType: ChallengeEntryActionType;
};

export type ChallengeEntryActionAdditionalData = {
  __typename?: 'ChallengeEntryActionAdditionalData';
  detailedReason?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<ChallengeEntryActionReason>;
  votesToProcess?: Maybe<Scalars['Float']['output']>;
};

export type ChallengeEntryActionAdditionalDataInput = {
  detailedReason?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<ChallengeEntryActionReason>;
  votesToProcess?: InputMaybe<Scalars['Float']['input']>;
};

export enum ChallengeEntryActionByUserType {
  Withdraw = 'WITHDRAW'
}

export type ChallengeEntryActionInput = {
  actionAdditionalData: ChallengeEntryActionAdditionalDataInput;
  actionType: ChallengeEntryActionType;
};

export enum ChallengeEntryActionReason {
  Ai = 'AI',
  Copyright = 'COPYRIGHT',
  Custom = 'CUSTOM',
  Likeness = 'LIKENESS',
  Theme = 'THEME'
}

export enum ChallengeEntryActionType {
  AddVotes = 'ADD_VOTES',
  Approve = 'APPROVE',
  MakeWinner = 'MAKE_WINNER',
  Reenter = 'REENTER',
  ReenterAfterVotingStarted = 'REENTER_AFTER_VOTING_STARTED',
  Remove = 'REMOVE',
  RemoveAfterVotingStarted = 'REMOVE_AFTER_VOTING_STARTED',
  RemoveVotes = 'REMOVE_VOTES',
  RemoveWinner = 'REMOVE_WINNER',
  Withdraw = 'WITHDRAW'
}

export enum ChallengeEntryState {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Removed = 'REMOVED',
  Unmoderated = 'UNMODERATED',
  Withdrawn = 'WITHDRAWN'
}

export enum ChallengeEntryStatus {
  Deleted = 'DELETED',
  PrivateNotForSale = 'PRIVATE_NOT_FOR_SALE',
  PublicForSale = 'PUBLIC_FOR_SALE',
  PublicNotForSale = 'PUBLIC_NOT_FOR_SALE'
}

export type ChallengeEntryVotes = {
  __typename?: 'ChallengeEntryVotes';
  designCreatedAt: Scalars['String']['output'];
  designId: Scalars['String']['output'];
  entryVotes: Array<ChallengeVote>;
  numberOfGuestUserVotes: Scalars['Int']['output'];
  numberOfRegisteredUserVotes: Scalars['Int']['output'];
};

export type ChallengeEntryWithUserValues = {
  __typename?: 'ChallengeEntryWithUserValues';
  challenge: Challenge;
  challengeId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  designId: Scalars['ID']['output'];
  entryDescription: Scalars['String']['output'];
  entryImage: Scalars['String']['output'];
  entryPlace?: Maybe<Scalars['Int']['output']>;
  entryState: ChallengeEntryState;
  entryStatus: ChallengeEntryStatus;
  entryTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: DesignImage;
  isDesignDeleted: Scalars['Boolean']['output'];
  isDesignPrivate: Scalars['Boolean']['output'];
  isFavorite: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  pdpUrl: Scalars['String']['output'];
  product: ProductName;
  updatedAt: Scalars['String']['output'];
  user: BaseUser;
  validVotes?: Maybe<Scalars['Int']['output']>;
};

export type ChallengeImage = {
  __typename?: 'ChallengeImage';
  aspect: Scalars['Float']['output'];
  crop: ImageCrop;
  height: Scalars['Float']['output'];
  role: ImageRole;
  url: ImageUrl;
  width: Scalars['Float']['output'];
};

export type ChallengeImageInput = {
  aspect?: InputMaybe<Scalars['Float']['input']>;
  crop: ImageCropInput;
  height?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<ImageRole>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type ChallengeImages = {
  __typename?: 'ChallengeImages';
  hero560x760: ChallengeImage;
  hero800x640: ChallengeImage;
  hero1080x560: ChallengeImage;
  hero1480x560: ChallengeImage;
  hero1770x670: ChallengeImage;
  origin: Scalars['String']['output'];
  thumbnail80x80: ChallengeImage;
  thumbnail160x160: ChallengeImage;
  thumbnail320x320: ChallengeImage;
  thumbnail640x640: ChallengeImage;
};

export type ChallengePartnerInput = {
  buttonText: Scalars['String']['input'];
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  /** Logo OR Photo required */
  logo?: InputMaybe<ImageFileInput>;
  name: Scalars['String']['input'];
  /** Logo OR Photo required */
  photo?: InputMaybe<ImageFileInput>;
  webSite: Scalars['String']['input'];
};

export type ChallengePartnerWithLogo = Partner & {
  __typename?: 'ChallengePartnerWithLogo';
  buttonText: Scalars['String']['output'];
  challengeSlug: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logo?: Maybe<Url>;
  name: Scalars['String']['output'];
  premium?: Maybe<Scalars['Boolean']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  priorityLogo?: Maybe<Url>;
  webSite: Scalars['String']['output'];
};

export type ChallengePartnerWithPhoto = Partner & {
  __typename?: 'ChallengePartnerWithPhoto';
  buttonText: Scalars['String']['output'];
  challengeSlug: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<ImageUrl>;
  premium?: Maybe<Scalars['Boolean']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  priorityLogo?: Maybe<Url>;
  webSite: Scalars['String']['output'];
};

export enum ChallengeState {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export type ChallengeStatus = {
  __typename?: 'ChallengeStatus';
  current: ChallengeStatusDetails;
  next: ChallengeStatusDetails;
};

export enum ChallengeStatusComment {
  ResultsNotFinal = 'RESULTS_NOT_FINAL',
  UnmoderatedEntries = 'UNMODERATED_ENTRIES',
  Unpublished = 'UNPUBLISHED'
}

export type ChallengeStatusDetails = {
  __typename?: 'ChallengeStatusDetails';
  comment?: Maybe<ChallengeStatusComment>;
  date?: Maybe<Scalars['String']['output']>;
  name: ChallengeStatusName;
};

export type ChallengeStatusDetailsInput = {
  comment?: InputMaybe<ChallengeStatusComment>;
  date?: InputMaybe<Scalars['String']['input']>;
  name: ChallengeStatusName;
};

export type ChallengeStatusInput = {
  current: ChallengeStatusDetailsInput;
  next: ChallengeStatusDetailsInput;
};

export enum ChallengeStatusName {
  Deactivated = 'DEACTIVATED',
  EntriesClosed = 'ENTRIES_CLOSED',
  EntriesOpen = 'ENTRIES_OPEN',
  ResultsFinal = 'RESULTS_FINAL',
  ResultsPublic = 'RESULTS_PUBLIC',
  Scheduled = 'SCHEDULED',
  VotingClosed = 'VOTING_CLOSED',
  VotingOpen = 'VOTING_OPEN'
}

export type ChallengeVote = {
  __typename?: 'ChallengeVote';
  challengeId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  entryId: Scalars['ID']['output'];
  guestId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type ChallengeVotesInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  challengeId: Scalars['ID']['input'];
  currentOffset: Scalars['Int']['input'];
  selectedEntriesIds: Array<Scalars['String']['input']>;
  unselectedEntriesIds: Array<Scalars['String']['input']>;
};

export type ChallengeWinner = {
  __typename?: 'ChallengeWinner';
  entry: ChallengeEntryWithUserValues;
  isFirstTime: Scalars['Boolean']['output'];
  isWinnerMadeByAdmin: Scalars['Boolean']['output'];
};

export type ChallengeWithUserVotes = {
  __typename?: 'ChallengeWithUserVotes';
  challenge: Challenge;
  imageUrl: Scalars['String']['output'];
  userVotedEntriesInTop10Count: Scalars['Int']['output'];
  userVotesCount: Scalars['Int']['output'];
};

export type ChallengeWithUserVotesInfo = {
  __typename?: 'ChallengeWithUserVotesInfo';
  pagination: Pagination;
};

export type Challenges = {
  __typename?: 'Challenges';
  info: ChallengesInfo;
  results: Array<Challenge>;
};

export type ChallengesInfo = {
  __typename?: 'ChallengesInfo';
  pagination: Pagination;
};

export type ChallengesInfoCount = {
  __typename?: 'ChallengesInfoCount';
  active: Scalars['Int']['output'];
  deactivated: Scalars['Int']['output'];
};

export enum ChallengesSortingParameter {
  CreatedAt = 'createdAt',
  EntriesCloseAt = 'entriesCloseAt',
  EntriesOpenAt = 'entriesOpenAt',
  ResultsPublicAt = 'resultsPublicAt',
  VotingClosesAt = 'votingClosesAt',
  VotingOpensAt = 'votingOpensAt'
}

export type ChallengesVotedByUser = {
  __typename?: 'ChallengesVotedByUser';
  info: ChallengeWithUserVotesInfo;
  results: ChallengesWithUserVotes;
};

export type ChallengesWithUserVotes = {
  __typename?: 'ChallengesWithUserVotes';
  challengesData: Array<ChallengeWithUserVotes>;
  chosenTop10Count: Scalars['Int']['output'];
  givenVotesCount: Scalars['Int']['output'];
  votedChallengesCount: Scalars['Int']['output'];
};

export type CreateChallengeEntriesInput = {
  challengeId: Scalars['ID']['input'];
  designsIds: Array<Scalars['String']['input']>;
};

export type CreateChallengeEntryInput = {
  challengeId: Scalars['ID']['input'];
  designId: Scalars['ID']['input'];
  entryDescription: Scalars['String']['input'];
  entryImage: Scalars['String']['input'];
  entryTitle: Scalars['String']['input'];
};

export type CreateChallengeImagesInput = {
  file: Scalars['Upload']['input'];
  hero560x760: ChallengeImageInput;
  hero800x640: ChallengeImageInput;
  hero1080x560: ChallengeImageInput;
  hero1480x560: ChallengeImageInput;
  hero1770x670: ChallengeImageInput;
  thumbnail80x80: ChallengeImageInput;
  thumbnail160x160: ChallengeImageInput;
  thumbnail320x320: ChallengeImageInput;
  thumbnail640x640: ChallengeImageInput;
};

export type CreateChallengeInput = {
  acceptableRepeats: Array<AcceptableRepeat>;
  challengeName: Scalars['String']['input'];
  challengeRules: Scalars['String']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  entriesCloseAt: Scalars['String']['input'];
  entriesOpenAt: Scalars['String']['input'];
  images: CreateChallengeImagesInput;
  intro?: InputMaybe<Scalars['String']['input']>;
  partner?: InputMaybe<ChallengePartnerInput>;
  prizes: Array<PrizesInput>;
  product: ProductName;
  resultsPublicAt: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  topDesignsCount: Scalars['Float']['input'];
  votingClosesAt: Scalars['String']['input'];
  votingOpensAt: Scalars['String']['input'];
};

export enum CustomErrorResponses {
  AccessDenied = 'ACCESS_DENIED',
  AlreadyActiveEntry = 'ALREADY_ACTIVE_ENTRY',
  AlreadyExist = 'ALREADY_EXIST',
  AlreadyVoted = 'ALREADY_VOTED',
  CaptchaError = 'CAPTCHA_ERROR',
  ChallengeResultsNotPublic = 'CHALLENGE_RESULTS_NOT_PUBLIC',
  CsrfError = 'CSRF_ERROR',
  DesignPrivate = 'DESIGN_PRIVATE',
  EmailNotSent = 'EMAIL_NOT_SENT',
  EmptyFields = 'EMPTY_FIELDS',
  ImagePixelTooBig = 'IMAGE_PIXEL_TOO_BIG',
  InvalidActionType = 'INVALID_ACTION_TYPE',
  InvalidImageFormat = 'INVALID_IMAGE_FORMAT',
  IpError = 'IP_ERROR',
  JwtExpired = 'JWT_EXPIRED',
  NotFound = 'NOT_FOUND',
  OtherDesignInChallenge = 'OTHER_DESIGN_IN_CHALLENGE',
  SeveralDesigns = 'SEVERAL_DESIGNS',
  Unauthenticated = 'UNAUTHENTICATED',
  VotingClosed = 'VOTING_CLOSED',
  WrongDesignCreationDate = 'WRONG_DESIGN_CREATION_DATE',
  WrongDesignDate = 'WRONG_DESIGN_DATE',
  WrongRepeat = 'WRONG_REPEAT',
  WrongUser = 'WRONG_USER'
}

export type DefaultSettings = {
  __typename?: 'DefaultSettings';
  customEmailMessage?: Maybe<Scalars['String']['output']>;
  entriesCloseAt: Scalars['String']['output'];
  entriesOpenAt: Scalars['String']['output'];
  images: ChallengeImages;
  messageAI?: Maybe<Scalars['String']['output']>;
  messageCopyright?: Maybe<Scalars['String']['output']>;
  messageLikeness?: Maybe<Scalars['String']['output']>;
  messageSubmitEntry?: Maybe<Scalars['String']['output']>;
  messageTheme?: Maybe<Scalars['String']['output']>;
  partners: PriorityPartners;
  prizes: Array<Prizes>;
  resultsPublicAt: Scalars['String']['output'];
  topDesignsCount: Scalars['Int']['output'];
  votingClosesAt: Scalars['String']['output'];
  votingOpensAt: Scalars['String']['output'];
};

export type Design = {
  __typename?: 'Design';
  acceptableRepeat: Array<AcceptableRepeat>;
  createdAt: Scalars['String']['output'];
  currentActiveChallenge?: Maybe<Challenge>;
  designDescription?: Maybe<Scalars['String']['output']>;
  designImageUrl: Scalars['String']['output'];
  designShortDescription?: Maybe<Scalars['String']['output']>;
  designTitle: Scalars['String']['output'];
  designerImage?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: DesignImages;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isForSale?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  tags: Array<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type DesignError = {
  __typename?: 'DesignError';
  code: CustomErrorResponses;
  designId: Scalars['String']['output'];
  message: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type DesignImage = {
  __typename?: 'DesignImage';
  L: Scalars['String']['output'];
  M: Scalars['String']['output'];
  S: Scalars['String']['output'];
  XS: Scalars['String']['output'];
};

export type DesignImages = {
  __typename?: 'DesignImages';
  Curtains: DesignImage;
  Duvet: DesignImage;
  DuvetTopView: DesignImage;
  FabricCrop: DesignImage;
  FabricFatQuarter: DesignImage;
  FabricSwatch: DesignImage;
  FabricYard: DesignImage;
  SheetSet: DesignImage;
  SheetSetTopView: DesignImage;
  SquareThrowPillow: DesignImage;
  TableRunner: DesignImage;
  Tablecloth: DesignImage;
  TeaTowel: DesignImage;
  WallHanging: DesignImage;
  Wallpaper: DesignImage;
  WallpaperGoldMetallic: DesignImage;
  WallpaperSilverMetallic: DesignImage;
};

export type DesignToFavoritesInput = {
  challengeId: Scalars['ID']['input'];
  designId: Scalars['String']['input'];
};

export type EntriesOpenChallenge = {
  __typename?: 'EntriesOpenChallenge';
  currentUserActiveEntry?: Maybe<ChallengeEntry>;
  entriesOpenChallenge: Challenge;
};

export type EntriesResultsEvent = {
  challengeId: Scalars['String']['input'];
  eventName: EventNames;
  triggeredBy: Scalars['String']['input'];
  votingClosesAt: Scalars['String']['input'];
  votingOpensAt: Scalars['String']['input'];
};

export type EntriesResultsEventInput = {
  entriesResultsEvent: EntriesResultsEvent;
  lambdaToken: Scalars['String']['input'];
};

export type ErrorResponseWithId = {
  __typename?: 'ErrorResponseWithId';
  challengeId?: Maybe<Scalars['String']['output']>;
  code: CustomErrorResponses;
  designId: Scalars['String']['output'];
  message: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export enum EventNames {
  EntriesResultsEvent = 'ENTRIES_RESULTS_EVENT',
  PreResultsPublicEvent = 'PRE_RESULTS_PUBLIC_EVENT',
  PreVotingEvent = 'PRE_VOTING_EVENT'
}

export enum GardenFormula {
  CurtainsLifestyleCloseup = 'Curtains_Lifestyle_closeup',
  TeaTowelsSpecialFlat = 'Tea_Towels_special_flat',
  TeaTowelsStandard = 'Tea_Towels_standard',
  DesignChallengeSwatch = 'design_challenge_swatch',
  DesignChallengeYard = 'design_challenge_yard',
  DuvetLifestyle = 'duvet_lifestyle',
  DuvetTopdown = 'duvet_topdown',
  SheetSet_2 = 'sheet_set_2',
  SheetSetTopdown = 'sheet_set_topdown',
  ShopCropThumbnail = 'shop_crop_thumbnail',
  ShopFqThumbnail = 'shop_fq_thumbnail',
  ShopSwatchThumbnail = 'shop_swatch_thumbnail',
  TableRunnerTop = 'table_runner_top',
  TableSquareFront = 'table_square_front',
  Throwpillow_18 = 'throwpillow_18',
  WallHangingWhitebg = 'wall_hanging_whitebg',
  WallHangingWhitebgEngineered = 'wall_hanging_whitebg_engineered',
  WallpaperOnWhite = 'wallpaper_on_white',
  WallpaperOnWhiteGold = 'wallpaper_on_white_gold',
  WallpaperOnWhiteSilver = 'wallpaper_on_white_silver'
}

export type HiveResult = {
  __typename?: 'HiveResult';
  AIgenerated: Scalars['Float']['output'];
  models: Array<AiModel>;
  none: Scalars['Float']['output'];
  notAIGenerated: Scalars['Float']['output'];
};

export type ImageCrop = {
  __typename?: 'ImageCrop';
  height: Scalars['Float']['output'];
  unit: Scalars['PercentSignScalar']['output'];
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type ImageCropInput = {
  height: Scalars['Float']['input'];
  unit?: Scalars['PercentSignScalar']['input'];
  width: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type ImageFileInput = {
  file: Scalars['Upload']['input'];
};

export type ImageLoader = {
  __typename?: 'ImageLoader';
  base64: Scalars['String']['output'];
  jpeg: Scalars['String']['output'];
};

export enum ImageRole {
  Hero = 'HERO',
  Thumbnail = 'THUMBNAIL'
}

export type ImageUrl = {
  __typename?: 'ImageUrl';
  jpeg: Scalars['String']['output'];
  loader: ImageLoader;
  webP: Scalars['String']['output'];
};

export type MeUser = {
  __typename?: 'MeUser';
  /** @deprecated Use user.role instead. */
  role: Roles;
  settings?: Maybe<UserSettings>;
  user?: Maybe<UserInfo>;
  /** @deprecated Use user.userEmail instead. */
  userEmail: Scalars['String']['output'];
  /** @deprecated Use user.userId instead. */
  userId: Scalars['String']['output'];
  /** @deprecated Use user.userName instead. */
  userName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** This endpoint is protected, only for registered user and is used for adding existing challenge entry (design) to user's favorites. */
  addDesignToUserFavorites: ChallengeEntryWithUserValues;
  /** This is protected endpoint, only for admins. It is used for adding hive result to challenge entry. */
  addHiveResultToChallengeEntry?: Maybe<AdminChallengeEntry>;
  /** This is protected endpoint, only for admins. It is used for submitting challenge entries with 'Add late entry' functionality on admin app. */
  adminCreateChallengeEntries: ChallengeEntriesReponseWithError;
  /** This is protected endpoint, only for admins. It is used for updating challenge entries. */
  adminUpdateChallengeEntries: Array<AdminChallengeEntry>;
  /** This is protected endpoint to create challenge, only for admins. */
  createChallenge: AdminChallenge;
  /** This endpoint is protected, only for registered user and is used for creating new challenge entry. */
  createChallengeEntry: ChallengeEntry;
  /** Endpoint to create all challenge schedules, it is protected with admin guard */
  createChallengeSchedules: Scalars['String']['output'];
  /** This is protected endpoint to delete challenge, only for admins. When you delete challenge, it will be in 'DELETED' state.Not hard deleted from DB. */
  deleteChallenge: AdminChallenge;
  /** Endpoint to delete all challenge schedules, it is protected with admin guard */
  deleteChallengeSchedules: Scalars['String']['output'];
  /** This endpoint is protected, only for registered user and is used for deleting existing favorite challenge entry (design) from user's favorites. */
  deleteDesignFromUserFavorites: ChallengeEntryWithUserValues;
  /** This is protected endpoint. It is used for discarding guest votes when logged in user starting to vote. */
  discardVotes: Scalars['Boolean']['output'];
  /** This endpoint to handle scheduled event for updating entries before challenge results go public, it is triggered by lambda. */
  handlePreResultsPublicEvent: Scalars['String']['output'];
  /** This endpoint to handle scheduled event for updating entries before voting opens on challenge, it is triggered by lambda. */
  handlePreVotingEvent: Scalars['String']['output'];
  /** This is protected endpoint to hard delete challenge, only for admins. When you hard delete challenge, it will be deleted from DB. */
  hardDeleteChallenge: Scalars['Boolean']['output'];
  /** This endpoint is protected, only for registered user and is used for hard deleting existing challenge entry from DB. */
  hardDeleteChallengeEntry: ChallengeEntry;
  /** This is protected endpoint. It is used for merging votes of guest to logged in user votes. */
  mergeVotes: Scalars['Boolean']['output'];
  /** This endpoint to process scheduled events for calculating entries results for voting open challenge, it is triggered by lambda. */
  processEntriesResultsEvent: Scalars['String']['output'];
  /** This is protected endpoint to update challenge, only for admins. */
  updateChallenge: AdminChallenge;
  /** This endpoint is used for updating challenge entries by lambda function. It is for updating entry statuses when user makes his design public or private in classic app. */
  updateChallengeEntriesByLambdaData?: Maybe<Scalars['String']['output']>;
  /** This endpoint is protected, only for registered user and is used for updating existing challenge entry. */
  updateChallengeEntry: ChallengeEntry;
  /** This is protected endpoint, only for admins. It is used for updating default settings of challenges. */
  updateDefaultSettings: DefaultSettings;
  /** This is protected endpoint. It is used for updating user design by id. */
  updateDesignById: Design;
  /** This is protected endpoint, only for admins. It is used for updating entries places and votes only for voting open challenge. */
  updateEntriesPlacesAndVotes?: Maybe<Array<AdminChallengeEntry>>;
  updateUserDataInChallengeEntries?: Maybe<Scalars['String']['output']>;
  /** This is unprotected endpoint. Is is used for submitting votes for challenge. Both guest and logged in users can do it. */
  voteForEntries: Scalars['Boolean']['output'];
};


export type MutationAddDesignToUserFavoritesArgs = {
  addDesignToFavoritesInput: DesignToFavoritesInput;
};


export type MutationAddHiveResultToChallengeEntryArgs = {
  designId: Scalars['String']['input'];
};


export type MutationAdminCreateChallengeEntriesArgs = {
  createChallengeEntriesData: CreateChallengeEntriesInput;
};


export type MutationAdminUpdateChallengeEntriesArgs = {
  updateChallengeEntriesData: UpdateChallengeEntriesInput;
};


export type MutationCreateChallengeArgs = {
  createChallengeData: CreateChallengeInput;
};


export type MutationCreateChallengeEntryArgs = {
  createChallengeEntryData: CreateChallengeEntryInput;
};


export type MutationCreateChallengeSchedulesArgs = {
  challengeId: Scalars['ID']['input'];
};


export type MutationDeleteChallengeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteChallengeSchedulesArgs = {
  challengeId: Scalars['ID']['input'];
};


export type MutationDeleteDesignFromUserFavoritesArgs = {
  deleteDesignFromFavoritesInput: DesignToFavoritesInput;
};


export type MutationHandlePreResultsPublicEventArgs = {
  preResultsPublicEventInput: PreResultsPublicEventInput;
};


export type MutationHandlePreVotingEventArgs = {
  preVotingEventInput: PreVotingEventInput;
};


export type MutationHardDeleteChallengeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHardDeleteChallengeEntryArgs = {
  entryId: Scalars['ID']['input'];
};


export type MutationMergeVotesArgs = {
  entriesPerPage?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
};


export type MutationProcessEntriesResultsEventArgs = {
  entriesResultsEventInput: EntriesResultsEventInput;
};


export type MutationUpdateChallengeArgs = {
  updateChallengeData: UpdateChallengeInput;
};


export type MutationUpdateChallengeEntriesByLambdaDataArgs = {
  updateChallengeEntriesByLambdaDataInput: UpdateChallengeEntriesByLambdaDataInput;
};


export type MutationUpdateChallengeEntryArgs = {
  updateChallengeEntryData: UpdateChallengeEntryByUserInput;
};


export type MutationUpdateDefaultSettingsArgs = {
  updateDefaultSettingsData: UpdateDefaultSettingsInput;
};


export type MutationUpdateDesignByIdArgs = {
  updateDesignByIdData: UpdateDesignByIdInput;
};


export type MutationUpdateEntriesPlacesAndVotesArgs = {
  UpdateChallengeEntriesPlacesAndVotesInput: UpdateChallengeEntriesPlacesAndVotesInput;
};


export type MutationUpdateUserDataInChallengeEntriesArgs = {
  updateUserInChallengeEntriesByLambdaInput: UpdateUserInChallengeEntriesByLambdaInput;
};


export type MutationVoteForEntriesArgs = {
  challengeVotesData: ChallengeVotesInput;
};

export type Pagination = {
  __typename?: 'Pagination';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export type Partner = {
  buttonText: Scalars['String']['output'];
  challengeSlug: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  premium?: Maybe<Scalars['Boolean']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  priorityLogo?: Maybe<Url>;
  webSite: Scalars['String']['output'];
};

export type PreResultsPublicEvent = {
  challengeId: Scalars['String']['input'];
  eventName: EventNames;
  resultsPublicAt: Scalars['String']['input'];
  triggeredBy: Scalars['String']['input'];
};

export type PreResultsPublicEventInput = {
  lambdaToken: Scalars['String']['input'];
  preResultsPublicEvent: PreResultsPublicEvent;
};

export type PreVotingEvent = {
  challengeId: Scalars['String']['input'];
  eventName: EventNames;
  triggeredBy: Scalars['String']['input'];
  votingOpensAt: Scalars['String']['input'];
};

export type PreVotingEventInput = {
  lambdaToken: Scalars['String']['input'];
  preVotingEvent: PreVotingEvent;
};

export type PriorityPartnerInput = {
  name: Scalars['String']['input'];
  priorityLogo?: InputMaybe<Scalars['Upload']['input']>;
};

export type PriorityPartners = {
  __typename?: 'PriorityPartners';
  premium: Array<Partner>;
  regular: Array<Partner>;
};

export type PriorityPartnersInput = {
  premium?: InputMaybe<Array<PriorityPartnerInput>>;
  regular?: InputMaybe<Array<PriorityPartnerInput>>;
};

export type Prizes = {
  __typename?: 'Prizes';
  places: Array<Scalars['Int']['output']>;
  prizes: Array<Scalars['String']['output']>;
};

export type PrizesInput = {
  places: Array<Scalars['Int']['input']>;
  prizes: Array<Scalars['String']['input']>;
};

export type Product = {
  __typename?: 'Product';
  gardenFormula: GardenFormula;
  name: ProductName;
  url: Scalars['String']['output'];
};

export enum ProductName {
  Curtains = 'Curtains',
  Duvet = 'Duvet',
  DuvetTopView = 'DuvetTopView',
  FabricCrop = 'FabricCrop',
  FabricFatQuarter = 'FabricFatQuarter',
  FabricSwatch = 'FabricSwatch',
  FabricYard = 'FabricYard',
  SheetSet = 'SheetSet',
  SheetSetTopView = 'SheetSetTopView',
  SquareThrowPillow = 'SquareThrowPillow',
  TableRunner = 'TableRunner',
  Tablecloth = 'Tablecloth',
  TeaTowel = 'TeaTowel',
  WallHanging = 'WallHanging',
  Wallpaper = 'Wallpaper',
  WallpaperGoldMetallic = 'WallpaperGoldMetallic',
  WallpaperSilverMetallic = 'WallpaperSilverMetallic'
}

export type Query = {
  __typename?: 'Query';
  /**
   * 1. This is protected endpoint to get challenge, only for admins. You can get challenge only by 'id' OR 'slug' parameters, please don't send both.
   * 2. All type of challenge will be returned.
   */
  adminChallenge?: Maybe<AdminChallenge>;
  /**
   * 1. This is protected endpoint, only for admins. It is used to get challenge entries.
   * 2. Default sorting is by 'validVotes' parameter and order is descending.
   */
  adminChallengeEntries: AdminChallengeEntries;
  /**
   * 1.  This is protected endpoint to get challenges. Challeneges sorted by 'resultsPublicAt' in descending order.
   * 2. Supported challenge states are 'ACTIVE', 'DEACTIVATED'.
   * 3. If 'challengeState' is not provided in response you will receive challenges in both states.
   */
  adminChallenges: AdminChallenges;
  /** This endpoint for getting app version and commit hash */
  appInfo: AppInfo;
  /**
   * 1. This endpoint to get challenge only by 'id' OR 'slug' parameters, please don't send both.
   * 2. If user will try to get challenge with state 'SCHEDULED' or 'DEACTIVATED', he will 'ACCESS_DENIED' error.
   */
  challenge?: Maybe<Challenge>;
  /** This is unprotected endpoint for voting, it returns random entries from opensearch if user is not logged in, otherwise it returns entries that user has not voted for. Both registered and guest users can vote. */
  challengeEntriesForVoting: ChallengeEntriesForVoting;
  /** This is protected endpoint, only for admins. It is used for getting votes of challenge entry. */
  challengeEntryVotes: ChallengeEntryVotes;
  /** This is public endpoint to get past challenge with results. Depending on requested return type, you can get top challenge entries, winner and all challenge entries. */
  challengeResults?: Maybe<ResultsOfChallenge>;
  /**
   * 1. This endpoint to get challenges. Challenges only in 'ACTIVE' state will be returned.
   * 2. If 'isResultsPublic' is not provided, then challeneges sorted by 'resultsPublicAt' in descending order.'
   * 3. If 'isResultsPublic' = true, then will be returned past challenges sorted by 'resultsPublicAt' in descending order.
   * 4. If 'isResultsPublic' = false, then will be returned current challenges sorted by 'entriesCloseAt' in ascending order.
   */
  challenges: Challenges;
  /** This is unprotected endpoint. It is used for getting default prizes of challenges. */
  defaultPrizes?: Maybe<Array<Prizes>>;
  /** This is protected endpoint, only for admins. It is used for getting default settings of challenges. */
  defaultSettings?: Maybe<DefaultSettings>;
  /** This is protected endpoint, only for admins. It is used for getting designs by ids. */
  designsByIds: Array<Design>;
  /**
   * 1. This is public endpoint to get entry submission open challenges.  Only registered user can get access to this endpoint.
   * 2. Default sorting by resultsPublic parameter, order ascending
   */
  entriesOpenChallenges: Array<EntriesOpenChallenge>;
  /** Get user data */
  me?: Maybe<MeUser>;
  /** This endpoint is protected, only for registered user and is used for getting existing challenge entry. */
  myActiveChallengeEntry?: Maybe<ChallengeEntry>;
  /**
   * 1. This endpoint is protected, only for registered user and is used for getting user's existing challenge entries.
   * 2. Default sorting is by 'createdAt' parameter and order is descending.
   */
  myChallengeEntries: ChallengeEntriesByUserResults;
  /** This is protected endpoint. It is used for getting user design by id. */
  myDesignById?: Maybe<Design>;
  /** This is protected endpoint. It is used for getting challenges voted by user. */
  myVotedChallenges: ChallengesVotedByUser;
  /** This is unprotected endpoint, to get user's voting results. It returns entries that user has voted for active voting challenge. Both registered and guest users can view it. */
  myVotingResults: ChallengeEntriesForVoting;
  /** This is protected endpoint. It is used for getting all partners of challenges. */
  partners: Array<Partner>;
  /** This is unprotected endpoint. It is used for getting priority partners of challenges. */
  priorityPartners: PriorityPartners;
  /** This is protected endpoint, only for admins. It is used for getting all products of challenges. */
  products?: Maybe<Array<Product>>;
};


export type QueryAdminChallengeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAdminChallengeEntriesArgs = {
  challengeId: Scalars['String']['input'];
  entryState: ChallengeEntryState;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAdminChallengesArgs = {
  challengeState?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<SortingDirection>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ChallengesSortingParameter>;
};


export type QueryChallengeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryChallengeEntriesForVotingArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  promotedEntryId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};


export type QueryChallengeEntryVotesArgs = {
  designId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type QueryChallengeResultsArgs = {
  slug: Scalars['String']['input'];
};


export type QueryChallengesArgs = {
  direction?: InputMaybe<SortingDirection>;
  from?: InputMaybe<Scalars['Int']['input']>;
  isResultsPublic?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ChallengesSortingParameter>;
};


export type QueryDesignsByIdsArgs = {
  designsIds: Array<Scalars['String']['input']>;
};


export type QueryMyActiveChallengeEntryArgs = {
  challengeId: Scalars['ID']['input'];
};


export type QueryMyChallengeEntriesArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<Array<ChallengeEntryState>>;
};


export type QueryMyDesignByIdArgs = {
  designId: Scalars['String']['input'];
};


export type QueryMyVotedChallengesArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMyVotingResultsArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  promotedEntryId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type ResultsOfChallenge = {
  __typename?: 'ResultsOfChallenge';
  challengeEntries?: Maybe<Array<ChallengeEntry>>;
  id: Scalars['ID']['output'];
  topChallengeEntries?: Maybe<Array<ChallengeEntryWithUserValues>>;
  topDesignsCount: Scalars['Float']['output'];
  winner?: Maybe<ChallengeWinner>;
};

export enum Roles {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Registrant = 'REGISTRANT'
}

export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type UpdateChallengeEntriesByDesignInput = {
  designId: Scalars['String']['input'];
  entryStatus: ChallengeEntryStatus;
};

export type UpdateChallengeEntriesByLambdaDataInput = {
  challengeEntriesToUpdate: Array<UpdateChallengeEntriesByDesignInput>;
  lambdaToken: Scalars['String']['input'];
};

export type UpdateChallengeEntriesInput = {
  challengeEntriesToUpdate: Array<UpdateChallengeEntryInput>;
};

export type UpdateChallengeEntriesPlacesAndVotesInput = {
  challengeId: Scalars['ID']['input'];
};

export type UpdateChallengeEntryByUserInput = {
  entryDescription?: InputMaybe<Scalars['String']['input']>;
  entryId: Scalars['ID']['input'];
  entryTitle?: InputMaybe<Scalars['String']['input']>;
  userActionType?: InputMaybe<ChallengeEntryActionByUserType>;
};

export type UpdateChallengeEntryInput = {
  action: ChallengeEntryActionInput;
  entryId: Scalars['ID']['input'];
};

export type UpdateChallengeImagesInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  hero560x760: ChallengeImageInput;
  hero800x640: ChallengeImageInput;
  hero1080x560: ChallengeImageInput;
  hero1480x560: ChallengeImageInput;
  hero1770x670: ChallengeImageInput;
  thumbnail80x80: ChallengeImageInput;
  thumbnail160x160: ChallengeImageInput;
  thumbnail320x320: ChallengeImageInput;
  thumbnail640x640: ChallengeImageInput;
};

export type UpdateChallengeInput = {
  acceptableRepeats?: InputMaybe<Array<AcceptableRepeat>>;
  challengeName?: InputMaybe<Scalars['String']['input']>;
  challengeRules?: InputMaybe<Scalars['String']['input']>;
  challengeState?: InputMaybe<ChallengeState>;
  designsCount?: InputMaybe<Scalars['Float']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  entriesCloseAt?: InputMaybe<Scalars['String']['input']>;
  entriesOpenAt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  images?: InputMaybe<UpdateChallengeImagesInput>;
  intro?: InputMaybe<Scalars['String']['input']>;
  partner?: InputMaybe<ChallengePartnerInput>;
  prizes?: InputMaybe<Array<PrizesInput>>;
  product?: InputMaybe<ProductName>;
  resultsFinalAt?: InputMaybe<Scalars['String']['input']>;
  resultsPublicAt?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  topDesignsCount?: InputMaybe<Scalars['Float']['input']>;
  votersCount?: InputMaybe<Scalars['Float']['input']>;
  votesCount?: InputMaybe<Scalars['Float']['input']>;
  votingClosesAt?: InputMaybe<Scalars['String']['input']>;
  votingOpensAt?: InputMaybe<Scalars['String']['input']>;
  youtubeVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDefaultSettingsInput = {
  customEmailMessage?: InputMaybe<Scalars['String']['input']>;
  entriesCloseAt: Scalars['String']['input'];
  entriesOpenAt: Scalars['String']['input'];
  messageAI?: InputMaybe<Scalars['String']['input']>;
  messageCopyright?: InputMaybe<Scalars['String']['input']>;
  messageLikeness?: InputMaybe<Scalars['String']['input']>;
  messageSubmitEntry?: InputMaybe<Scalars['String']['input']>;
  messageTheme?: InputMaybe<Scalars['String']['input']>;
  partners?: InputMaybe<PriorityPartnersInput>;
  prizes: Array<PrizesInput>;
  resultsPublicAt: Scalars['String']['input'];
  topDesignsCount: Scalars['Int']['input'];
  votingClosesAt: Scalars['String']['input'];
  votingOpensAt: Scalars['String']['input'];
};

export type UpdateDesignByIdInput = {
  designDescription?: InputMaybe<Scalars['String']['input']>;
  designTitle?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateUserInChallengeEntriesByLambdaInput = {
  lambdaToken: Scalars['String']['input'];
  usersToUpdate: Array<UserDataToUpdate>;
};

export type Url = {
  __typename?: 'Url';
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  bestResult?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use /settings endpoint to get user settings. */
  language: Scalars['String']['output'];
  role: Roles;
  settings?: Maybe<UserSettings>;
  userEmail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  userImage?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
};

export type UserDataToUpdate = {
  newUserName: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  expiration?: Maybe<Scalars['String']['output']>;
  role: Roles;
  userEmail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type UserInput = {
  bestResult?: InputMaybe<Scalars['Float']['input']>;
  language?: Scalars['String']['input'];
  role: Roles;
  settings?: InputMaybe<UserSettingsInput>;
  userEmail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  userImage?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  adultContent: Scalars['String']['output'];
  country: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  language: Scalars['String']['output'];
  measurementSystem: Scalars['String']['output'];
  orderIdPending?: Maybe<Scalars['Int']['output']>;
};

export type UserSettingsInput = {
  adultContent: Scalars['String']['input'];
  country: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  language: Scalars['String']['input'];
  measurementSystem: Scalars['String']['input'];
  orderIdPending?: InputMaybe<Scalars['Int']['input']>;
};

export type VotesToMerge = {
  __typename?: 'VotesToMerge';
  totalVotes: Scalars['Int']['output'];
  votingDate: Scalars['String']['output'];
};

export type VotingStats = {
  __typename?: 'VotingStats';
  total: Scalars['Int']['output'];
  totalViewed: Scalars['Int']['output'];
  totalVoted: Scalars['Int']['output'];
};
