import { CartItems } from "@artists/design-schemas"
import { deriveTranscoderPar, pipe, S, type TCE, TE, tr } from "@artists/sf-fp"

import { asJson } from "./utils/as-json"

/**
 * Request:
 * https://api-gateway.spoonflower.com/alpenrose/order/<pending_order_id>/cart_items_count
 *
 * Example Response: { "data": { "count": 6 } }
 */
export const OrderResponseS = S.Struct({
	data: CartItems.CartItemsSchema,
})

export type OrderResponse = S.TypeOf<typeof OrderResponseS>

export const orderResponseDecoder = deriveTranscoderPar(OrderResponseS)

// https://api-gateway.spoonflower.com/alpenrose/order/<pending_order_id>/cart_items_count
export const orderCountPath = pipe(
	tr.RootPath,
	tr.path("alpenrose"),
	tr.path("order"),
	tr.param("orderId", tr.numberRC),
	tr.path("cart_items_count"),
)

export const fetchOrderCount =
	(basePath: string) =>
	(
		orderId: number,
	): TE.TaskEither<string | TCE.TranscodeErrors, OrderResponse> =>
		pipe(
			TE.tryCatch(
				() =>
					fetch(
						`${basePath}${tr.encodeUrl(orderCountPath)({
							route: { orderId: orderId },
						})}`,
						{ credentials: "include" },
					),

				e => `Failed to fetch order count: ${e}`,
			),
			TE.chain(asJson("cart count")),
			TE.chainW(orderResponseDecoder.decode),
		)

/**
 * Request: https://[cart|test].spoonflower.com/api/spoonflower/fetch-cart-count
 *
 * Example Response: { "success": true, "cartCount": { "cartCount": 6 } }
 */
export const OriumOrderResponseS = S.Struct({
	success: S.Boolean,
	cartCount: S.Struct({
		cartCount: S.Number,
	}),
})

export type OriumOrderResponse = S.TypeOf<typeof OriumOrderResponseS>

export const oriumOrderResponseDecoder =
	deriveTranscoderPar(OriumOrderResponseS)

// https://cart.spoonflower.com/api/spoonflower/fetch-cart-count
export const oriumOrderCountPath = pipe(
	tr.RootPath,
	tr.path("api"),
	tr.path("spoonflower"),
	tr.path("fetch-cart-count"),
)

export const fetchOriumOrderCount = (
	basePath: string,
): TE.TaskEither<string | TCE.TranscodeErrors, OriumOrderResponse> =>
	pipe(
		TE.tryCatch(
			() =>
				fetch(`${basePath}${tr.encodeUrl(oriumOrderCountPath)({})}`, {
					credentials: "include",
				}),

			e => `Failed to fetch order count: ${e}`,
		),
		TE.chain(asJson("orium cart count")),
		TE.chainW(oriumOrderResponseDecoder.decode),
	)
