import { CrisisAlert } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useRouteError } from "react-router-dom"

import { logger } from "../../../../../@common"
import { Container, Icon } from "./styles"

export const Fallback = () => {
	const [t] = useTranslation()
	const error = useRouteError()

	if (IS_PROD) window.newrelic?.noticeError(error as Error)
	logger.debug("error %o", error)

	return (
		<Container>
			<Icon>
				<CrisisAlert fontSize='inherit' />
			</Icon>

			<Stack>
				<Typography variant='h5'>{t`error.title`}</Typography>
				<Typography>{t`error.subtitle`}</Typography>
			</Stack>
		</Container>
	)
}
