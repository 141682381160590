import { locales } from "@artists/regional-preferences"
import { pipe } from "@artists/sf-fp"
import * as tr from "@simspace/trout/index"

export const facebookPath = "https://www.facebook.com/Spoonflower"
export const twitterPath = "https://twitter.com/spoonflower"
export const instagramPath = "https://www.instagram.com/spoonflower"
export const pinterestPath = "https://www.pinterest.com/spoonflower"
export const flickrPath = "https://www.flickr.com/groups/spoonflower"
export const youtubePath = "https://www.youtube.com/user/spoonflowerfabric"
export const tiktokPath = "https://www.tiktok.com/@spoonflower_official"

export const helpPath = "https://support.spoonflower.com"

export const submitMessagePath =
	"https://support.spoonflower.com/hc/en-us/requests/new?ticket_form_id=360001159372"

export const aboutPath = pipe(
	tr.RootPath,
	tr.param("locale", tr.stringLiteralRC(...locales)),
	tr.path("about"),
)

export const jobsBasePath = "https://jobs.spoonflower.com"
export const jobsPath = pipe(tr.RootPath, tr.path("work-at-spoonflower"))

export const plusPath = pipe(
	tr.RootPath,
	tr.path("en"),
	tr.path("plus-membership"),
)

export const subscribePath = pipe(
	tr.RootPath,
	tr.path("en"),
	tr.path("subscribe"),
)

export const catalogPath = pipe(
	tr.RootPath,
	tr.path("catalog"),
	tr.path("pt"),
	tr.path("index"),
)

export const returnsBasePath = "https://support.spoonflower.com"
export const returnsPath = pipe(
	tr.RootPath,
	tr.path("hc"),
	tr.path("en-us"),
	tr.path("articles"),
	tr.path("204444690-Can-I-return-or-exchange-what-I-ordered"),
)

export const termsPath = pipe(tr.RootPath, tr.path("terms"))

export const proTermsPath = pipe(tr.RootPath, tr.path("pro_terms"))

export const sellerAgreementPath = pipe(
	tr.RootPath,
	tr.path("seller_agreement"),
)

export const privacyPath = pipe(
	tr.RootPath,
	tr.param("locale", tr.stringLiteralRC(...locales)),
	tr.path("privacy"),
)

export const accountPrivacyPath = pipe(
	tr.RootPath,
	tr.path("account"),
	tr.param("id", tr.stringRC),
	tr.queryParam("sub_action", tr.stringLiteralRC("privacy")),
)

export const guestPrivacyPath = pipe(
	tr.RootPath,
	tr.param("locale", tr.stringLiteralRC(...locales)),
	tr.path("request-for-information"),
)

export const accessibilityPath = pipe(
	tr.RootPath,
	tr.path("en"),
	tr.path("accessibility-statement"),
)

// https://cdn.spoonflower.com/baerlauch/development/images/flags/AU.svg
export const countrySvgPath = pipe(
	tr.RootPath,
	tr.path("baerlauch"),
	tr.path("development"),
	tr.path("images"),
	tr.path("flags"),
	tr.param("svg", tr.stringRC),
)
