import { A, O, pipe } from "@artists/sf-fp"
import type { Theme } from "@chakra-ui/react"
import {
	Box,
	Button,
	Flex,
	Link,
	List,
	ListItem,
	useDisclosure,
	useTheme,
} from "@chakra-ui/react"
import ExpandMoreRounded from "@mui/icons-material/ExpandMoreRounded"

import type { HeaderProps } from "../domain/header"

interface AnnouncementProps {
	announcements: HeaderProps["announcements"]
}

export const Announcements = ({ announcements = [] }: AnnouncementProps) => {
	const theme: Theme = useTheme()
	const {
		isOpen: isAnnouncementsOpen,
		onToggle: toggleAnnouncements,
		onOpen: openAnnouncements,
		onClose: closeAnnouncements,
	} = useDisclosure()

	return (
		<Box
			data-testid='banner'
			position='relative'
			gridColumnStart={{ base: 1, lg: 2 }}
			width={{ base: "full" }}
		>
			{pipe(
				announcements,
				A.head,
				O.fold(
					() => undefined,
					ann => (
						<Flex role='heading'>
							<Button
								rightIcon={
									<ExpandMoreRounded fontSize='small' />
								}
								aria-label='announcements'
								aria-expanded={isAnnouncementsOpen}
								data-testid='announcements-button'
								id='announcements-button'
								variant='unstyled'
								width={{ base: "full", lg: "480px" }}
								display='flex'
								color='red.600'
								onClick={() => {
									toggleAnnouncements()
								}}
								onMouseEnter={() => {
									openAnnouncements()
									;(
										document.activeElement as HTMLElement
									).blur()
								}}
								onMouseLeave={() => {
									closeAnnouncements()
									;(
										document.activeElement as HTMLElement
									).blur()
								}}
								textTransform='uppercase'
								fontSize='xs'
							>
								{ann.title}
							</Button>
						</Flex>
					),
				),
			)}

			<Flex role='region' aria-labelledby='announcements-button'>
				<List
					position='absolute'
					data-testid='announcements'
					display={isAnnouncementsOpen ? "flex" : "none"}
					bgColor='white'
					width={{ base: "100vw", lg: "480px" }}
					marginLeft={{ xs: -1, sm: -6, md: -8, lg: 0, xl: 0 }}
					border={`1px solid ${theme.colors.gray[50]}`}
					zIndex={3}
					flexDirection='column'
					margin={0}
					fontSize='sm'
					top={`calc(${theme.space[10]} - 1px)`}
					onMouseEnter={() => {
						openAnnouncements()
						;(document.activeElement as HTMLElement).blur()
					}}
					onMouseLeave={() => {
						closeAnnouncements()
						;(document.activeElement as HTMLElement).blur()
					}}
				>
					{pipe(
						announcements,
						A.map(ann => (
							<Link
								key={ann.id}
								href={ann.url}
								display='flex'
								justifyContent='center'
								color='teal.600'
								p={2}
								_hover={{ color: theme.colors.teal[700] }}
							>
								<ListItem
									userSelect='none'
									wordBreak={"break-word"}
								>
									{ann.message}
								</ListItem>
							</Link>
						)),
					)}
				</List>
			</Flex>
		</Box>
	)
}
