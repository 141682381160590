import type { Dispatch } from "react"

export class NotificationDispatcher {
	private static instance?: NotificationDispatcher
	private subscriber?: Dispatch<string>
	private pendingActions: string[] = []

	private constructor() {}

	public static getInstance() {
		if (IS_SERVER) {
			throw Error("Notifications should not be rendered on server")
		}

		return this.instance || (this.instance = new NotificationDispatcher())
	}

	public dispatch(action: string) {
		if (this.subscriber) this.subscriber(action)
		else this.pendingActions.push(action)
	}

	public subscribe(subscriber: Dispatch<string>) {
		this.subscriber = subscriber
		this.pendingActions.forEach(action => this.subscriber?.(action))
		this.pendingActions = []

		return () => {
			this.subscriber = undefined
		}
	}
}
