export enum CreateAccountCallToAction {
	IP_FRAUD = "IP fraud detected",
	CAPTCHA_ERROR = "Captcha fraud detected",
	VOTE_CLICK = "Vote click",
	REVIEW_VOTE_PAGE_INFO = "Info on review vote page",
	REVIEW_VOTE_PAGE_MODAL = "Modal on review vote page",
}

export const createNewAccountAnalytics = (
	callToAction: CreateAccountCallToAction,
	slug: string,
) => ({
	event: "createAccount",
	callToAction,
	slug,
})
