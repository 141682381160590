import {
	type AnnouncementsResponse,
	fetchAnnouncements,
} from "@artists/api-client"
import { DEFAULT_LOCALE, type Locale } from "@artists/regional-preferences"
import { E, pipe, RA } from "@artists/sf-fp"
import { useEffect, useState } from "react"

export const useAnnouncements = (
	basePath: string,
	locale: Locale = DEFAULT_LOCALE,
) => {
	const [announcements, setAnnouncements] = useState<
		AnnouncementsResponse["data"]
	>([])

	useEffect(() => {
		async function fetchAnnouncementsData() {
			const announcementsE = await pipe(basePath, fetchAnnouncements)()

			pipe(
				announcementsE,
				E.fold(
					() => setAnnouncements([]),
					result =>
						setAnnouncements(
							pipe(
								result.data,
								RA.filter(a => a.language === locale),
							),
						),
				),
			)
		}

		fetchAnnouncementsData()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale])

	return { announcements }
}
