import { ApolloLink } from "@apollo/client/core"

import { logger } from "../../logger"

export const loggerLink = new ApolloLink((operation, forward) => {
	const startTime = new Date().getTime()
	const operationType = (
		operation.query.definitions[0] as Nullable<{ operation: string }>
	)?.operation

	logger.debug(`apollo ${operationType} ${operation.operationName} started`)

	return forward(operation).map(result => {
		const elapsed = new Date().getTime() - startTime

		logger.debug(
			`apollo ${operationType} ${operation.operationName} in ${elapsed} ms`,
			IS_DEV && IS_CLIENT && result,
		)

		return result
	})
})
