/* eslint-disable no-restricted-imports */
import { enUS } from "date-fns/locale"
import { format as formatFns, formatInTimeZone } from "date-fns-tz"

/**
 * We don't want to transform date into any specific timezone. However, we still
 * need to use format from date-fns-tz library to get date as "EST/EDT" for
 * users with America/New_York timezone, because format function from date-fns
 * only operates with GMT.**
 */

const format = (
	date: string | number | Date,
	format: string,
	timeZone?: string,
) => {
	const normalizedDate = new Date(date)

	return timeZone
		? formatInTimeZone(normalizedDate, format, timeZone, { locale: enUS })
		: formatFns(normalizedDate, format, { locale: enUS })
}

export { format }
