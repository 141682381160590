import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

export default defineStyleConfig({
	// Styles for the base style
	baseStyle: {
		fontWeight: "bold",
		lineHeight: "shorter",
		fontFamily: "heading",
	},
	// Styles for the size variations
	sizes: {
		xs: defineStyle({
			fontSize: "sm",
			letterSpacing: "wide",
		}),
		sm: defineStyle({
			fontSize: "md",
			letterSpacing: "wide",
		}),
		md: defineStyle({
			fontSize: "xl",
			letterSpacing: "wide",
		}),
		lg: defineStyle({
			fontSize: "2xl",
			letterSpacing: "normal",
		}),
		xl: defineStyle({
			fontSize: "3xl",
			letterSpacing: "normal",
		}),
		"2xl": defineStyle({
			fontSize: "4xl",
			letterSpacing: "normal",
		}),
		"3xl": defineStyle({
			fontSize: ["4xl", null, null, "5xl"],
			letterSpacing: "normal",
		}),
		"4xl": defineStyle({
			fontSize: ["5xl", null, null, "6xl"],
			letterSpacing: "normal",
		}),
	},
})
