import {
	fetchOrderCount,
	fetchUserQuery,
	fetchUserStats,
} from "@artists/api-client"
import { Ap, deriveTranscoder, E, O, pipe, S, TE } from "@artists/sf-fp"
import { useEffect } from "react"

const UserMetaS = S.Struct({
	username: S.OptionFromNullable(S.String()),
	orderIdPending: S.OptionFromNullable(S.Number),
	userId: S.OptionFromNullable(S.FloatFromString),
})

const userMetaDecoder = deriveTranscoder(UserMetaS)

export const useUserAnalytics = (
	basePath: string,
	handleAnalytics: (value: object) => void = () => void 0,
	userMeta: {
		username?: string
		userId?: string
		orderIdPending?: number
	} = { username: undefined, userId: undefined, orderIdPending: undefined },
) => {
	useEffect(() => {
		async function handleUserAnalytics() {
			const userDataE = await pipe(
				TE.fromEither(
					pipe(
						userMetaDecoder.decode(userMeta),
						E.fold(
							() => E.left("user meta schema is not valid"),
							s =>
								pipe(
									Ap.sequenceT(O.Applicative)(
										s.orderIdPending,
										s.userId,
									),
									O.fold(
										() =>
											E.left(
												"userId or orderIdPending is not defined",
											),
										([orderIdPending, userId]) =>
											E.right({
												username: s.username,
												userId,
												orderIdPending,
											}),
									),
								),
						),
					),
				),
				TE.orElse(() =>
					pipe(
						fetchUserQuery(basePath),
						TE.map(v => ({
							userId: v.data.id,
							orderIdPending: v.data.order_id_pending,
							username: v.data.screen_name,
						})),
					),
				),
				TE.chain(({ userId, orderIdPending, username }) =>
					pipe(
						Ap.sequenceT(TE.ApplicativePar)(
							fetchUserStats(basePath)(userId),
							fetchOrderCount(basePath)(orderIdPending),
						),
						TE.map(
							([
								{
									data: { data_layer: dataLayer },
								},
								{
									data: { count: cartCount },
								},
							]) => ({
								username,
								dataLayer,
								cartCount,
							}),
						),
					),
				),
			)()

			pipe(
				userDataE,
				E.fold(
					() => {},
					({ username, dataLayer, cartCount }) => {
						const isUser = pipe(username, O.isSome)
						const isGuest = pipe(username, O.isNone)
						const hasCartItems = cartCount > 0

						if (isUser || (isGuest && hasCartItems)) {
							handleAnalytics({
								event: "user_information",
								...dataLayer,
							})
						}
					},
				),
			)
		}

		handleUserAnalytics()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}
