import {
	CountryAbbreviationRefinement,
	currencies,
	locales,
	measurements,
} from "@artists/regional-preferences"
import { deriveTranscoder, pipe, S, TE, tr } from "@artists/sf-fp"

import { userPath, userResponseTaskDecoder } from "./user"
import { asJson } from "./utils/as-json"

const UserPreferencesS = S.Struct({
	currency: S.Literal(...currencies),
	measurement_system: S.Literal(...measurements),
	country: CountryAbbreviationRefinement,
	locale: S.Optional(S.Literal(...locales)),
})

export type UserPreferences = S.TypeOf<typeof UserPreferencesS>

export const userPreferencesDecoder = deriveTranscoder(UserPreferencesS)

/**
 * Request: https://api-gateway.spoonflower.com/alpenrose/user/me (PATCH)
 *
 * Example Body: { "preferences":{ "currency":"AUD",
 * "measurement_system":"IMPERIAL", "locale":"en", "country":"AU" } }
 */
const UserPreferencesPatchS = S.Struct({
	preferences: UserPreferencesS,
})

export type UserPreferencesPatch = S.TypeOf<typeof UserPreferencesPatchS>

export const userPreferencesPatchDecoder = deriveTranscoder(
	UserPreferencesPatchS,
)

export const patchUserPreferences =
	(userPreferences: UserPreferencesPatch) => (basePath: string) =>
		pipe(
			TE.tryCatch(
				() =>
					fetch(`${basePath}${tr.encodeUrl(userPath)({})}`, {
						method: "PATCH",
						body: JSON.stringify(userPreferences),
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						},
						credentials: "include",
					}),
				e => `Failed to fetch user preferences: ${e}`,
			),
			TE.chain(asJson("user preferences")),
			TE.chainW(userResponseTaskDecoder.decode),
		)
