import { pipe } from "fp-ts/lib/function"
import * as S from "schemata-ts/index"
import { deriveTranscoder } from "schemata-ts/Transcoder"

import { countryCodeMap } from "./country-code-map"

export const countries = Object.values(countryCodeMap)
	.map(v => Object.values(v.localized_names))
	.flat()

export type Country = (typeof countries)[number]

export const CountryRefinement = pipe(
	S.String(),
	S.Refine(
		(s): s is Country => (countries as Array<string>).includes(s),
		"country",
	),
)

export const countryDecoder = deriveTranscoder(CountryRefinement)

export type CountryAbbreviation = keyof typeof countryCodeMap

export const countryAbbreviations = Object.keys(
	countryCodeMap,
) as ReadonlyArray<CountryAbbreviation>

export const CountryAbbreviationRefinement = pipe(
	S.String(),
	S.Refine(
		(s): s is CountryAbbreviation =>
			(countryAbbreviations as Array<string>).includes(s),
		"country abbreviation",
	),
)

export const countryAbbreviationDecoder = deriveTranscoder(
	CountryAbbreviationRefinement,
)
