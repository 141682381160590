import type { PostHogConfig } from "posthog-js"

export const postHogOptions: Partial<PostHogConfig> = {
	autocapture: false,
	capture_pageview: false,
	capture_pageleave: false,
	debug: IS_DEV,
	disable_session_recording: true,
	opt_out_persistence_by_default: false,
	enable_recording_console_log: false,
	verbose: IS_DEV,
	rageclick: false,
}
