import { defineStyleConfig } from "@chakra-ui/react"

export default defineStyleConfig({
	variants: {
		"no-outline": {
			container: {
				border: "none",
				borderBottomStyle: "solid",
				borderBottomWidth: "px",
				borderBottomColor: "gray.500",
			},
		},
	},
})
