export const semanticTokens = {
	"design-edit-ruler-tick-width": "1px",
	colors: {
		errorIcon: "red.500",
		"design-edit-ruler-tick": "gray.500",
		"design-product-card-for-sale": "green.500",
		"design-product-card-not-for-sale": "gray.400",
		"focus-border-color": "teal.500",
		"menu-unselected": "gray.500",
		"menu-selected": "gray.100",
		"edit-modal-bg": "white",
		"preview-alpha": "blackAlpha.50",
		"text-secondary": "gray.500",
		"preview-bg": "#f4f4f4",
		divider: "gray.200",
		"design-upload-buy-or-sell-banner-bg": "gray.100",
		success: "green.500",
		"modal-text-secondary-bg": "gray.100",
		"external-link-button-color": "granite.600",
		"text-red": "red.500",
	},
} as const
