import { checkboxAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const inline = definePartsStyle({
	label: {
		minWidth: "max-content",
	},
})

export default defineMultiStyleConfig({
	variants: { inline },
})
