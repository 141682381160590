import { theme } from "@artists/chakra-ui-theme"
import { Footer as SFFooter } from "@artists/footer"
import { ChakraProvider } from "@chakra-ui/react"

import { services } from "../../../../../../../../config/services"
import { useAuth } from "../../../../../../api"

export const Footer = () => {
	const { user } = useAuth()

	return (
		<ChakraProvider theme={theme}>
			<SFFooter
				userId={user?.userId}
				alpenroseServiceUrl={services.gateway}
			/>
		</ChakraProvider>
	)
}
