import * as TE from "fp-ts/TaskEither"

export const asJson: (
	queryName: string,
) => (r: Response) => TE.TaskEither<string, unknown> =
	(queryName: string) => (r: Response) =>
		TE.tryCatch(
			() => r.json(),
			e => `Error converting ${queryName} query response to JSON: ${e}`,
		)
