import type { Locale } from "@artists/regional-preferences"
import { O, pipe, tr } from "@artists/sf-fp"
import {
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	Link,
	Stack,
	Text,
	useDisclosure,
	VStack,
} from "@chakra-ui/react"
import { createRef } from "react"
import { useTranslation } from "react-i18next"

import { accountPrivacyPath, guestPrivacyPath } from "../utils/paths"

interface UserDataInfoProps {
	handleManageCookiesDrawer: () => void
	userId: O.Option<string>
}

export const UserDataInfo = ({
	handleManageCookiesDrawer,
	userId,
}: UserDataInfoProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { t, i18n } = useTranslation("footer")
	const buttonRef = createRef<HTMLButtonElement>()

	return (
		<>
			<Button
				ref={buttonRef}
				variant='footer-policies-deprecated'
				onClick={onOpen}
				data-testid='button-data-privacy'
			>
				{t("navigation.dataPrivacy")}
			</Button>
			<Drawer
				size={{ base: "full", md: "md" }}
				isOpen={isOpen}
				placement='right'
				onClose={onClose}
				finalFocusRef={buttonRef}
			>
				<DrawerOverlay />
				<DrawerContent bgColor='white'>
					<DrawerCloseButton />
					<DrawerHeader>
						<Heading as='h1' size='lg'>
							{t("privacy.yourPrivacyHeader")}
						</Heading>
					</DrawerHeader>

					<DrawerBody>
						<VStack gap={5} alignItems='start'>
							<Stack>
								<Text>{t("privacy.privacyBlurb")}</Text>
								<Button
									size='sm'
									width='max-content'
									onClick={handleManageCookiesDrawer}
								>
									{t("navigation.cookies")}
								</Button>
								.
							</Stack>
							<Stack>
								<Heading as='h2' size='md'>
									{t("privacy.residentsHeader")}
								</Heading>
								<Text>
									{t("privacy.residentsBlurb")}{" "}
									<Link
										textDecoration='underline'
										href={pipe(
											userId,
											O.fold(
												() =>
													tr.encodeUrl(
														guestPrivacyPath,
													)({
														route: {
															locale: i18n.resolvedLanguage as Locale,
														},
													}),
												userId =>
													tr.encodeUrl(
														accountPrivacyPath,
													)({
														route: { id: userId },
														query: {
															sub_action:
																"privacy",
														},
													}),
											),
										)}
									>
										{t("privacy.clickHere")}
									</Link>
									.
								</Text>
							</Stack>
						</VStack>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}
