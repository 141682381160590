import "../@common/i18n/i18n.client"

import { useSSR } from "react-i18next"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import { basename, createEmotionCache, getApolloClient } from "../@common"
import { App, routes } from "./components/@shared/app"
import { bootstrap } from "./utils"

const AppContainer = () => {
	const apolloClient = getApolloClient()
	const cache = createEmotionCache(window.NONCE)
	useSSR(window.I18N_STORE, window.LANGUAGE)
	return (
		<App
			nonce={window.NONCE}
			browser={window.BROWSER}
			apolloClient={apolloClient}
			emotionCache={cache}
		>
			<RouterProvider
				router={createBrowserRouter(routes, { basename })}
			/>
		</App>
	)
}

bootstrap(<AppContainer />)
