import { getENV } from "../../src/@common"

type ServiceName = "gateway" | "titanarum" | "CDN" | "cart"

const { API_MODE, BACKEND_API } = getENV()

const stage: Collection<ServiceName, string> = {
	CDN: "https://cdn.spoonflower.com/staging",
	gateway: "https://staging-api-gateway.spoonflower.com",
	titanarum: BACKEND_API,
	cart: "https://test.spoonflower.com",
}

const prod: Collection<ServiceName, string> = {
	CDN: "https://cdn.spoonflower.com/prod",
	gateway: "https://api-gateway.spoonflower.com",
	titanarum: "https://www.spoonflower.com/titanarum/public",
	cart: "https://cart.spoonflower.com",
}

export const services = API_MODE === "production" ? prod : stage
