import { Box } from "@mui/material"

import { Browser } from "./browser"
import { Noscript } from "./noscript"

export const Prompt = () => (
	<Box
		sx={{
			top: 0,
			zIndex: 5,
			display: "grid",
			position: "sticky",
			boxShadow: "0 1px 6px 0 rgb(0 0 0 / 15%)",
		}}
	>
		<Browser />
		<Noscript />
	</Box>
)
