import type { FC, ReactNode } from "react"
import { createContext } from "react"

import type { RedirectContext } from "./redirect.types"

interface RedirectCtx {
	context: RedirectContext
}
export const RedirectCtx = createContext<RedirectCtx>({
	context: {},
})

interface Props {
	context?: RedirectContext
	children: ReactNode
}
export const RedirectProvider: FC<Props> = ({ children, context = {} }) => (
	<RedirectCtx.Provider value={{ context }}>{children}</RedirectCtx.Provider>
)
