/* eslint-disable */ 
// @ts-nocheck
import * as Types from '../schema.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MyCurrentEntryQueryVariables = Types.Exact<{
  challengeId: Types.Scalars['ID']['input'];
}>;


export type MyCurrentEntryQuery = { __typename?: 'Query', myActiveChallengeEntry?: { __typename?: 'ChallengeEntry', id: string, entryPlace?: number | null, validVotes?: number | null } | null };


export const MyCurrentEntryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MyCurrentEntry"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"challengeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myActiveChallengeEntry"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"challengeId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"challengeId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"entryPlace"}},{"kind":"Field","name":{"kind":"Name","value":"validVotes"}}]}}]}}]} as unknown as DocumentNode<MyCurrentEntryQuery, MyCurrentEntryQueryVariables>;