import { type SystemStyleObject } from "@chakra-ui/react"

export const stylesForRecursiveNavList: SystemStyleObject = {
	".header-wrapper": {
		paddingX: 5,
		paddingY: 4,
		alignItems: "center",
		bgColor: "gray.50",
		display: {
			base: "none",
			lg: "none",
		},
		'&[data-depth="1"]': {
			display: {
				base: "flex",
			},
		},
	},

	".nav-list-item": {
		whiteSpace: "nowrap",
		userSelect: "none",
		display: "flex",
		alignItems: "center",
		position: "static",
		bg: "none",

		"&:hover": {
			bg: "gray.50",

			"> div > .submenu-container": {
				display: { lg: "block" },
				'&[data-all-children-are-links="true"][data-has-over-10-children="true"]':
					{
						display: { lg: "grid" },
					},
			},
		},

		'&[data-is-selected="true"]': {
			bg: "gray.50",
		},

		'&[data-hide-on-mobile="true"]': {
			display: "none",
		},

		'&[data-depth="1"]': {
			position: {
				lg: "relative",
			},

			'&[data-max-items-reached="true"]': {
				display: "none",
			},
		},
	},

	".nav-button-container": {
		width: "full",
		height: "full",
	},

	".nav-button": {
		display: "flex",
		paddingTop: 2.5,
		paddingRight: 0,
		paddingBottom: 2.5,
		paddingLeft: {
			base: 5,
			lg: 2.5,
		},
		textAlign: "left",
		width: "full",
		height: "full",
		'&[data-depth="1"]': {
			padding: { lg: 4 },
			"> span": {
				display: { lg: "none" },
			},
		},
		fontSize: {
			base: "md",
			lg: "sm",
		},
		fontWeight: "normal",
		color: "gray.700",
		lineHeight: 5,
		borderRadius: 0,

		// expand icon
		"> span": {
			marginLeft: "auto",
			paddingRight: 5,
		},

		"&:focus": {
			backgroundColor: "gray.50",
		},

		"&:active": {
			backgroundColor: "gray.200",
		},
	},

	".submenu-container": {
		display: "none",
		'&[data-is-selected="true"]': {
			display: "flex",
		},
		flexDirection: "column",
		position: "absolute",
		top: 0,
		left: {
			base: 0,
			lg: "100%",
		},
		bgColor: "white",
		borderStyle: "solid",
		border: {
			base: "1px",
			lg: 0,
		},
		borderColor: "gray.50",
		borderTop: "none",
		minWidth: {
			base: "full",
			lg: 56,
		},
		zIndex: 1,
		minHeight: "full",

		'&[data-depth="1"]': {
			top: { lg: "53px" },
			left: 0,
		},

		'&[data-is-about-menu="true"]': {
			left: "auto",
			right: 0,
		},

		'&[data-is-selected="true"][data-all-children-are-links="true"][data-has-over-10-children="true"]':
			{
				display: { lg: "grid" },
			},

		'&[data-all-children-are-links="true"][data-has-over-10-children="true"]':
			{
				alignItems: { lg: "center" },
				gridAutoRows: "max-content",
				gridTemplateColumns: "repeat(2, 1fr)",

				'&[data-has-over-50-children="true"]': {
					gridTemplateColumns: "repeat(6, 1fr)",
				},
			},
	},

	".nav-link": {
		paddingTop: 2.5,
		paddingRight: {
			base: 0,
			lg: 2.5,
		},
		paddingBottom: 2.5,
		paddingLeft: {
			base: 5,
			lg: 2.5,
		},
		fontSize: {
			base: "md",
			lg: "sm",
		},
		lineHeight: 5,
		color: "gray.700",
		width: "full",
		textDecoration: "none",
		display: "flex",

		"&:hover": {
			textDecoration: "none",
		},
		"&:focus": {
			backgroundColor: "gray.50",
		},
		"&:active": {
			backgroundColor: "gray.200",
		},
		'&[data-depth="1"]': {
			padding: 4,
		},
	},
}
