import { format } from "../dates"

export interface TimeData {
	day?: string
	date?: string
	month?: string
	time?: string
	isPassed?: boolean
	dayOfMonth?: string
	timeOfDay?: string
	dayAndTimeOfMonth?: string
}

type Dates<T> = Collection<keyof T, string | undefined>
export const getTimes = <T>(dates: Dates<T>, timezone?: string) =>
	Object.entries(dates).reduce(
		(acc, [key, date]) => {
			if (!date) {
				acc[key as keyof T] = {
					day: undefined,
					date: undefined,
					month: undefined,
					isPassed: undefined,
					time: undefined,
					dayOfMonth: undefined,
					timeOfDay: undefined,
					dayAndTimeOfMonth: undefined,
				}

				return acc
			}

			const currentDate = date as string
			const now = new Date()

			acc[key as keyof T] = {
				day: format(currentDate, "EEE", timezone),
				date: format(currentDate, "d", timezone),
				month: format(currentDate, "M", timezone),
				isPassed: new Date(currentDate).getTime() - now.getTime() < 0,
				time: format(currentDate, "EEE, MM/dd, ha z"),
				dayOfMonth: format(currentDate, "MMM d"),
				timeOfDay: format(currentDate, "ha z"),
				dayAndTimeOfMonth: format(currentDate, "EE, MMM d, ha z"),
			}
			return acc
		},
		{} as Collection<keyof T, TimeData>,
	)
