import { loadableReady } from "@loadable/component"
import type { ReactNode } from "react"
import { createRoot, hydrateRoot } from "react-dom/client"

import { disableEmotionWarnings } from "../../@common"

export const bootstrap = (app: ReactNode) => {
	const container = document.getElementById("root") as HTMLElement

	if (IS_SPA) {
		disableEmotionWarnings()
		return createRoot(container).render(app)
	}

	loadableReady(() => hydrateRoot(container, app))
}
