const breakpoints = {
	base: "0rem", // 0px
	xs: "20rem", // 320px
	sm: "26.75rem", // 428px
	md: "48rem", // 768px
	lg: "64rem", // 1024px
	xl: "80rem", // 1280px
	"2xl": "96rem", // 1536px
} as const

export default breakpoints
