import type { RouteObject } from "react-router"

import { loadable, Redirect, Root } from "../../../../utils"
import { Fallback } from "../error"
import { Layout } from "../layout"

const Home = loadable(() => import("../../../../pages/home"))
const Challenge = loadable(() => import("../../../../pages/challenge"))
const Submission = loadable(() => import("../../../../pages/submission"))
const NotFound = loadable(() => import("../../../../pages/not-found"))
const Entries = loadable(() => import("../../../../pages/artist/entries"))
const Voting = loadable(() => import("../../../../pages/artist/voting"))
const Artist = loadable(() => import("../../../../pages/artist"))
const Past = loadable(() => import("../../../../pages/past"))
const Votes = loadable(() => import("../../../../pages/vote"))
const ReviewVotes = loadable(() => import("../../../../pages/review-votes"))

export const routes: RouteObject[] = [
	{
		element: <Layout />,
		errorElement: <Fallback />,
		children: [
			{ path: Root.Home.path, element: <Home /> },
			{ path: Root.Past.path, element: <Past /> },
			{ path: Root.Challenge.path, element: <Challenge /> },
			{ path: Root.Vote.path, element: <Votes /> },
			{ path: Root.Submission.path, element: <Submission /> },
			{ path: Root.ReviewVotes.path, element: <ReviewVotes /> },
			{
				path: Root.Me.path,
				element: <Artist />,
				children: [
					{
						index: true,
						element: <Redirect to={Root.Me.Voting.path} />,
					},
					{ path: Root.Me.Entries.path, element: <Entries /> },
					{ path: Root.Me.Voting.path, element: <Voting /> },
					{ path: "*", element: <NotFound /> },
				],
			},
			{ path: "*", element: <NotFound /> },
		],
	},
]
