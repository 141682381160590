export default {
	// Styles for the base style
	baseStyle: {
		fontWeight: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		fontFamily: "body",
	},
	// Styles for the size variations
	xs: {
		fontSize: "0.75rem",
	},
	sm: {
		fontSize: "0.875rem",
	},
	md: {
		fontSize: "1rem",
	},
	lg: {
		fontSize: "1.125rem",
	},
	xl: {
		fontSize: "1.25rem",
	},
	"2xl": {
		fontSize: "1.5rem",
	},
	"3xl": {
		fontSize: "1.875rem",
	},
	"4xl": {
		fontSize: "2.25rem",
	},
	"5xl": {
		fontSize: {
			xs: "2.75rem",
			sm: "2.75rem",
			md: "2.75rem",
			lg: "3rem",
			xl: "3rem",
			"2xl": "3rem",
		},
	},
	"6xl": {
		fontSize: {
			xs: "3.5rem",
			sm: "3.5rem",
			md: "3.5rem",
			lg: "4rem",
			xl: "4rem",
			"2xl": "4rem",
		},
	},
	paragraph: {
		paddingBottom: "0.75rem",
	},
	"menu-item-label": {
		fontWeight: "bold",
		marginBottom: 2,
		color: "gray.900",
	},
	"menu-item-sublabel": {
		fontSize: "14px",
		color: "gray.600",
	},
	center: {
		textAlign: "center",
	},
	// The default `size` or `variant` values
	defaultProps: {},
}
