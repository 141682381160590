/* eslint-disable */ 
// @ts-nocheck
import * as Types from '../../schema.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type WithdrawDesignsMutationVariables = Types.Exact<{
  updateChallengeDesignsData: Types.UpdateChallengeEntryByUserInput;
}>;


export type WithdrawDesignsMutation = { __typename?: 'Mutation', updateChallengeEntry: { __typename?: 'ChallengeEntry', id: string, designId: string } };


export const WithdrawDesignsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"WithdrawDesigns"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"updateChallengeDesignsData"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateChallengeEntryByUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateChallengeEntry"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"updateChallengeEntryData"},"value":{"kind":"Variable","name":{"kind":"Name","value":"updateChallengeDesignsData"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"designId"}}]}}]}}]} as unknown as DocumentNode<WithdrawDesignsMutation, WithdrawDesignsMutationVariables>;