import { useQuery } from "@apollo/client"
import { MeDocument } from "@artists/gql/design-challenges"

export const useUserPreferences = () => {
	const { data } = useQuery(MeDocument, {
		variables: { settings: true },
	})

	if (!data?.me?.settings) return null

	return {
		orderIdPending: data.me.settings.orderIdPending,
	} as { orderIdPending?: number }
}
