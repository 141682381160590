import { Close } from "@mui/icons-material"
import { Alert, Collapse, IconButton, Typography } from "@mui/material"
import { addYears } from "date-fns"
import { useState } from "react"
import { useCookies } from "react-cookie"
import { Trans } from "react-i18next"

import { useBrowser } from "../../../../../../utils"

export const Browser = () => {
	const [cookies, setCookie] = useCookies()
	const { browser } = useBrowser()
	const [show, setShow] = useState(
		!cookies.browser && !!browser && !browser.supported,
	)

	const onClose = () => {
		setShow(false)
		setCookie("browser", true, {
			domain: ".spoonflower.com",
			expires: addYears(new Date(), 1),
		})
	}

	return (
		<Collapse in={show} sx={{ gridArea: "1 / 1 / 1 / 1" }}>
			<Alert
				severity='info'
				sx={{ py: 1 }}
				action={
					<IconButton size='small' onClick={onClose}>
						<Close />
					</IconButton>
				}
			>
				<Typography fontSize='small'>
					<Trans
						ns='common'
						i18nKey='browser'
						values={{ browser: browser?.name }}
						components={{ b: <strong /> }}
					/>
				</Typography>
			</Alert>
		</Collapse>
	)
}
