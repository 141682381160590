import {
	CountryAbbreviationRefinement,
	currencies,
	locales,
	measurements,
} from "@artists/regional-preferences"
import { S } from "@artists/sf-fp"

const adultContents = ["ADULT_CONTENT_ON", "ADULT_CONTENT_OFF"] as const

export const SpoondollarsS = S.Struct({
	available: S.String(),
	raw_available: S.Number,
	currency: S.Literal(...currencies),
})

export const FavoritesS = S.Struct({
	design_ids: S.Array(S.Number),
})

export const UserPreferencesS = S.Struct({
	currency: S.Literal(...currencies),
	measurement_system: S.Literal(...measurements),
	country: CountryAbbreviationRefinement,
	locale: S.OptionFromNullable(S.Literal(...locales)),
	adult_content: S.Literal(...adultContents),
})

export const UserS = S.Struct({
	id: S.Number,
	email: S.OptionFromNullable(S.String()),
	screen_name: S.OptionFromNullable(S.String()),
	pro: S.Boolean,
	guest: S.Boolean,
	design_owner: S.Boolean,
	order_id_pending: S.Number,
	spoondollars: SpoondollarsS,
	/**
	 * Some users have hundreds of thousands of favorited designs and this
	 * causes the browser to OOM. For now, since this data is not needed, we
	 * skip trying to parse it on the client.
	 *
	 * - AR 2023-08-09
	 *
	 * Favorites: S.OptionFromUndefined(FavoritesS),
	 */
	business_tools: S.Boolean,
	preferences: UserPreferencesS,
	newsletter_opt_in: S.Boolean,
	do_not_sell_share: S.OptionFromNullable(S.Boolean),
	user_groups: S.OptionFromNullable(S.Array(S.Number)),
})

export const DataLayerS = S.Struct({
	userID: S.String(),
	segment: S.String(),
	contestVoter: S.BooleanFromString,
	designForSale: S.BooleanFromString,
	marketplaceBuyer: S.BooleanFromString,
	accountAge: S.String(),
	numOrders: S.String(),
	proUser: S.BooleanFromString,
	currencyCode: S.Literal(...currencies),
	countryCode: CountryAbbreviationRefinement,
	em: S.String(),
	customerStatus: S.String(),
	optOut: S.Boolean,
})

export const UserStatsS = S.Struct({
	cookie_value: S.String(),
	data_layer: DataLayerS,
})

export const UserQueryS = S.Struct({
	id: S.Number,
	email: S.OptionFromNullable(S.String()),
	screen_name: S.OptionFromNullable(S.String()),
	order_id_pending: S.Number,
	preferences: UserPreferencesS,
})
