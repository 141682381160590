import { CloseAction, useSnackbar } from "@artists/uikit"
import { Button } from "@mui/material"

export const useErrors = () => {
	const { show, close } = useSnackbar()

	const onError = (message: string) => {
		show(message, {
			variant: "error",
			autoHideDuration: 15000,
			preventDuplicate: true,
			action: () => (
				<>
					<Button
						sx={{
							textTransform: "none",
							textDecoration: "underline",
							color: "white",
						}}
						onClick={() => {
							close()
							window.location.reload()
						}}
					>
						Try again.
					</Button>
					<CloseAction />
				</>
			),
		})
	}

	return { onError }
}
